#myBookmarkSection {
  .header {
    margin-bottom: 40px;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    .heading {
      font-size: 17px;
      float: left;
      font-family: montserrat-bold;
    }
    .filter {
      float: right;
      span {
        font-size: 15px;
        margin-left: 10px;
        margin-top: 5px;
        display: inline-block;
      }
    }
  }

  //   ========================
  figure.uni-box {
    padding: 10px;
    border: 1px solid #dce1eb;
    border-radius: 5px;
    overflow: hidden;
    background: #fff;
    &:hover {
      border: 1px dashed black;
    }
    .uni-details {
      .title {
        font-size: 12px;
        margin-bottom: 5px;
        font-family: montserrat-bold;
        span {
          opacity: 0.5;
        }
        img {
          margin-left: 10px;
        }
      }
      .uni-name {
        font-family: montserrat-bold;
        font-size: 14px;
      }
    }
    .logoBox {
      line-height: 110px;
      height: 110px;
      width: 100%;
      overflow: hidden;

      .uni-logo {
        width: 100%;
      }
    }
    .stats {
      text-align: center;
      .list-icon {
        height: 20px;
        margin-bottom: 10px;
      }
      p {
        font-size: 12px;
      }
    }
    .gallery {
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        margin: 1px;
        border-radius: 10px;
        height: 50px;
        width: 50px;
        overflow: hidden;
        cursor: pointer;
        position: relative;

        &.more::after {
          content: attr(data-text);
          font-size: 10px;
          color: #fff;
          position: absolute;
          top: 0px;
          left: 0;
          text-align: center;
          height: 50px;
          width: 50px;
          padding-top: 10px;
          background: radial-gradient(black, transparent);
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .downloader {
      margin-top: 15px;
      display: inline-block;
      margin-right: 15px;
    }
  }

  .h1-1 {
    font-size: 15px;
    font-family: montserrat-bold;
    margin-bottom: 15px;
  }
  hr {
    margin: 0 0 15px;
  }
  // ==================================
}
