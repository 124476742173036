.successModal {
  width: 800px;
  max-width: 90%;
  #popupSuccess {
    text-align: center;
    padding: 50px;

    img {
      height: 70px;
    }
    p {
      font-size: 14px;
      line-height: 24px;
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
}
