#MyProductsAndServices {
  .heading {
    font-size: 17px;
    font-family: montserrat-bold;
    margin-bottom: 30px;
  }
  figure {
    position: relative;
    .filter {
      position: absolute;
      right: 0;
      top: 5px;
      z-index: 9;
      box-shadow: 0px 2px 3px #0000000d;
      .ant-select-selector {
        width: 170px;
        font-size: 12px;
      }
    }
  }
  .not-found {
    padding: 18px 30px;
    text-align: center;
    background: #fff;
    margin-bottom: 40px;
    padding: 40px;
    img {
      width: 156px;
      height: 109px;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 5px;
      font-size: 12px;
    }
    button {
      margin-top: 20px;
    }
  }

  .card {
    margin-bottom: 20px;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer !important;
    .insider {
      padding: 20px;
      table {
        width: 100%;
        table-layout: fixed;
        tr {
          td {
            vertical-align: top;
            .header {
              font-size: 12px;
              margin-bottom: 7px;
            }
            .h4-1 {
              font-size: 13px;
              margin-bottom: 20px;
              white-space: normal;
              line-height: 20px;
            }
            .p-1 {
              font-size: 12px;
              padding: 0 !important;
            }
            .imgBox {
              img {
                height: 35px;
                margin-left: -7px;
                border-radius: 18px;
                &:first-child {
                  margin-left: 0;
                }
              }
              span {
                font-size: 12px;
                color: #707070;
                display: inline-block;
                margin-left: 5px;
              }
            }
            .button-primary {
              width: 140px;
              padding: 10px;
            }
          }
        }
      }
    }
    .checker {
      position: relative;
      margin-bottom: 10px;
      &.active {
        &::before {
          content: "";
          position: absolute;
          background: url("../../img/icons/Active.svg");
          height: 15px;
          width: 15px;
          top: 5px;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
      &.completed {
        &::before {
          content: "";
          position: absolute;
          background: url("../../img/icons/Completed.svg");
          height: 15px;
          width: 15px;
          top: 5px;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
      &.pending {
        &::before {
          content: "";
          position: absolute;
          background: url("../../img/icons/Pending.svg");
          height: 15px;
          width: 15px;
          top: 5px;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
      span {
        font-size: 12px;
        margin-left: 39px;
      }
    }
    .custom-toggler {
      margin-bottom: 1rem;
      position: absolute;
      right: 30px;
      top: 90px;
      background: transparent;
      border: 0;
      padding: 0;
      &:focus {
        outline: 0;
        box-shadow: unset;
      }
    }
    .form-group {
      margin-bottom: 0;
    }
    .uncontrollableCollapse {
      border-top: 1px solid #eee;
      .card {
        margin-bottom: 0;
        .overall {
          font-size: 10px;
        }
        .card-body {
          margin-bottom: 0;
        }
        .custom-control {
          margin-bottom: 7px;
          &.unique {
            .custom-control-label {
              &::before {
                border-color: #ffab4f;
                background-color: #fff;
              }
            }
            input:checked ~ .custom-control-label:before {
              border-color: #ffab4f;
              background-color: #ffab4f;
              border: 2px solid #ffab4f;
            }
          }
          input:checked ~ .custom-control-label:before {
            border-color: #c0dcfd;
            background-color: #c0dcfd;
            border: 2px solid #c0dcfd;
          }
          .custom-control-label {
            padding-top: 4px;
            padding-left: 15px;
            font-size: 12px;
            cursor: pointer;
            &::before {
              border-color: #c0dcfd;
              background-color: #fff;
              border: 2px solid #c0dcfd;
            }
          }
        }
      }
      .batcher {
        width: 100%;

        .header {
          font-size: 10px;
          margin-bottom: 7px;
        }
        .h4-1 {
          font-size: 12px;
          margin-bottom: 20px;
        }
        .p-1 {
          font-size: 12px;
          padding: 0 !important;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  #MyProductsAndServices {
    .heading {
      margin-top: 20px;
      font-size: 14px;
    }
    .titleName {
      margin-top: 20px;
    }
    figure {
      margin-top: -16px;

      .scroller {
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        width: 100vw;
        margin-left: -34px;
        padding: 0;
        margin-bottom: 0;
        li {
          display: inline-block;
          margin: 10px;
          width: 80%;
          &:first-child {
            margin-left: 35px;
          }
          &:last-child {
            margin-right: 35px;
          }
          .card {
            .insider table tr td .button-primary {
              width: 100%;
            }
          }
        }
      }

      .filter {
        top: -36px;
        box-shadow: unset;
        .ant-select-selector {
          width: auto;
          font-size: 10px;
          border: 0;
          background: transparent;
          .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
          .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
            padding-right: 20px !important;
          }
        }
        .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
          z-index: 1;
          margin-top: -8px;
        }
      }
      .ant-tabs-nav {
        // width: 100%;
        .ant-tabs-tab {
          margin-right: 0 !important;
          padding: 10px 10px !important;
          font-family: montserrat-semibold !important;
          font-size: 11px;
          width: 92%;
          text-align: center;
        }
      }
    }
  }
}
