#UploadDocumentSection {
  .upload-document-container {
    .heading {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #284157;
      margin-bottom: 16px;
    }
    .select-document-type {
      margin-bottom: 24px;
    }
    .type-of-document {
      margin-bottom: 16px;
      font-weight: 700;
    }
    .uploadDisabled {
      background: rgba(225, 229, 232, 0.24);
      border: 2px solid rgba(225, 229, 232, 0.24);
      .upload-doc {
        cursor: not-allowed;
      }
    }
    .upload {
      border: 2px dashed #e1e5e8;
      padding: 16px;
      border-radius: 16px;
      margin-bottom: 14px;
      .btn-not-active {
        pointer-events: none;
        background: #858585;
        color: white;
        cursor: pointer;
      }
      .btn-not-active:hover {
        background: #858585;
      }
      .uploading {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          width: 36px;
          height: 32px;
        }
        p {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #667a8b;
        }
      }
      .ant-upload-list {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ant-upload.ant-upload-select-picture-card {
        border: none;
        background-color: transparent;
        width: 100%;
      }
      .ant-upload-list-picture-card-container {
        display: none;
      }
      .ant-progress-line {
        width: 85%;
        display: flex;
        margin: auto;
      }
      .ant-progress-bg {
        height: 32px !important;
        background-color: #5EB568;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin: 0;
          margin-right: 5px;
        }
      }
      .showImg {
        display: none;
      }
      .choose-flex {
        display: flex;
      }
      .mobile-upload {
        display: none;
        button {
          position: relative;
          background: rgba(53, 168, 224, 0.12);
          border-radius: 8px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: #35a8e0;
          border: 0;
          padding: 6px 15px;
          font-weight: 500;
        }
        input {
          opacity: 0;
          z-index: 19;
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;
        }
      }
    }
    .upload-flex {
      display: flex;
      justify-content: space-between;
      .upload {
        padding: 0;
        width: 50%;
        .ant-upload-select-picture-card {
          margin: 0;
        }
        .type-of-document {
          margin: 0;
          text-align: center;
        }
        .upload-doc {
          padding: 0;
          margin: 0;

          img {
            margin: 0;
          }
          p {
            font-size: 12px;
            line-height: 16px;
            margin: 3px;
          }
          button {
            margin: 0;
            padding: 5px 10px;
            font-size: 10px;
          }
        }
      }
      .upload:nth-of-type(1) {
        margin-right: 16px;
      }
    }
    .upload-footer {
      .delete_doc_section {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          margin-right: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  #UploadDocumentSection {
    .showImg {
      display: block !important;
    }
    .mobile-upload {
      display: block !important;
    }
    .upload {
      padding: 6px !important;
      .show-text {
        display: none !important;
      }
      button {
        margin: 10px 8px 0 0 !important;
        height: 32px;
        width: 112px;
      }
    }
  }
}
