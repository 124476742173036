.ExperienceModal {
  max-width: 90vw;
  width: 600px;
  .modal-body {
    padding: 2rem;
    .close {
      cursor: pointer;
      height: 10px;
    }
    .heading {
      font-size: 16px;
      font-family: montserrat-bold;
    }

    form {
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 40px !important;
      }
      .ant-select-single .ant-select-selector .ant-select-selection-item,
      .ant-select-single
        .ant-select-selector
        .ant-select-selection-placeholder {
        line-height: 40px !important;
        font-size: 12px;
      }

      .button {
        width: 150px;
        margin-top: 20px;
        border-radius: 4px;
        height: 40px;
        padding: 0 30px;
      }

      label {
        font-size: 12px;
      }
      .profile_uploading {
        p {
          font-size: 12px;
        }
        .uploader {
          height: 120px;
          width: 100%;
          border: 1px dashed #00000026;
          border-radius: 5px;
          text-align: center;
          cursor: pointer;
          input {
            opacity: 0;
            position: absolute;
          }
          .dummy-img {
            height: 50px;
            width: 50px;
            margin-top: 30px;
          }
        }
      }
      .verify-btn {
        padding-left: 0;
      }
      .verify {
        background: #424242;
        color: #fff;
        border: 0;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        padding: 0;
      }

      input::placeholder,
      textarea::placeholder {
        color: #a6adb4;
        font-size: 12px;
      }
      textarea {
        min-height: 100px;
        border: 1px solid #00000026;
      }
      input {
        height: 40px;
        border: 1px solid #00000026;
      }
      .form-group {
        position: relative;
        #location {
          padding-right: 40px;
        }
        .loc_icon {
          position: absolute;
          right: 10px;
          top: 37px;
        }
      }
      input,
      textarea {
        font-size: 12px;
      }

      .saveButton {
        height: 40px;
        padding: 0 30px;
        background: #000;
        border: 1px solid #000;
        color: #fff;
        font-size: 12px;
        margin-top: 35px;
        margin-bottom: 20px;
        border-radius: 5px;
        width: 150px;
      }
    }
  }
}

.Custom-Toaster {
  background: #fff;
  padding: 20px;
}

#myProfile {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 20px 30px;
  }
  .ant-collapse {
    background-color: transparent;
    border: unset;
    .ant-collapse-item {
      border: 1px solid #d9d9d9;
      margin-bottom: 10px;
      background: #fff;

      .ant-collapse-header {
        padding: 12px 30px;
        font-family: montserrat-semibold;
      }
    }
  }
  form {
    label {
      font-size: 12px;
    }
    .profile_uploading {
      p {
        font-size: 12px;
      }
      .uploader {
        height: 120px;
        width: 100%;
        border: 1px dashed #00000026;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        input {
          opacity: 0;
          position: absolute;
        }
        .dummy-img {
          height: 50px;
          width: 50px;
          margin-top: 30px;
        }
      }
    }
    .verify-btn {
      padding-left: 0;
    }
    .verify {
      background: #424242;
      color: #fff;
      border: 0;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      padding: 0;
    }

    input::placeholder,
    textarea::placeholder {
      color: #a6adb4;
      font-size: 12px;
    }
    textarea {
      min-height: 100px;
      border: 1px solid #00000026;
    }
    input {
      height: 40px;
      border: 1px solid #00000026;
    }
    .form-group {
      position: relative;
      #location {
        padding-right: 40px;
      }
      .loc_icon {
        position: absolute;
        right: 10px;
        top: 37px;
      }
    }
    input,
    textarea {
      font-size: 12px;
    }

    .saveButton {
      height: 40px;
      padding: 0 30px;
      background: #000;
      border: 1px solid #000;
      color: #fff;
      font-size: 12px;
      margin-top: 35px;
      margin-bottom: 20px;
      border-radius: 5px;
    }
  }
  .ant-checkbox-wrapper {
    margin-top: 15px;
    .ant-checkbox + span {
      font-size: 12px;
      font-family: montserrat-semibold;
      color: #000;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 40px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    outline: 0;
  }
  .ant-select-show-search.ant-select-multiple .ant-select-selector input {
    border: 0;
    outline: 0;
  }
  .ant-select-multiple {
    .anticon {
      vertical-align: 0em;
    }
    .ant-select-selection-placeholder {
      font-size: 12px;
    }
    .ant-select-selector {
      padding: 0px 4px;
      min-height: 40px;
    }
    .ant-select-selection-item-content {
      font-size: 10px;
    }
  }
  .ant-select-selector {
    height: 40px;
    border-radius: 4px;
  }

  .sideBox {
    padding: 0 40px;
    .sideImage {
      width: 100%;
      padding: 35px 0px 0 0;
    }
    .details {
      font-size: 12px;
      padding: 0px 20px;
    }
  }
  .action-btn {
    border: 1px solid;
    border-radius: 2px;
    background: transparent;
    width: 60px;
    text-transform: uppercase;
    transition: 0.2s;
    margin-right: 20px;
    &:hover {
      background: #000;
      color: #fff;
      border: 1px solid #000;
      border-radius: 2px;
    }
  }
  .account-settings {
    .h-title {
      font-family: montserrat-semibold;
      font-size: 12px;
    }
    .p-desc {
      font-size: 10px;
    }
    .form-group {
      position: relative;
      .eye {
        height: 12px;
        position: absolute;
        right: 20px;
        top: 42px;
        cursor: pointer;

        &.eye-hide {
          height: 17px;
          top: 40px;
        }
      }
    }
    .passwordToggler {
      position: absolute;
      right: 15px;
      top: 0;
      font-size: 12px;
      cursor: pointer;
    }
    .userToggler {
      position: absolute;
      right: 15px;
      top: 0;
      font-size: 12px;
      cursor: pointer;
    }
    .note {
      font-size: 12px;
    }
    .user_isAvailable {
      margin-top: 40px;
      font-size: 12px;
      /* line-height: 22px; */
      vertical-align: middle;
      display: flex;
      flex: 1;
      img {
        margin-right: 10px;
      }
    }
  }
  table {
    tr {
      border-bottom: 1px solid #a6adb4;
      th {
        text-align: inherit;
        color: #000;
        font-family: montserrat-semibold;
        font-size: 12px;
        padding: 20px 0 10px;
      }
      td {
        text-align: inherit;
        color: #000;
        font-family: montserrat-light;
        font-size: 12px;
        padding: 20px 0 10px;
      }
    }
  }
  .table-format {
    // border-bottom: 1px solid #a6adb4;
    .row {
      border-bottom: 1px solid #a6adb4;
      padding: 10px 0;

      .th-format {
        font-size: 12px;
        font-family: montserrat-semibold;
        color: #666666;
      }
      .td-format {
        font-size: 12px;
        color: #666666;
        font-family: montserrat-light;
      }
    }
  }

  .personal_details-show {
    table {
      tr {
        td {
          vertical-align: top;
          width: 50%;
        }
      }
    }
    .profileBox {
      text-align: center;
      .imgBox {
        height: 60px;
        width: 60px;
        overflow: hidden;
        border-radius: 50%;
        margin: 35px auto 5px;
        img {
          width: 100%;
        }
      }
      .name {
        font-size: 12px;
        font-family: montserrat-bold;
        color: #000;
      }
    }
  }
  .academic {
    tr {
      td,
      th {
        width: 50%;
      }
    }
  }
  .academic_details {
    .h-title {
      font-size: 12px;
      font-family: montserrat-semibold;
    }
  }
  .professional {
    tr {
      td,
      th {
        width: 50%;
      }
    }
    p.label {
      font-size: 12px;
      margin-bottom: 10px;
    }
    .resumeUploader {
      height: 40px;
      width: 100%;
      border-radius: 5px;
      // border: 1px solid #424242;
      background: rgba(104, 97, 247, 0.2);
      position: relative;
      display: flex;
      align-items: center;
      flex: 1;
      padding: 0 15px;
      cursor: pointer;
      input {
        opacity: 0;
        position: absolute;
      }
      .fileName {
        font-size: 11px;
        margin-left: 10px;
        color: #6c63ff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .workExpHeader {
      border-bottom: 1px solid #00000026;
      margin-top: 30px;
      .add-Exp-btn {
        cursor: pointer;
      }
    }
    .workExpDetail {
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #00000026;
      position: relative;
      .edit {
        position: absolute;
        top: 10px;
        right: 3px;
        cursor: pointer;
      }
      h5 {
        font-size: 14px;
        margin-bottom: 10px;
        font-family: montserrat-bold;
      }
      p {
        margin-bottom: 5px;
        font-size: 12px;
      }
    }
    .innerTable {
      width: 100%;
      tr {
        border: 0;
        td,
        th {
          border: 0;
          width: 50%;
          padding: 3px 0;
        }
      }
    }
  }
  .preferences {
    tr {
      th,
      td {
        width: 50%;
      }
    }
  }
}

@media (max-width: 500px) {
  .ExperienceModal {
    margin: 20px auto;
    .modal-body form .saveButton {
      width: 100%;
    }
  }
  #myProfile {
    .sideBox {
      display: none;
    }
    .action-btn {
      font-size: 12px;
      padding: 0;
    }
    .personal_details-show .profileBox .imgBox {
      height: 80px;
      width: 80px;
      overflow: hidden;
      border-radius: 50%;
      margin: 5px auto 5px;
    }
    form {
      .saveButton {
        width: 100%;
        margin-top: 15px;
      }
      textarea {
        min-height: 70px;
        border: 1px solid #00000026;
      }
      .profile_uploading .uploader {
        margin-top: 2px;
        .dummy-img {
          height: 80px;
          width: 80px;
          margin-top: 25px;
          margin-top: 18px;
        }
      }
    }
    .account-settings .user_isAvailable {
      margin-top: 0;
      margin-bottom: 25px;
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3d3d3d !important;
  border-color: #3d3d3d !important;
}

.ant-checkbox-input:hover + .ant-checkbox-inner {
  border-color : black !important;
}
