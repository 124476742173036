.bookSession {
  padding-right: 30px;
  .schedular {
    max-height: 90vh;
    overflow: auto;
  }
  // .ant-tabs-tab-active {
  //   color: #fff !important;
  // }

  .bookings {
    margin-top: 60px;
    .nameHead {
      .details {
        float: left;
        margin-right: 20px;
        .designation {
          font-size: 12px;
          margin-bottom: 3px;
        }
        .name {
          font-size: 14px;
          font-family: montserrat-semibold;
        }
      }
      .imgBox {
        height: 35px;
        width: 35px;
        overflow: hidden;
        border-radius: 50%;
        margin-top: 2px;
        img {
          width: 100%;
        }
      }
    }
  }
  .tabers {
    margin-top: 15px;
    table {
      width: 100%;
    }
    .t__head {
      margin: 0;
      font-size: 12px;
      color: #aeaeae;
    }
    input {
      // margin-bottom: 10px;
      font-size: 14px;
      &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: unset;
      }
    }
    .t__value {
      margin: 0;
      font-size: 13px;
      color: #000000;
      margin-bottom: 15px;
      span {
        // margin-right: 20px;
      }
    }
    .ant-tabs-nav .ant-tabs-tab {
      color: #000000;
      font-weight: 400;
      z-index: 9;
      padding: 5px 15px;
    }
    .ant-tabs-ink-bar {
      height: 50px;
      background: #5c6ac4 !important;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
      color: #fff !important;
    }
  }

  .actions {
    margin-top: 50px;
    button {
      font-size: 15px;
      width: 100%;
    }
    .offline-msg {
      font-size: 12px;
      color: #888;
      margin: 10px;
      display: block;
    }
  }
  .actions1 {
    // margin-top: 50px;
    button {
      font-size: 15px;
      width: 100%;
    }
    .offline-msg {
      font-size: 12px;
      color: #888;
      margin: 10px;
      display: block;
    }
  }
  .instructions {
    ul {
      padding: 0;
      li {
        position: relative;
        display: inline-block;
        width: 50%;
        padding-left: 50px;
        margin-bottom: 20px;
        font-size: 13px;
        &.bookings {
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 1px;
            width: 30px;
            height: 17px;
            border-left: 4px solid #5563c0;
            background: #c2c9f6;
            z-index: 1;
          }
        }
        &.unavailable {
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 1px;
            width: 30px;
            height: 17px;
            border-left: 4px solid #f99482;
            background: #fef5f4;
          }
        }
        &.selected {
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 1px;
            width: 30px;
            height: 17px;
            border-left: 4px solid #cdcdcd;
            background: #e5e5e5;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .bookSession {
    padding: 0 20px;
    .actions button {
      font-size: 15px;
      width: 100%;
      margin-bottom: 20px;
    }
    .actions1 button {
      font-size: 15px;
      width: 100%;
      margin-bottom: 20px;
    }
    .bookings {
      margin-top: 15px;
    }
  }
  .bookSessionModal {
    margin: 0 auto;
    .schedular {
      display: none;
    }
  }
}
