.verifyMobileModal {
  width: 600px;
  max-width: 90vw;
  .close {
    right: 30px;
    position: absolute;
    top: 25px;
    cursor: pointer;
  }
}
#fileUploadSection {
  padding: 35px 55px;
  h1 {
    font-size: 15px;
    font-family: montserrat-bold;
    margin-bottom: 50px;
  }
  p {
    font-size: 12px;
    color: #363636;
    margin-bottom: 40px;
    strong {
      font-size: 12px;
      color: #000;
    }
  }
  .fileUpload {
    height: 100px;
    width: 100px;
    background: #e7e7e7;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    &:hover {
      img {
        width: 50%;
      }
    }
    img {
      width: 43%;
      transition: 0.3s;
    }
    input {
      opacity: 0;
    }
  }
  .profileUpload {
    height: 100px;
    width: 100px;
    background: #e7e7e7;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    // &:hover {
    //   .userPic {
    //     width: 50%;
    //   }
    // }
    img {
      &.userPic {
        width: 100%;
        transition: 0.3s;
        position: absolute;
        top: 0;
        left: 0;
      }
      &.camera {
        z-index: 9;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    input {
      opacity: 0;
    }
  }
  .fileUpload {
    height: 100px;
    width: 100px;
    background: #e7e7e7;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    &:hover {
      img {
        width: 50%;
      }
    }
    img {
      width: 43%;
      transition: 0.3s;
    }
    input {
      opacity: 0;
    }
  }
  .filename {
    font-size: 10px;
    margin-bottom: 0;
    margin-top: 8px;
  }
  button {
    margin: 45px 0 0px 0;
    width: 50%;
    padding: 10px;
    border-radius: 5px;
    letter-spacing: 1px;
  }
  .form-group {
    label {
      text-align: left;
      display: block;
      font-size: 11px;
      color: #000;
      margin-bottom: 5px;
    }

    input {
      background: #f7f7f7;
      border: 1px solid #ebebeb;
      font-size: 13px;
      height: 40px;
    }
  }
}
