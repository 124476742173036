#amountHeaderSection {
  min-height: 100%;
  // background: #fbfdfe;
  // background-image: url(https://leverageedunew.s3.amazonaws.com/flybank/background1.png);
  background: #000;
  // background-repeat: no-repeat;
  // background-size: cover;
  position: relative;

  
  // previous example 
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
      transform: rotate(270deg);
      margin-bottom: -3px;
    }
    .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow  {
      transform: rotate(180deg);
      margin-bottom: -3px;
    }
 
  #amoundHeaderHeadSection {
    background: #000000;
    // box-shadow: 0px 2px 4px rgba(102, 122, 139, 0.16);
    padding: 20px 0;
    .head-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo-head {
        .logo {
        }
      }
     
      // .process-steps {
      //   display: flex;
      //   align-items: center;
      //   justify-content: space-between;
      //   width: 50%;
      //   padding: 1px;
      //   .step {
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     flex-direction: column;
      //     flex: 1;
      //     position: relative;
      //     &:last-child {
      //       &::before {
      //         display: none;
      //       }
      //       .number {
      //       }
      //     }
      //     &.complete {
      //       &::before {
      //         background: #6181FF;
      //       }
      //       .number {
      //         background: #6181FF;
      //         border: 1.5px solid #6181FF;
      //         color: #000000;
      //       }
      //     }
      //     &.active {
      //       .number {
      //         background: #FEC807;
      //         border: 2px solid #FEC807;
      //         // color: #000000;
      //         // padding: 8px;
      //         // position: relative;
      //         // z-index: 1;
      //         background: #FEC807;
      //         // border-color: #9AE7DC
      //         animation:.3s ease-out ;
      //         background-image: url("/assets/img/Tick.png");
      //         // &:after
      //         // background: #9AE7DC;
      //       }
      //     }
      //     &.number {&::after{
      //       background: #9AE7DC;
      //     }

      //     }

      //     &::before {
      //       content: "";
      //       height: 2px;
      //       width: 100%;
      //       background: #C4C4C4;
      //       position: absolute;
      //       left: 50%;
      //       top: 12px;
      //     }
      //     .number {
      //       border: 2px solid #e1e5e8;
      //       box-sizing: border-box;
      //       width: 25px;
      //       height: 25px;
      //       border-radius: 50%;
      //       display: flex;
      //       align-items: center;
      //       justify-content: center;
      //       color: #fff;
      //       background: #e1e5e8;
      //       z-index: 9;
      //       position: relative;
      //       // position: relative;
      //     }
      //     .label {
      //       font-style: normal;
      //       font-weight: 500;
      //       font-size: 16px;
      //       line-height: 24px;
      //       /* identical to box height, or 150% */

      //       text-align: center;

      //       color: #667a8b;
      //       margin-top: 5px;
      //     }
      //   }
      // }
      .cross {
      }
    }  
  }
}
.pointer {
  cursor: pointer;
}

.custom-timer-header {
  .timer-strip {
    text-align: center;
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    background: #ffcb2f;
    color: #284157;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .timer-completed {
    background: red;
    color: #fff;
  }
}

@media (max-width: 768px) {
  #amountHeaderSection {
    #amoundHeaderHeadSection {
      .head-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        .logo-head {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
        // .process-steps {
        //   width: 100%;
        //   margin-top: 27px;
        //   .step {
        //     .number::before {
        //       width: 60px;
        //       height: 2px;
        //     }
        //     .label {
        //       font-size: 13px;
        //       line-height: 20px;
        //     }
        //   }
          
        //     @keyframes blob {

        //       0% {transform: scale(.5)}
        //       50% {transform: scale(1.1)}
        //       100% {transform: scale(1)}
        //     }
          
          
        // }
        
      }
    }
  }
}
