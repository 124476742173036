@import "variable";
body {
  #root {
    .mobile-hide {
      display: block;
    }
    .mobile-show {
      display: none !important;
    }
  }
}
// mobile devices iphone 5/SE
@media (max-width: 320px) {
}

.saveBtn4{
  background: #000000;
  border-radius: 74px;
  color: #FFFFFF;
  // font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  height: 50px;
  // padding: 0 30px;
  width: 193px;
  border: 0;
  cursor: pointer;
  &:hover {
    background-color: white !important;
    color: black !important;
    border: 1px solid black !important;
  }
}

@media (max-width: 480px) {
  #UserPersonalDetailsSection,
  #UserDocumentUploadSection,
  #paymentSection,
  #revDetailSection {
    padding-bottom: 0px;
    .encaps {
      display: flex;
      flex-direction: row;
      align-items: center;
      .details {
        margin-left: 20px;
        h4,
        p {
          text-align: left;
          margin-bottom: 0;
        }
      }
    }
  }
  #amountHeaderSection #amoundHeaderHeadSection .head-container .process-steps .step.complete .number::before {
    width: 60px;
  }
  .floatingbottomAction {
    padding: 15px 25px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    // background: #000000 ;
    box-shadow: 0px -2px 4px rgba(102, 122, 139, 0.16);
    z-index: 1;
    button {
      margin: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      width: 100%;
    }

    .tag {
      width: 100%;
    }
  }

  .paymentStepsViewSection #paymentStepsViewSection .steps .step span {
    font-size: 10px;
    line-height: 14px;
  }
}

@media (max-width: 768px) {
  .button-primary {
    font-size: 16px !important;
    padding: 0 10px;
  }
  .message {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
    float: unset;
  }
  .go-back {
    margin-bottom: 10px;
  }
  #needHelpSection {
    .contact {
      flex-direction: column;
      a {
        width: 100%;
        margin: 0;
        margin-bottom: 15px;
        img {
          height: 15px;
        }
      }
    }
    .heading {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height, or 150% */
      color: #000000;
      border-bottom: 1px solid #b1b1b1;
      padding-bottom: 25px;
      margin: 0;
    }
    .accordion .accordion-item {
      .accordion-collapse .accordion-body p {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
      }
      .accordion-header .accordion-button {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        /* or 129% */

        color: #000000;
        text-align: left;
        &::after {
          right: 15px;
        }
      }
    }
  }
  .transactionData {
    .heading {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 34px;
      /* identical to box height, or 150% */

      color: #284157;
    }
  }
  #amountHeaderSection {
    padding-bottom: 0px;
  }
  #revDetailSection {
    .floatingbottomAction {
      button {
        width: 100%;
      }
    }
    .edit {
      height: 30px;
      padding: 0 15px;
      font-size: 12px;
    }
    table tr td {
      font-size: 14px !important;
      line-height: 16px !important;
    }
    .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
    .head-text {
      margin-top: 20px;
      margin-bottom: 15px;
    }
    .ant-radio-wrapper {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #284157;
      a {
        color: #35a8e0;
      }
    }
  }
  #paymentSuccessFull .paymentDone {
    img {
      margin: 10px;
      width: 85px;
    }
    .paidbillDetail {
      max-width: 100%;
      padding: 20px;
      p {
        font-size: 10px;
        line-height: 20px;
      }
    }
  }
  .input-encaps {
    .type-select .dropdown .dropdown-menu {
      max-height: 290px;
      .dropdown-item {
        .currency-code {
          min-width: 30px;
          font-size: 13px;
        }
        .currency {
          font-size: 12px;
        }
      }
    }
  }
}

// desktop devices
@media (min-width: 768px) {
  .mobileView {
    display: none;
  }
  .webView {
    display: block;
  }
  .schedulerModal {
    max-width: 70%;
  }
}

/* iPad Landscape */
@media only screen and (max-device-width: 1024px) {
  /* SCSS */
  body {
    // overflow-x: hidden!important;
    #root {
      .mobile-hide {
        display: none !important;
      }
      .mobile-show {
        display: block !important;
      }

      main.Wrapper {
        margin-left: 0;
        padding: 20px 15px 20px 75px;
        // overflow: hidden;
      }
    }
  }
}

// /* iPad Portrait */
// @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
//   /* SCSS */
// }

@media (max-width: 1199px) and (min-width: 768px) {
}

//responsive for macbook air
@media (max-width: 1365px) and (min-width: 1023px) {
}
