#UserPersonalDetailsSection {
  .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
  }
  .ant-collapse-header-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    svg {
    transform: rotate(270deg);
    margin-bottom: -3px;
  }
  .ant-collapse-icon-position-end
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    transform: rotate(180deg);
    margin-bottom: -3px;
  }
  .ant-picker-input > input {
    color: black !important;
    font-size: 1rem;
    &:hover {
      border-color: transparent !important;
    }
  }
  .ant-picker {
    filter: invert(0.9);
    width: 98%;
    color: #000;
    border-bottom: 1px solid rgb(0, 0, 0);
    background: transparent;
    border: none !important;
    border-bottom: 2px solid #000 !important;
  }
  .ant-picker-focused {
    border-color: transparent !important;
    box-shadow: none !important;
    border-bottom: 2px solid #000 !important;
  }
  .ant-picker.ant-picker-disabled .ant-picker-suffix{
      display: none;
    }
  .MuiSelect-icon {
    background-image: url("https://leverageedunew.s3.amazonaws.com/assets/new-images/dropdown.png") !important;
    background-repeat: no-repeat;
  }
  .MuiSelect-icon.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    display: none;
  }
  .MuiSvgIcon-root {
    fill: none;
  }

  .saveBtn22 {
    width: 36% !important;
  }

  @media only screen and (max-width: 600px) {
    .saveBtn22 {
      width: 100% !important;
    }
    .go-back {
      font-size: 20px !important;
    }
  }

  @media only screen and (max-width: 425px) {
    .saveBtn22 {
      width: 100% !important;
    }
    .go-back {
      font-size: 14px !important;
    }
  }
}
