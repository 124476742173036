#uploadDocSection {
  padding-top: 32px;
  .uploadDocContainer {
    .go-back {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      .accepted-formats {
        font-size: 16px;
        line-height: 24px;
        color: #667a8b;
        font-weight: normal;
      }
      .ant-progress {
        width: 184px;
        .ant-progress-bg {
          background-color: #35a8e0;
        }
      }
    }
    .doc-upload-section {
      display: flex;
      justify-content: space-between;
      .doc-box {
        width: 32%;
        border: 1px solid black;
        box-sizing: border-box;
        box-shadow: 0px 4px 24px rgba(102, 122, 139, 0.16);
        border-radius: 16px;
        padding: 24px 16px;
        background-color: white;
      }
    }
    .upload-footer {
      display: flex;
      justify-content: space-between;
      img {
        cursor: pointer;
      }
      p {
        margin: 0;
      }
      button {
        border: none;
        color: #fff;
        background: black;
        border-radius: 4px;
        font-size: 10px;
        font-weight: 500;
      }
    }
    .continue-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
      button {
        width: 121px;
        height: 48px;
        border: none;
        color: #fff;
        background: #000000;
        border-radius: 74px;
        font-size: 16px;
        font-weight: 500;
        &:hover {
          color: black !important;
          background-color: white !important;
          border: 1px solid black !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 769px) {
  #uploadDocSection {
    .doc-upload-section {
      display: block !important;
      .doc-box {
        width: 100% !important;
        margin-bottom: 16px;
      }
    }
  }
}

@media only screen and (max-width: 520px) {
  #uploadDocSection {
    .uploadDocContainer {
      .go-back {
        display: block;
        .accepted-formats {
          border-bottom: 1px solid #e1e5e8;
          padding-bottom: 15px;
          margin-bottom: 0;
        }
        .ant-progress {
          width: 100% !important;
        }
      }
      .continue-btn {
        position: fixed;
        bottom: 0;
        background: #fff;
        width: 100%;
        margin: 0 -14px;
        align-items: center;
        justify-content: center;
        padding: 10px;
        box-shadow: 0px -2px 4px rgba(102, 122, 139, 0.16);
        z-index: 1;
        button {
          width: 96% !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .go-back {
    font-size: 20px !important;
  }
}


