@import url('https://fonts.googleapis.com/css2?family=Uchen&display=swap');

#needHelpSection {
  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    color: #000000;
    margin-bottom: 25px;
    img {
      margin-right: 10px;
    }
  }
  .bgWhite {
    background: #fff;
    padding: 24px;
    border-radius: 4px;
    border: 1px solid #000000;
    box-shadow: 0px 4px 8px rgba(102, 122, 139, 0.12);
  }
  .contact {
    display: flex;
    align-items: center;
    a {
      margin-right: 100px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      color: #1890ff;
      img {
        margin-right: 10px;
      }
    }
  }
  .choose-faq-category {
    display: -webkit-inline-box;
    overflow: auto;
    margin-bottom: 64px;
    width: 100%;
    .faq-chips {
      width: 15.3%;
      max-width: 200px;
      height: 64px;
      background: #e1e5e8;
      border-radius: 32px;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #284157;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 18px;
      cursor: pointer;
    }
    .faq-chips:nth-of-type(1) {
      margin-left: 0;
    }
    .faq-chips-active {
      background: #000000; //ye
      color: #fff;
      pointer-events: none;
    }
    .faq-chips:hover {
      background: #000000;
      color: #fff;
    }
  }

  @media screen and (max-width: 998px) {
    .choose-faq-category {
      margin-bottom: 32px !important;
      .faq-chips {
        width: 114px !important;
        max-width: 114px !important;
        height: 48px !important;
        font-size: 16px !important;
      }
    }
  }

  .accordion {
    .accordion-item {
      border: 0;
      background: transparent;
      border-bottom: 1px solid #e1e5e8 !important;
      // &:first-child {
      //   border-top: 1px solid #e1e5e8 !important;
      // }
      .accordion-header {
        margin-bottom: 0;
        .accordion-button {
          padding: 20px 0;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          background: transparent;
          color: #000000;
          box-shadow: none;
          border: 0;
          padding-right: 30px;
          width: 100%;
          text-align: left;
          &::after {
            background-image: url("https://s3.amazonaws.com/leverageedunew/flybank/expand_more_black_24dp.png");
            height: 13px;
            width: 13px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
          &:not(.collapsed)::after {
            transform: rotate(180deg);
          }
        }
      }
      .accordion-collapse {
        .accordion-body {
          color: #3D3D3D;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
          padding: 0px 0 20px;
          p {
            margin: 0;
            text-align: left;
          }
        }
      }
      .MuiAccordionSummary-content.Mui-expanded {
        margin: 0;
      }
      .MuiAccordionSummary-root.Mui-expanded {
        min-height: 30px;
      }
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
