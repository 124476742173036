#navContainer {
  &.open {
    left: 0 !important;
  }
  #sidebar {
    height: 100vh;
    background: #000000;
    position: fixed;
    top: 0;
    left: 0;
    .Logo {
      img {
        height: 40px;
        margin: 15px;
      }
    }
    ul {
      padding: 0;
      a {
        &.active {
          li {
            // border-left: 4px solid #9296eb;
            background: #3D3D3D;
          }
        }
        li {
          color: #ffffff;
          font-size: 12px;
          list-style-type: none;
          transition: 0.3s;
          border-left: 4px solid transparent;
          padding: 15px 5px 15px 20px;
          &:active{
            background: #3D3D3D !important;
          }
          &:hover {
            border-left: 4px solid #9296eb;
            background: #3c415e;
          }
          img {
            margin-top: -2px;
            margin-right: 8px;
            width: 26px;
            filter: grayscale(100%);
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  #navContainer {
    // background: grey;
    width: auto;
    position: fixed;
    height: 100vh;
    z-index: 999999999;
    left: -100vw;
    transition: 0.3s;
    top: 0;
    &.mobile-open {
      left: 0;
    }
    #sidebar {
      position: relative;
      transition: 0.3s;
      height: 100vh;
      width: 70vw;
      // z-index: 9999;
      // box-shadow: 0px 9px 34px 2px #666;
    }
  }
  #sidebar-wrapper {
    display: none;
    width: 0px;
    width: 25px;
  }
  main.Wrapper {
    margin-left: 0px !important;
    // padding: 10px !important;

    padding: 55px 0px !important;
  }
  #topNav {
    img.navLogo {
      display: none;
    }
  }
}

@media (min-width: 768px) {
  #navContainer {
    display: none;
    // background: grey;
    width: auto;
    position: fixed;
    height: 100vh;
    z-index: 999999999;
    left: -100vw;
    transition: 0.3s;
    top: 0;
    &.mobile-open {
      left: 0;
    }
    #sidebar {
      position: relative;
      transition: 0.3s;
      height: 100vh;
      width: 50vw;
      // z-index: 9999;
      box-shadow: 15px 27px 74px 5px #666;
    }
  }
}
