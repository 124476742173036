#latest-transaction {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #000000;
  box-shadow: 0px 4px 8px rgba(102, 122, 139, 0.12);
  .pd-bottom-24 {
    padding-bottom: 24px;
  }
  .pd-top-24 {
    padding-top: 24px;
  }
  .border-bottom-lt {
    border-bottom: 1.5px solid #c4c4c4;
  }
  .latest-transaction-container {
    padding: 24px;
    .lt-heading {
      display: flex;
      align-items: center;
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin: 0;
      }
      a {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        color: #0487F3;
      }
    }
    .lt-details {
      table {
        width: 100%;
        tr {
          display: flex;
          justify-content: space-between;
          .transact {
            margin: 0 9px;
          }
          .td {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
          }
          .td-light {
            color: #989898;
          }
        }
      }
    }
    .lt-download {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      padding-top: 40px;
      cursor: pointer;
      .lt-download-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 33.33%;
        margin-bottom: 24px;
        .lt-img-box {
          border: 1px solid #1890ff;
          height: 40px;
          width: 40px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;
        }
        p {
          margin-top: 8px;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          text-align: center;
          color: #000000;
        }
      }
    }
  }
  @media only screen and (max-width: 425px) {
    .transactOne {
      width: 248px !important;
    }
  }
}
