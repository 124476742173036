.verifyMobileModal {
  width: 600px;
  max-width: 90vw;
  .close {
    right: 30px;
    position: absolute;
    top: 25px;
    cursor: pointer;
  }
}
#linkedInSection {
  padding: 35px 55px;
  h1 {
    font-size: 15px;
    font-family: montserrat-bold;
    margin-bottom: 50px;
  }
  p {
    font-size: 12px;
    color: #363636;
    margin-bottom: 40px;
    strong {
      font-size: 12px;
      color: #000;
    }
  }
  ul {
    padding: 0;
    padding-left: 45px;
    text-align: center;
    li {
      display: inline-block;
      font-size: 11px;
      color: #000;
      margin: 5px;
      padding: 5px;
      // border: 1px solid;
      border-radius: 37px;
      width: 90px;
      text-align: center !important;
      label {
        input {
          margin-top: -11px;
          &:checked ~ label {
            background: black;
            color: #fff;
          }
        }
      }
    }
  }
  button {
    margin: 20px 0 0px 0;
    width: 50%;
    padding: 10px;
    border-radius: 5px;
    letter-spacing: 1px;
  }
  .form-group {
    label {
      text-align: left;
      display: block;
      font-size: 11px;
      color: #000;
      margin-bottom: 5px;
    }

    input {
      background: #f7f7f7;
      border: 1px solid #ebebeb;
      font-size: 13px;
      height: 40px;
    }
    textarea {
      font-size: 12px;
    }
  }
}

@media (max-width: 500px) {
  .verifyMobileModal .close {
    height: 13px;
  }
  #linkedInSection {
    padding: 35px 5px;
    // height: 80vh;
    display: flex;
    align-items: center;
    h1 {
      font-size: 17px;
    }
    .form-control {
      font-size: 13px;
      height: 40px;
    }
    .text-center {
      width: 100%;
      button {
        width: 100%;
        margin: 0px 0 0px 0;
        padding: 12px;
      }
    }
  }
}
