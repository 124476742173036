#upload_payment_reciept {
  padding: 16px;
  .header {
    // display: flex;
    // align-items: flex-start;
    // justify-content: space-between;
    // margin-bottom: 32px;
    float: right;
    h3 {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
    }
  }
  .show-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #999999;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 50px;
    background: rgba(122, 122, 122, 0.1);
    backdrop-filter: blur(60px);
    border-radius: 16px;
    border: unset;
  }
  
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    justify-content: space-between;
  }
  .ant-upload-list-picture-card-container {
    display: none;
  }
  .ant-progress-bg {
    height: 34px !important;
    background-color: #1890ff;
  }
  .upload-doc {
    margin-bottom: 0;
    padding: 0px 16px;
    p {
      margin: unset;
    }
    .showImg {
      margin: 0;
      margin-right: 5px;
    }
  }
  .doc-name {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #FFFFFF;
    padding-bottom: 32px;
    padding-top: 32px;
    text-align: left;
  }
  .upload-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 24px;
    gap: 8px;
    width: 89px;
    height: 32px;
    background: #6181FF;
    border-radius: 8px;
    border: 1px solid #6181FF;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-right: 16px;
  }
  .uploading {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .submit-btn {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 8px;
    cursor: pointer;
    button {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 12px 32px;
      gap: 8px;
      border: none;
      width: 100%;
      height: 48px;
      background: #6181FF;
      border-radius: 12px;
      color: #FFFFFF;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .remove {
    margin-right: 16px;
    cursor: pointer;
  }
  .upload-doc-box {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgba(122, 122, 122, 0.1);
    backdrop-filter: blur(60px);
    border-radius: 16px;
    border: unset;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (max-width: 500px) {
  #upload_payment_reciept {
    .show-text {
      // display: none;
    }
  }
}
