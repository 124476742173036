#timerModalSection {
  .ant-progress-text {
    color: #000;
  }
  .timer-heading {
    text-align: center;
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #284157;
      margin: 0;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #667a8b;
      margin: 0;
    }
  }
  .timer-container {
    text-align: center;
    margin: 24px 0;
  }
  .timer-footer {
    text-align: center;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #667a8b;
      margin-bottom: 16px;
    }
    button {
      width: 128px;
      height: 48px;
      background: #000000;
      border-radius: 74px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      border: none;
      &:hover {
        background-color: white;
        color: black;
        border: 1px solid black;
      }
    }
  }
}
