#homeBannerSection {
  padding: 50px 0;

  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    color: #000000;
  }
  .sub-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    color: #989898;
  }
  .form {
    box-sizing: border-box;
    border-radius: 16px;
    // min-height: 400px;
    width: 100%;
    label {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      color: #667a8b;
      margin-bottom: 10px;
    }
    .data {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      span {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 167% */

        color: #667a8b;
      }
    }
    @mixin button {
      flex: 1;
      margin-left: 20px;

      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      display: flex;
      align-items: center;
      text-align: center;

      text-align: center;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 74px;
      justify-content: center;
      height: 45px;
      &:first-child {
        margin-left: 0;
      }
    }
    .secondary-btn {
      @include button;
      background: transparent;
      color: #35a8e0;
    }
    .primary-btn {
      @include button;
      background: #000000;
      color: #fff;
      width: 100%;
      &:hover {
        background-color: white;
        color: black;
      }
    }
    a {
      flex: 1;
      width: 100%;
    }
  }
  .moneyHop {
    margin-top: 10px;
  }
}

.input-encaps {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1.5px solid #e1e5e8;
  border-radius: 8px;
  height: 50px;
  align-items: center;
  .data-input {
    flex: 1;
    border-right: 1.5px solid #e1e5e8;
    .form-control {
      border: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      display: flex;
      align-items: center;

      color: #284157;
    }
  }
  .type-select {
    width: 150px;
    .dropdown {
      .btn-secondary {
        background: #fff;
        color: #000;
        border: 0;
        box-shadow: unset;
        outline: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        display: flex;
        align-items: center;

        color: #284157;
        .flag {
          height: 18px;
          margin-right: 10px;
        }
      }
      .dropdown-toggle {
        background: #fff;
        color: #000;
        border: 0;
        box-shadow: unset;
        outline: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        display: flex;
        align-items: center;

        color: #284157;
        &::after {
          position: absolute;
          right: 10px;
          top: 20px;
          background: url("https://leverageedunew.s3.us-east-1.amazonaws.com/assets/img/leverage-finance/arrow-down.svg");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          height: 10px;
          width: 10px;
          border: 0;
        }
        > div {
          display: flex;
          align-items: center;
        }
        .flag {
          height: 18px;
          margin-right: 10px;
        }
      }
      .dropdown-menu {
        width: auto;
        .header {
          display: flex;
          align-items: center;
          outline: 0;
          border-bottom: 1.5px solid #e1e5e8;
          .search-icon {
            margin-right: 10px;
            height: 17px;
          }
          .search-input {
            flex: 1 1;
            border: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            color: #667a8b;
            outline: 0;
            box-shadow: unset;
            height: unset !important;
          }
        }
        .dropdown-item {
          display: flex;
          align-items: center;
          &::before {
            display: none;
          }
          .flag {
            margin-right: 10px;
            height: 18px;
          }
          .currency-code {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #284157;
            margin-right: 10px;
            min-width: 40px;
          }
          .currency {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            color: #667a8b;
          }
          &.active {
            .currency-code {
              color: #fff;
            }
            .currency {
              color: #eaeaea;
            }
          }
        }
      }
    }
  }
}

.type-select {
  width: 150px;
  .dropdown {
    .dropdown-toggle {
      background: #fff;
      width: 100%;
      color: #000;
      box-shadow: none !important;
      outline: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      display: block;
      align-items: center;

      color: #284157;
      height: 48px;
      overflow: hidden;
      border: 1px solid #ddd;
      border-radius: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 40px;
      text-align: left;
      &::after {
        position: absolute;
        right: 10px;
        top: 20px;
        background: url("https://leverageedunew.s3.us-east-1.amazonaws.com/assets/img/leverage-finance/arrow-down.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 10px;
        width: 10px;
        border: 0;
      }
      .flag {
        height: 18px;
        margin-right: 10px;
      }
      &.emptyState {
        color: #92a0ac;
        font-weight: 400;
      }
    }
    .dropdown-menu {
      background: #ffffff;
      border: 1px solid #f3f3f3;
      box-sizing: border-box;
      box-shadow: 0px 8px 24px rgba(102, 122, 139, 0.16);
      border-radius: 16px;
      padding: 5px;
      max-height: 180px;
      overflow: auto;
      button {
        border-radius: 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #284157;
        white-space: pre-wrap;
        &::before {
          display: none;
        }

        &:active,
        &.active {
          background: black;
          color: #fff;
        }
      }
    }
  }
}
