@import "variable"; // variable file import
$btnBorderRadius: 10px;

//transparent button
.transparent-button {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
}
button:disabled {
  opacity: .6;
  cursor: not-allowed;
  &:hover {
    cursor: not-allowed !important;
    background-color: #858585 !important;
    color: white !important;
    border: 1px solid #858585 !important;
  }
}
.MuiFormLabel-root{
  color: #999999 !important;
}
.badges {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 50px;
  &.primary {
    color: #fff;
    background: $primary-color;
  }
  &.sm {
    font-size: 10px;
    padding: 3px 12px;
  }
  &.md {
    font-size: 12px;
  }
  &.md {
    font-size: 14px;
  }
  &.outlined {
    background: transparent;
    &.warning {
      color: orange;
      border: 1px solid orange;
    }
    &.info {
      color: blue;
      border: 1px solid blue;
    }
  }
}

.button-primary {
  background: $primary-color;
  color: $white-color;
  font-size: 12px;
  border-radius: 0;
  border: 0;
  padding: 8px 30px;
}
.button-secondary {
  font-size: 12px;
  font-family: montserrat-semibold;
  border: 1px solid;
  padding: 8px 15px;
  margin-bottom: 20px;
  background: transparent;
  width: 70%;
  transition: 0.1s;
  &:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
  }
}
.dropdown-menu {
  padding: 0;
  min-width: 13rem;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.7rem 1.5rem 0.7rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 10px;
  border-bottom: 1px solid #eee;
  &:hover {
    font-weight: 600;
    &::before {
      opacity: 1;
    }
  }
  &::before {
    content: "";
    height: 38px;
    width: 8px;
    background: #a3a0fb4d;
    display: block;
    position: absolute;
    left: 0;
    margin-top: -12px;
    opacity: 0;
    transition: 0.3s;
  }
  &.active,
  &:active {
    color: #000;
    text-decoration: none;
    background-color: #b8d5f5;
  }
}
.primary-button {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #35a8e0;
  border-radius: 8px;
  height: 50px;
  padding: 0 30px;
  border: 0;
  box-shadow: none;
  outline: 0;
  transition: .5s;
  
  &.black{
    color: #fff;
    background: #284157;
    border: 1px solid #284157;

  }
  &.outlined {
    background: transparent;
    border: 1px solid #35a8e0;
    color: #35a8e0;
    &.black{
      color: #2F3B52;
      border: 1px solid #2F3B52;
    }
  }
  &:hover{
    background: #007CB9;
    &.black{
      color: #fff;
      background: #062541;
      border: 1px solid #062541;

    }
  }
}
.secondary-button {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #284157;
  border-radius: 8px;
  height: 50px;
  padding: 0 30px;
  border: 0;
  box-shadow: none;
  outline: 0;
}
.MuiFormLabel-root.Mui-focused {
  color: #35a8e0 !important;
}

.MuiOutlinedInput-root {
  &.Mui-focused {
    fieldset {
      border: 2px solid #35a8e0 !important;
    }
  }
}
@media (max-width: 1024px) {
  .button-secondary {
    font-size: 10px;
    margin-bottom: 10px;
    width: 85%;
  }
}
