.back-nav {
  display: inline-block;
  margin-bottom: 30px;
  font-family: montserrat-medium;
  color: #000 !important;
  img {
    margin-right: 5px;
    margin-top: -3px;
  }
}
.width100 {
  width: 100%;
  thead {
    background: #f4f6f8;
  }
  &.test-prep {
    tr {
      td:nth-child(1) {
        width: 55%;
      }
      th {
        padding: 20px 30px;
        font-family: montserrat-semibold;
        color: #000;
      }
      td {
        padding: 15px 30px;
        // border-bottom: 1px solid #efefef;

        span {
          &.badge {
            display: inline-block;
            padding: 5px 13px;
            border-radius: 20px;
            font-size: 12px;
            color: #fff;
            font-family: montserrat-light;
            font-weight: 400;
            width: 90px;
            &.present {
              background: #21ba45;
            }
            &.absent {
              background: #db2828;
            }
          }
        }
        .imgBox {
          img {
            height: 35px;
            border-radius: 50%;
            margin-left: -7px;
            &:first-child {
              margin-left: 0;
            }
          }
          span {
            font-size: 12px;
            color: #707070;
            display: inline-block;
            margin-left: 5px;
          }
        }
        .keyAvatar {
          margin: 7px 20px;
          display: inline-block;
          color: #000;
        }
      }
    }
  }
}
#productDetailsSection {
  .card {
    margin-bottom: 20px;
    box-shadow: 0px 3px 6px #00000029;
    .insider {
      padding: 20px;
      table {
        tr {
          td {
            vertical-align: top;
            .header {
              font-size: 11px;
              margin-bottom: 7px;
            }
            .h4-1 {
              font-size: 13px;
              margin-bottom: 20px;
              line-height: 20px;
            }
            .h4-2 {
              font-size: 22px;
              margin-bottom: 20px;
            }
            .p-1 {
              font-size: 12px;
              padding: 0 !important;
              strong {
                font-family: montserrat-semibold;
                color: #000;
              }
            }
            .imgBox {
              img {
                height: 35px;
                border-radius: 50%;
                margin-left: -7px;
                &:first-child {
                  margin-left: 0;
                }
              }
              span {
                font-size: 12px;
                color: #707070;
                display: inline-block;
                margin-left: 5px;
              }
            }
            .button-primary {
              width: 140px;
              padding: 10px;
            }
          }
        }
      }
      .stages {
        width: 100%;
        table {
          width: 100%;
          .header {
            font-size: 11px;
            margin-bottom: 7px;
          }
          .h4-1 {
            font-size: 13px;
            margin-bottom: 20px;
            line-height: 20px;
          }
          .h4-2 {
            font-size: 22px;
            margin-bottom: 20px;
          }
          .p-1 {
            font-size: 12px;
            padding: 0 !important;
            strong {
              font-family: montserrat-semibold;
              color: #000;
            }
          }
        }
        .card {
          margin-bottom: unset;
          box-shadow: unset;
          .card-body {
            padding: 1.25rem 5px 0;
          }
        }
      }
    }
    &.tester-prep {
      .ant-tabs-bar {
        margin: 0 !important;
      }
      .ant-tabs-nav .ant-tabs-tab {
        padding: 18px 0px !important;
        margin: 0 30px;
      }
    }
    .checker {
      position: relative;
      margin-bottom: 10px;
      &.active {
        &::before {
          content: "";
          position: absolute;
          background: url("../../img/icons/Active.svg");
          height: 15px;
          width: 15px;
          top: 4px;
          background-size: contain;
          background-repeat: no-repeat;
        }
        span {
          strong {
            color: #000;
          }
        }
      }
      &.completed {
        &::before {
          content: "";
          position: absolute;
          background: url("../../img/icons/Completed.svg");
          height: 15px;
          width: 15px;
          top: 4px;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
      &.pending {
        &::before {
          content: "";
          position: absolute;
          background: url("../../img/icons/Pending.svg");
          height: 15px;
          width: 15px;
          top: 4px;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
      span {
        font-size: 12px;
        margin-left: 39px;
      }
    }
    .custom-toggler {
      margin-bottom: 1rem;
      position: absolute;
      right: 30px;
      top: 90px;
      background: transparent;
      border: 0;
      padding: 0;
      &:focus {
        outline: 0;
        box-shadow: unset;
      }
    }
    .form-group {
      margin-bottom: 0;
    }
    .uncontrollableCollapse {
      border-top: 1px solid #eee;
      .card {
        margin-bottom: 0;
        .overall {
          font-size: 10px;
        }
        .card-body {
          margin-bottom: 0;
        }
        .custom-control {
          margin-bottom: 7px;
          &.unique {
            .custom-control-label {
              &::before {
                border-color: #ffab4f;
                background-color: #fff;
              }
            }
            input:checked ~ .custom-control-label:before {
              border-color: #ffab4f;
              background-color: #ffab4f;
              border: 2px solid #ffab4f;
            }
          }
          input:checked ~ .custom-control-label:before {
            border-color: #c0dcfd;
            background-color: #c0dcfd;
            border: 2px solid #c0dcfd;
          }
          .custom-control-label {
            padding-top: 4px;
            padding-left: 15px;
            font-size: 12px;
            cursor: pointer;
            &::before {
              border-color: #c0dcfd;
              background-color: #fff;
              border: 2px solid #c0dcfd;
            }
          }
        }
      }
      .batcher {
        width: 100%;

        .header {
          font-size: 10px;
          margin-bottom: 7px;
        }
        .h4-1 {
          font-size: 12px;
          margin-bottom: 20px;
          line-height: 20px;
        }
        .p-1 {
          font-size: 12px;
          padding: 0 !important;
        }
      }
    }
  }
  .collaps__able {
    margin-bottom: 20px;
    .header {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 3px;
    }
    .togg {
      cursor: pointer;
      border-bottom: 3px solid transparent;
      &:hover {
        border-bottom: 3px solid #5c6ac4;
        // font-family: montserrat-semibold;
        color: #5c6ac4;
      }
      &.active {
        border-bottom: 3px solid #5c6ac4;
        font-family: montserrat-semibold;
        color: #5c6ac4;
      }
    }
    .toggler__custom {
      background: #e9ebef;
      &.show {
        display: block;
      }
      &.hide {
        display: none;
      }

      .looper-table {
        table {
          width: 100%;
          thead {
            tr {
              th {
                padding: 20px 35px;
                font-size: 12px;
                font-family: montserrat-semibold;
                color: #000;
              }
            }
          }
          tbody {
            background: #fff;
            tr {
              td {
                padding: 25px 35px;
                font-size: 12px;
                .imgBox {
                  width: 40px;
                  height: 40px;
                  overflow: hidden;
                  border-radius: 50%;
                  img {
                    width: 100%;
                  }
                }
                p {
                  margin: 0;
                  color: #000;
                }
                .name {
                  display: inline-block;
                  margin: 10px;
                  color: #000;
                  font-family: montserrat-semibold;
                }
                button {
                  &.white {
                    border: 0;
                    background: #fff;
                    color: #919191;
                    font-size: 12px;
                    border-radius: 0px;
                    padding: 10px 30px;
                    float: right;
                    &:focus {
                      box-shadow: unset;
                    }
                  }
                  &.black {
                    border: 0;
                    background: #000;
                    color: #fff;
                    font-size: 12px;
                    border-radius: 0px;
                    padding: 10px 30px;
                    float: right;
                    &:focus {
                      box-shadow: unset;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .looper {
        border-bottom: 1px solid #d3d3d3;
        padding: 25px 35px;
        p,
        a {
          font-size: 12px;
          margin: 0;
        }
        strong {
          color: #000;
          font-family: montserrat-semibold;
        }
        button {
          &.white {
            border: 0;
            background: #002541;
            color: #ffffff;
            font-size: 12px;
            &:focus {
              box-shadow: unset;
            }
          }
          &.black {
            border: 0;
            background: #000;
            color: #fff;
            font-size: 12px;
            &:focus {
              box-shadow: unset;
            }
          }
        }
        .avatar {
          height: 30px;
          width: 30px;
          overflow: hidden;
          float: left;
          img {
            width: 100%;
            height: auto;
          }
        }
        .keyAvatar {
          margin-top: 5px;
          position: absolute;
          margin-left: 10px;
        }
        .remove {
          display: block;
          margin-left: 40px;
          margin-top: 4px;
          cursor: pointer;
        }
        .reply {
          cursor: pointer;
        }
        .looper {
          border-bottom: 1px solid #d3d3d3;
          padding: 20px 0;
        }
      }
    }
    .HeadCollapse {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 3px;

      .row {
        align-items: center;
      }
      .title {
        background-color: #f9fafb;
        p {
          font-family: montserrat-semibold;
          color: #000;
          font-size: 14px;
          &.checked {
            position: relative;
            &::before {
              content: "";
              background: url("../../img/icons/check.svg");
              background-size: contain;
              background-repeat: no-repeat;
              height: 15px;
              width: 15px;
              position: absolute;
              top: 23px;
              left: -5px;
            }
          }
          &.checkedgrey {
            position: relative;
            &::before {
              content: "";
              background: url("../../img/icons/checkgrey.svg");
              background-size: contain;
              background-repeat: no-repeat;
              height: 15px;
              width: 15px;
              position: absolute;
              top: 23px;
              left: -5px;
            }
          }
        }
      }
      p {
        margin: 0;
        padding: 20px 0 20px 20px;
        font-size: 13px;
      }
      .date-title {
        font-size: 10px;
        margin-bottom: 5px;
        padding: 0;
        color: #000;
      }
      .date {
        font-size: 10px;
        padding: 0;
        color: #000;
      }
    }
    .tabbers {
      .card {
        background: #e9ebef;
        box-shadow: unset;
        .card-body {
          padding-bottom: 0;
        }
        .ant-tabs-nav {
          float: right;
        }
        p,
        a {
          font-size: 12px;
          margin: 0;
        }
        strong {
          color: #000;
          font-family: montserrat-semibold;
        }
        button {
          &.white {
            border: 0;
            background: #fff;
            color: #919191;
            font-size: 12px;
            &:focus {
              box-shadow: unset;
            }
          }
          &.black {
            border: 0;
            background: #000;
            color: #fff;
            font-size: 12px;
            &:focus {
              box-shadow: unset;
            }
          }
        }
        .avatar {
          height: 30px;
          width: 30px;
          overflow: hidden;
          float: left;
          img {
            width: 100%;
            height: auto;
          }
        }
        .keyAvatar {
          margin-top: 5px;
          position: absolute;
          margin-left: 10px;
        }
        .remove {
          display: block;
          margin-left: 40px;
          margin-top: 4px;
          cursor: pointer;
        }
        .reply {
          cursor: pointer;
        }
        .looper {
          border-bottom: 1px solid #d3d3d3;
          padding: 20px 0;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  #productDetailsSection {
    .mobile-show {
      display: block !important;
    }
    .card {
      margin-bottom: 20px;
      box-shadow: 0px 3px 6px #00000029;
      cursor: pointer !important;
      .insider {
        padding: 20px;
        table {
          tr {
            td {
              vertical-align: top;
              .header {
                font-size: 12px;
                margin-bottom: 7px;
              }
              .h4-1 {
                font-size: 13px;
                margin-bottom: 20px;
                line-height: 20px;
              }
              .p-1 {
                font-size: 12px;
                padding: 0 !important;
              }
              .imgBox {
                img {
                  height: 35px;
                  margin-left: -7px;
                  border-radius: 18px;
                  &:first-child {
                    margin-left: 0;
                  }
                }
                span {
                  font-size: 12px;
                  color: #707070;
                  display: inline-block;
                  margin-left: 5px;
                }
              }
              .button-primary {
                width: 100%;
                padding: 10px;
              }
            }
          }
        }
      }
      .checker {
        position: relative;
        margin-bottom: 10px;
        &.active {
          &::before {
            content: "";
            position: absolute;
            background: url("../../img/icons/Active.svg");
            height: 15px;
            width: 15px;
            top: 5px;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
        &.completed {
          &::before {
            content: "";
            position: absolute;
            background: url("../../img/icons/Completed.svg");
            height: 15px;
            width: 15px;
            top: 5px;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
        &.pending {
          &::before {
            content: "";
            position: absolute;
            background: url("../../img/icons/Pending.svg");
            height: 15px;
            width: 15px;
            top: 5px;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
        span {
          font-size: 12px;
          margin-left: 39px;
        }
      }
      .custom-toggler {
        margin-bottom: 1rem;
        position: absolute;
        right: 30px;
        top: 90px;
        background: transparent;
        border: 0;
        padding: 0;
        &:focus {
          outline: 0;
          box-shadow: unset;
        }
      }
      .form-group {
        margin-bottom: 0;
      }
      .uncontrollableCollapse {
        border-top: 1px solid #eee;
        .card {
          margin-bottom: 0;
          .overall {
            font-size: 10px;
          }
          .card-body {
            margin-bottom: 0;
          }
          .custom-control {
            margin-bottom: 7px;
            &.unique {
              .custom-control-label {
                &::before {
                  border-color: #ffab4f;
                  background-color: #fff;
                }
              }
              input:checked ~ .custom-control-label:before {
                border-color: #ffab4f;
                background-color: #ffab4f;
                border: 2px solid #ffab4f;
              }
            }
            input:checked ~ .custom-control-label:before {
              border-color: #c0dcfd;
              background-color: #c0dcfd;
              border: 2px solid #c0dcfd;
            }
            .custom-control-label {
              padding-top: 4px;
              padding-left: 15px;
              font-size: 12px;
              cursor: pointer;
              &::before {
                border-color: #c0dcfd;
                background-color: #fff;
                border: 2px solid #c0dcfd;
              }
            }
          }
        }
        .batcher {
          width: 100%;

          .header {
            font-size: 10px;
            margin-bottom: 7px;
          }
          .h4-1 {
            font-size: 12px;
            margin-bottom: 20px;
            line-height: 20px;
          }
          .p-1 {
            font-size: 12px;
            padding: 0 !important;
          }
        }
      }
    }
    .productDetailsSection {
      .ant-collapse {
        background: transparent;
        border: 0;
        .ant-collapse-item {
          background: #fff;
          margin-bottom: 20px;
          box-shadow: 0px 3px 6px #00000029;
          .ant-collapse-header {
            font-size: 12px;
            font-family: montserrat-bold;
          }
          .ant-collapse-content-box {
            padding: 5px 16px;
            font-size: 12px;
            padding-top: 15px;
            .ant-tabs-nav .ant-tabs-tab {
              margin-right: 10px !important;
              padding: 12px 0px !important;
              font-family: montserrat-semibold !important;
              font-size: 10px;
            }
            p.link {
              font-size: 12px !important;
              margin-bottom: 5px !important;
            }
            a.url {
              font-size: 10px !important;
              color: #3b86ff !important;
            }
            .checker {
              position: relative;
              margin-bottom: 10px;
              &.active {
                &::before {
                  content: "";
                  position: absolute;
                  background: url("../../img/icons/Active.svg");
                  height: 15px;
                  width: 15px;
                  top: 0px;
                  background-size: contain;
                  background-repeat: no-repeat;
                }
                span {
                  strong {
                    color: #000;
                  }
                }
              }
              &.completed {
                &::before {
                  content: "";
                  position: absolute;
                  background: url("../../img/icons/Completed.svg");
                  height: 15px;
                  width: 15px;
                  top: 0px;
                  background-size: contain;
                  background-repeat: no-repeat;
                }
              }
              &.pending {
                &::before {
                  content: "";
                  position: absolute;
                  background: url("../../img/icons/Pending.svg");
                  height: 15px;
                  width: 15px;
                  top: 0px;
                  background-size: contain;
                  background-repeat: no-repeat;
                }
              }
              span {
                font-size: 12px;
                margin-left: 39px;
              }
            }
            .looper-table {
              overflow: scroll;
              table {
                white-space: nowrap;
                overflow: scroll;
                tr {
                  th {
                    padding: 5px 15px 5px 0;
                  }
                  td {
                    padding: 5px 15px 5px 0;
                    .name {
                      display: block !important;
                      margin: 5px 5px 5px 40px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      button {
        &.white {
          background: #000e24;
          color: #fff;
          border: 0;
          border-radius: 3px;
          margin-bottom: 20px;
          font-size: 12px;
        }
      }
    }
  }
}
