.card {
  margin-bottom: 40px;
}
.titleName {
  font-size: 15px;
  font-family: "montserrat-bold";
  margin-bottom: 15px;
  text-transform: capitalize;
}
.showBtn {
  color: #3b86ff;
  font-size: 10px;
  font-family: "montserrat-bold";
  display: inline-block;
  margin: 15px 0 10px;
}

.float-right {
  ul {
    padding-right: 30px;
    margin: 0;
    &.dropdownContainer {
      li {
        margin-left: 37px;
        &::before {
          display: none;
        }
        .dropdown {
          position: relative;
          .dropdown-toggle::after {
            background: url("../../img/icons/small-down.svg");
            height: 11px;
            width: 11px;
            border: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top: 7px;
            margin-left: 7px;
          }
          button {
            &.btn-secondary {
              background: transparent;
              color: #777;
              border: 0;
              font-size: 12px;
              padding: 0;
              margin: 0;
              &:focus {
                border: 0;
                outline: 0;
                box-shadow: unset;
                color: #777;
              }
            }
          }
        }
      }
    }

    li {
      display: inline-block;
      position: relative;

      img {
        margin-left: 10px;
        padding-left: 20px;
        height: 12px;
        cursor: pointer;
      }
      &:last-child {
        &::before {
          display: none;
        }
      }
      &::before {
        content: "";
        height: 19px;
        width: 2px;
        position: absolute;
        background: #d7d7d7;
        right: -15px;
        top: 4px;
      }
    }
  }
}

.toDoList {
  width: 100%;
  .card {
    padding: 10px 0px;
    height: 300px;
    .fix-scroll {
      padding: 0 20px;
      height: 243px;
      overflow-y: auto;
    }
  }
  table {
    margin: 0px;
    position: relative;
    tr {
      position: relative;
      &:hover {
        &::before {
          opacity: 1;
        }
        td,
        th {
          font-size: 12px;
          vertical-align: middle;
          border-top: 1px solid transparent;
          // font-family: montserrat-semibold;
          color: #000;
          &.status {
            color: #3b86ff;
            font-size: 10px;
            font-family: montserrat-bold;
            text-transform: uppercase;
            width: 100px;
            span {
              font-family: montserrat-bold;
            }
          }
        }
      }
      &::before {
        content: "";
        position: absolute;
        border-left: 9px solid #a3a0fb;
        left: -20px;
        height: 48px;
        display: inline-block;
        opacity: 0;
        transition: 0.3s;
      }
      td,
      th {
        font-size: 12px;
        vertical-align: middle;
        border-top: 1px solid transparent;
        font-family: montserrat-medium;
        color: #888888;
        &:nth-child(3) {
          width: 123px;
        }
        &.status {
          color: #a3a3a3;
          font-size: 10px;
          font-family: montserrat-semibold;
          text-transform: uppercase;
          width: 100px;
          cursor: pointer;
          span {
            font-family: montserrat-semibold;
          }
        }
        .badges {
          min-width: 80px;
        }
      }
    }
  }
}

.importantDates {
  width: 100%;
  .card {
    padding: 10px 0px;
    height: 300px;
    .fix-scroll {
      padding: 0 20px;
      height: 243px;
      overflow-y: auto;
    }
  }
  .not-found {
    padding: 18px 30px;
    text-align: center;
    img {
      width: 156px;
      height: 109px;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 5px;
      font-size: 12px;
    }
    button {
      margin-top: 20px;
    }
  }
  .event-success {
    padding: 52px 30px;
    text-align: center;
    img {
      width: 109px;
      height: 109px;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 5px;
      font-size: 12px;
    }
    button {
      margin-top: 20px;
    }
  }
  #calendar {
    max-width: 100%;
  }
  table.reminder-list {
    margin-bottom: 12px;
    table-layout: fixed;
    position: relative;
    tr {
      position: relative;
      &:hover {
        &::before {
          opacity: 1;
        }
        td,
        th {
          font-size: 12px;
          // border-top: 1px solid transparent;
          // font-family: montserrat-semibold;
          &.date {
            font-family: montserrat-bold;
            color: #000;
          }
          &.title {
            color: #000;
            .title-name {
              // font-family: montserrat-semibold;
            }
          }
          &.status {
            color: #3b86ff;
          }
        }
      }
      &::before {
        content: "";
        position: absolute;
        border-left: 9px solid #a3a0fb;
        left: -20px;
        height: 48px;
        margin-top: 15px;
        display: inline-block;
        opacity: 0;
        transition: 0.3s;
      }
      cursor: pointer;
      // &:hover {
      //   background: #f7f7f7;
      // }
      td,
      th {
        border: 0;
        padding: 20px 15px;
        color: #888888;
      }
    }
    .date {
      font-size: 12px;
      font-family: montserrat-bold;
      width: 60px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        height: 40px;
        width: 1px;
        right: 0;
        top: 18px;
        background: #a8a8a8;
      }
    }
    .title {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: auto;
      width: 70%;
      font-family: montserrat-medium;
      // .title-name {
      //   white-space: nowrap;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      // }
    }
    .status {
      font-size: 12px;
      color: #a3a3a3;
      width: 115px;
      font-family: montserrat-semibold;
    }
  }
}
#productsAndServices {
  .not-found {
    padding: 18px 30px;
    text-align: center;
    background: #fff;
    margin-bottom: 40px;
    padding: 40px;
    img {
      width: 156px;
      height: 109px;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 5px;
      font-size: 12px;
    }
    button {
      margin-top: 20px;
    }
  }

  .productsAndServices {
    margin-bottom: 40px;
    width: 100%;
    .stages {
      table {
        tr {
          td {
            p {
              font-size: 12px;
            }
          }
        }
      }
    }
    .card {
      margin-bottom: 20px;
      box-shadow: 0px 3px 6px #00000029;
      .insider {
        padding: 20px;
        table {
          tr {
            td {
              vertical-align: top;
              .header {
                font-size: 12px;
                margin-bottom: 7px;
              }
              .h4-1 {
                font-size: 13px;
                margin-bottom: 20px;
              }
              .p-1 {
                font-size: 12px;
                padding: 0 !important;
              }
              .imgBox {
                img {
                  height: 35px;
                  margin-left: -7px;
                  border-radius: 18px;
                  &:first-child {
                    margin-left: 0;
                  }
                }
                span {
                  font-size: 12px;
                  color: #707070;
                  display: inline-block;
                  margin-left: 5px;
                }
              }
              .button-primary {
                width: 140px;
                padding: 10px;
              }
            }
          }
        }
      }
      .checker {
        position: relative;
        margin-bottom: 10px;
        &.active {
          &::before {
            content: "";
            position: absolute;
            background: url("../../img/icons/Active.svg");
            height: 15px;
            width: 15px;
            top: 5px;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
        &.completed {
          &::before {
            content: "";
            position: absolute;
            background: url("../../img/icons/Completed.svg");
            height: 15px;
            width: 15px;
            top: 5px;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
        &.pending {
          &::before {
            content: "";
            position: absolute;
            background: url("../../img/icons/Pending.svg");
            height: 15px;
            width: 15px;
            top: 5px;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
        span {
          font-size: 12px;
          margin-left: 39px;
        }
      }
      .custom-toggler {
        margin-bottom: 1rem;
        position: absolute;
        right: 30px;
        top: 90px;
        background: transparent;
        border: 0;
        padding: 0;
        &:focus {
          outline: 0;
          box-shadow: unset;
        }
      }
      .form-group {
        margin-bottom: 0;
      }
      .uncontrollableCollapse {
        border-top: 1px solid #eee;
        .card {
          margin-bottom: 0;
          .overall {
            font-size: 10px;
          }
          .card-body {
            margin-bottom: 0;
          }
          .custom-control {
            margin-bottom: 7px;
            &.unique {
              .custom-control-label {
                &::before {
                  border-color: #ffab4f;
                  background-color: #fff;
                }
              }
              input:checked ~ .custom-control-label:before {
                border-color: #ffab4f;
                background-color: #ffab4f;
                border: 2px solid #ffab4f;
              }
            }
            input:checked ~ .custom-control-label:before {
              border-color: #c0dcfd;
              background-color: #c0dcfd;
              border: 2px solid #c0dcfd;
            }
            .custom-control-label {
              padding-top: 4px;
              padding-left: 15px;
              font-size: 12px;
              cursor: pointer;
              &::before {
                border-color: #c0dcfd;
                background-color: #fff;
                border: 2px solid #c0dcfd;
              }
            }
          }
        }
        .batcher {
          width: 100%;

          .header {
            font-size: 10px;
            margin-bottom: 7px;
          }
          .h4-1 {
            font-size: 12px;
            margin-bottom: 20px;
          }
          .p-1 {
            font-size: 12px;
            padding: 0 !important;
          }
        }
      }
    }
  }
}

.MyAssessments {
  width: 100%;
  .not-found {
    padding: 70px 30px;
    text-align: center;
    p {
      margin-bottom: 10px;
      font-size: 12px;
    }
    button {
      margin-top: 20px;
    }
  }
  .card {
    padding: 15px;
    min-height: unset;
    height: 100%;
  }
  .assessMentBox {
    img {
      margin: 20px;
      height: 40px;
    }
    h4 {
      font-size: 12px;
    }
    p {
      font-size: 12px;
      color: #b2b2b2;
    }
  }
}

.MySessions {
  width: 100%;
  .not-found {
    padding: 70px 30px;
    text-align: center;
    img {
      width: 156px;
      height: 109px;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 5px;
      font-size: 12px;
    }
    button {
      margin-top: 20px;
    }
  }

  .sessionCard {
    padding: 15px;
    border-bottom: 1px solid #eaeaea;

    .imgBox {
      overflow: hidden;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      float: left;
      img {
        width: 100%;
      }
    }
    .details {
      padding-left: 60px;
      padding-top: 15px;
      position: relative;
      .go {
        position: absolute;
        right: 8px;
        top: 18px;
        height: 10px;
        cursor: pointer;
      }
      .title {
        font-size: 12px;
        font-family: montserrat-bold;
        margin-bottom: 21px;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          list-style-type: none;
          font-size: 11px;
          margin-bottom: 15px;
          &:last-child {
            margin: 0;
          }
          img {
            margin-right: 10px;
            &.status {
              margin-top: -3px;
            }
            &.calendar {
              margin-top: -35px;
            }
          }

          .sessionDateTime {
            display: inline-block;
          }
        }
      }
    }
  }
  .viewAllBtn {
    background: #fff;
    margin-top: -1px;
    margin-bottom: 15px;
    .showBtn {
      color: #3b86ff;
      font-size: 10px;
      font-family: montserrat-bold;
      display: inline-block;
      margin: 15px 0 10px;
    }
  }
}
.MyTimeline {
  width: 100%;
  .card {
    padding: 15px 0;
  }
  .scroller {
    height: 470px;
    overflow: auto;
  }
  table {
    tr {
      &:last-child {
        td {
          border-bottom: unset;
        }
      }
      td {
        vertical-align: middle;
        border-bottom: 1px solid #f6f6f7;
        border-top: unset;
        .imgBox {
          text-align: center;
          img {
            width: 40px;
          }
          p {
            font-size: 10px;
            color: #525252;
            margin: 5px;
          }
        }
        .title {
          font-size: 12px;
          margin-bottom: 3px;
        }
        .date {
          font-size: 12px;
          color: #b8b8b8;
          margin-bottom: 0;
        }
      }
    }
  }
  .not-found {
    padding: 70px 30px;
    text-align: center;
    img {
      width: 156px;
      height: 109px;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 5px;
      font-size: 12px;
    }
    button {
      margin-top: 20px;
    }
  }
}
.popularAmongStudents {
  width: 100%;
  .imgBox {
    width: 100%;
    img {
      width: inherit;
    }
  }
  .details {
    h4 {
      font-size: 12px;
      font-family: montserrat-semibold;
      margin-top: 10px;
      margin-bottom: 5px;
    }
    p {
      font-size: 10px;
      margin-bottom: 10px;
    }
  }
}
.FeaturedProducts {
  width: 100%;
  .card {
    &:hover {
      .imgBox {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  .imgBox {
    width: 100%;
    overflow: hidden;
    img {
      width: inherit;
      transition: 0.3s;
    }
  }
  .details {
    h4 {
      font-size: 12px;
      font-family: montserrat-semibold;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    p {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
}
/* Mobile Landscape */
@media only screen and (max-device-width: 500px) {
  body #root {
    .productsAndServices .scroller li {
      width: 81% !important;
    }
    .FeaturedProducts .row.mobile-show .overflow ul li,
    .popularAmongStudents .row.mobile-show .overflow ul li,
    .MyAssessments ul li {
      width: auto !important;
    }
    .recommendedUniversities .row.mobile-show .overflow ul li,
    .popularAmongStudents .row.mobile-show .overflow ul li,
    .MyAssessments ul li {
      width: auto !important;
    }
  }
}
/* iPad Landscape */
@media only screen and (max-device-width: 1024px) {
  body {
    // overflow-x: hidden!important;
    #root {
      .titleName {
        font-size: 13px;
      }
      .card {
        margin-bottom: 20px;
      }
      .float-right ul {
        padding-right: 5px;
        margin: 0;
        margin-top: -5px;
      }
      .toDoList {
        .card {
          padding: 10px 0px;

          table {
            tr {
              &::before {
                display: none;
              }
              &:focus,
              &:active {
                background: #003f661a;
              }
              &:first-child {
                td,
                th {
                  color: #000;
                }
                .status {
                  background: #a3a0fb;
                }
              }
              td,
              th {
                width: auto;
                font-size: 12px;

                .badges {
                  font-size: 9px;
                  padding: 2px;
                  min-width: 55px;
                  text-align: center;
                }

                &.status {
                  position: relative;
                  width: 40px;
                  text-align: center;
                }
                &.mobile-hide {
                  display: none !important;
                  text-align: center;
                }
                &.mobile-show {
                  &:active,
                  &:focus {
                    background: #a3a0fb;
                    display: block !important;
                    width: 36px;
                    text-align: center;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    line-height: 40px;
                  }
                }
              }
            }
          }
        }
      }
      .MyTimeline {
        .float-right ul {
          padding-right: 22px;
        }
        table tr td .date {
          font-size: 10px;
        }
      }
      .importantDates {
        .not-found {
          padding: 20px;
          text-align: center;
        }
        .card {
          padding: 10px 0px;
          table {
            tr::before {
              display: none;
            }
            .title {
              font-size: 12px;
              white-space: unset;
            }
            .status {
              width: 37px;
            }
          }
        }
      }
      .FeaturedProducts {
        .row.mobile-show {
          display: block !important;
          .overflow {
            // overflow-x: scroll;
            width: 100vw;
            margin-left: -34px;
            ul {
              padding: 0;
              overflow: scroll;
              -webkit-overflow-scrolling: touch;

              white-space: nowrap;
              li {
                display: inline-block;
                width: 190px;
                margin: 5px 5px 0px 5px;
                overflow: hidden;
                &:first-child {
                  margin-left: 35px;
                }
                &:last-child {
                  margin-right: 35px;
                }
                .card {
                  display: inline-block;
                  margin-bottom: 0px;
                  width: 100%;
                  overflow: hidden;
                  .imgBox {
                    min-height: 100px;
                    overflow: hidden;
                    img {
                      width: auto;
                      height: 100px;
                    }
                  }
                  .details h4 {
                    font-family: montserrat-regular;
                  }
                }
              }
            }
          }
        }
      }
      .recommendedUniversities {
        .row.mobile-show {
          display: block !important;
          .overflow {
            // overflow-x: scroll;
            width: 100vw;
            margin-left: -34px;
            ul {
              padding: 0;
              overflow: scroll;
              -webkit-overflow-scrolling: touch;

              white-space: nowrap;
              li {
                display: inline-block;
                width: 190px;
                margin: 5px 5px 0px 5px;
                overflow: hidden;
                &:first-child {
                  margin-left: 35px;
                }
                &:last-child {
                  margin-right: 35px;
                }
                .card {
                  display: inline-block;
                  margin-bottom: 0px;
                  width: 100%;
                  overflow: hidden;
                  .imgBox {
                    min-height: 100px;
                    overflow: hidden;
                    img {
                      width: auto;
                      height: 100px;
                    }
                  }
                  .details h4 {
                    font-family: montserrat-regular;
                  }
                }
              }
            }
          }
        }
      }

      .popularAmongStudents {
        .row.mobile-show {
          display: block !important;
          .overflow {
            // overflow-x: scroll;
            width: 100vw;
            margin-left: -34px;
            ul {
              padding: 0;
              overflow: scroll;
              -webkit-overflow-scrolling: touch;

              white-space: nowrap;
              li {
                display: inline-block;
                width: 190px;
                margin: 5px 5px 0px 5px;
                overflow: hidden;
                &:first-child {
                  margin-left: 35px;
                }
                &:last-child {
                  margin-right: 35px;
                }
                .card {
                  display: inline-block;
                  margin-bottom: 0px;
                  width: 100%;
                  overflow: hidden;
                  .imgBox {
                    min-height: 100px;
                    overflow: hidden;
                    img {
                      width: auto;
                      height: 100px;
                    }
                  }
                  .details h4 {
                    font-family: montserrat-regular;
                  }
                }
              }
            }
          }
        }
      }
      .MyAssessments {
        ul {
          padding: 0;
          overflow: scroll;
          -webkit-overflow-scrolling: touch;

          white-space: nowrap;
          width: 100vw;
          margin-left: -34px;
          padding-bottom: 10px;
          li {
            display: inline-block;
            background: #fff;
            margin: 5px;
            padding: 10px;
            width: 190px;
            .assessMentBox p {
              font-size: 10px;
            }
            &:first-child {
              margin-left: 35px;
            }
            &:last-child {
              margin-right: 35px;
            }
            .assessMentBox h4 {
              font-size: 10px;
            }
          }
        }
      }
      .productsAndServices {
        margin-bottom: 0;
        .scroller {
          overflow: scroll;
          -webkit-overflow-scrolling: touch;
          white-space: nowrap;
          width: 100vw;
          margin-left: -34px;
          padding: 0;
          margin-bottom: 0;
          li {
            display: inline-block;
            margin: 10px;
            width: 40%;
            &:first-child {
              margin-left: 35px;
            }
            &:last-child {
              margin-right: 35px;
            }
            .card {
              .insider table {
                width: 100%;
                table-layout: fixed;
                .h4-1 {
                  white-space: normal;
                  line-height: 20px;
                }
                tr td .button-primary {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
a.todo_links {
  color: grey;
  font-family: montserrat-semibold;
}

a.todo_links:hover {
  color: #3b86ff;
}

@media (max-width: 500px) {
  .importantDates table.reminder-list tr td,
  .importantDates table.reminder-list tr th {
    padding: 11px 15px;
  }
  .MyTimeline {
    .scroller {
      height: auto !important;
    }
  }
}
@media (min-width: 600px) {
  .MySessions .card {
    // min-height: 33.7rem;
  }
}
