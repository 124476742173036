.verifyMobileModal {
  width: 600px;
  max-width: 90vw;
  .close {
    right: 30px;
    position: absolute;
    top: 25px;
    cursor: pointer;
  }
}
#dobSection {
  padding: 35px 150px;
  h1 {
    font-size: 15px;
    font-family: montserrat-bold;
    margin-bottom: 50px;
  }
  p {
    font-size: 12px;
    color: #363636;
    margin-bottom: 40px;
    strong {
      font-size: 12px;
      color: #000;
    }
  }

  .container-fluid {
    padding: 0;
    .row {
      .col-4 {
        padding: 7px;
        label {
          display: block;
          text-align: left;
          font-size: 12px;
          margin-bottom: 5px;
        }
        input {
          text-align: center;
          background: whitesmoke;
          border: 1px solid #e9e9e9;
          font-size: 13px;
          font-family: montserrat-semibold;
          color: #000;
          /* padding: 13px; */
          height: 45px;
          &::placeholder {
            color: #bfbcbc;
          }
        }
      }
    }
  }

  button {
    margin: 7px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    letter-spacing: 1px;
    height: 45px;
  }
  .form-group {
    label {
      text-align: left;
      display: block;
      font-size: 11px;
      color: #000;
      margin-bottom: 5px;
    }

    input {
      background: #f7f7f7;
      border: 1px solid #ebebeb;
      font-size: 13px;
      height: 40px;
    }
  }
}

@media (max-width: 500px) {
  #dobSection {
    padding: 35px 15px;
  }
}
