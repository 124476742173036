/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300;400;500;600;700;800&display=swap");

@import "./fonts"; // font file import
@import "./variable"; // variable file import
@import "./buttons"; // buttons file import@import "./animate"; // animate file import
@import "./_style/sidebar"; // sidebar file import
@import "./_style/topbar"; // topbar file import
@import "./_style/backdrop"; // backdrop file import
@import "./_style/dashboard"; // dashboard file import
@import "./_style/my-sessions"; // my-sessions file import
@import "./_style/book-session"; // book-sessions file import
@import "./_style/reminder-session"; // book-sessions file import
@import "./_style/my-sessions-success"; // book-sessions file import
@import "./_style/my-products-and-services"; // book-sessions file import
@import "./_style/products-details"; // book-sessions file import
@import "./_style/verify-mobile"; // book-sessions file import
@import "./_style/otp-verification"; // book-sessions file import
@import "./_style/linkedin-url"; // book-sessions file import
@import "./_style/dob"; // book-sessions file import
@import "./_style/file-upload"; // file-upload file import
@import "./_style/productsBookSession"; // book-productsBookSession file import
@import "./_style/my-profile.scss"; // book-profile file import
@import "./_style/my-bookmark.scss"; // book-bookmark file import
@import "./_style/send-money.scss"; // send-money file import
@import "./_style/need-help"; // need-help file import
@import "./_style/amountHeader-section"; // amountHeader-section file import
@import "./_style/amount-conversion-form";
@import "./_style/transaction-summary";
@import "./_style/profile-personal-details";
@import "./_style/user-recipients";
@import "./_style/timer-modal";
@import "./_style/user-doc-upload";
@import "./_style/user-upload-documents";
@import "./_style/upload-payment-reciept";
@import "./_style/latest-transaction";

* {
  // font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-family: "Open Sans" !important;
  letter-spacing: 1px;
}

html {
  width: 100%;
  height: 100%;
  body {
    font-family: "Open Sans", sans-serif;
    // font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
}
.form-control:focus {
  box-shadow: unset;
  border-color: #80bdff !important;
}
.primary-button:hover {
  color: #000 !important;
}
body h1,
body h2,
body h4,
body h5,
body h6,
body p,
body a,
body ul li,
body ol li,
body span,
body,
div {
  font-family: "Open Sans", sans-serif;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}
.ant-tooltip-inner {
  border-radius: 16px !important;
  padding: 15px !important;
  border: 1px solid #000000;
}
.ant-tooltip-arrow-content {
  border: 1px solid #fff !important;
  width: 13.313709px !important;
  height: 14.3213px !important;
}
.ant-tooltip-inner {
  border: 1px solid #fff !important;
  margin-left: -1px !important;
}
.inner.ant-input {
  background: none !important;
  color: #fff;
}
.ant-input {
  background: none !important;
  color: #fff !important;
}
.ant-spin-container.ant-spin-blur {
  &::after {
    background: none !important;
    transition: none !important;
  }
}
.ant-dropdown.ant-dropdown-placement-left {
  top: 365px !important;
  left: 1056px !important;
  max-height: 200px;
  overflow: auto;
  overflow-x: hidden;
  width: 200px;
  z-index: 99;
  background: #000;
  padding-bottom: 60px;
}
.ant-select-selector {
  background: none !important;
  color: #fff !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 0px !important;
}
.ant-select-clear {
  top: 46% !important;
  color: red !important;
  background: transparent !important;
}
.input-encaps {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1.5px solid #e1e5e8;
  border-radius: 8px;
  height: 50px;
  align-items: center;
  .data-input {
    flex: 1;
    border-right: 1.5px solid #e1e5e8;
    .form-control {
      border: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      display: flex;
      align-items: center;

      color: #284157;
    }
  }
  .type-select {
    width: 150px;
    .dropdown {
      .btn-secondary {
        background: #fff;
        color: #000;
        border: 0;
        box-shadow: unset;
        outline: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        display: flex;
        align-items: center;

        color: #284157;
        .flag {
          height: 18px;
          margin-right: 10px;
        }
      }
      .dropdown-toggle {
        background: #fff;
        color: #000;
        border: 0;
        box-shadow: unset;
        outline: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        display: flex;
        align-items: center;

        color: #284157;
        &::after {
          position: absolute;
          right: 10px;
          top: 20px;
          background: url("https://leverageedunew.s3.us-east-1.amazonaws.com/assets/img/leverage-finance/arrow-down.svg");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          height: 10px;
          width: 10px;
          border: 0;
        }
        > div {
          display: flex;
          align-items: center;
        }
        .flag {
          height: 18px;
          margin-right: 10px;
        }
      }
      .dropdown-menu {
        width: auto;
        .header {
          display: flex;
          align-items: center;
          outline: 0;
          border-bottom: 1.5px solid #e1e5e8;
          .search-icon {
            margin-right: 10px;
            height: 17px;
          }
          .search-input {
            flex: 1 1;
            border: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            color: #667a8b;
            outline: 0;
            box-shadow: unset;
            height: unset !important;
          }
        }
        .dropdown-item {
          display: flex;
          align-items: center;
          .flag {
            margin-right: 10px;
            height: 18px;
          }
          .currency-code {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #284157;
            margin-right: 10px;
            min-width: 40px;
          }
          .currency {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            color: #667a8b;
          }
          &.active {
            .currency-code {
              color: #fff;
            }
            .currency {
              color: #eaeaea;
            }
          }
        }
      }
    }
  }
}

.otp-input .MuiInput-underline:before {
  border-bottom: none !important;
}
.input-encaps1 {
  display: flex;
  justify-content: space-between;
  background: transparent;
  border-bottom: 1px solid #fff;
  height: 50px;
  align-items: center;
  width: 100%;
  .data-input {
    flex: 1;
    // border-right: 1.5px solid #e1e5e8;
    .form-control {
      border: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      background: transparent;
      display: flex;
      align-items: center;
      color: #fff;
    }
  }
  .type-select {
    width: 100px;
    .dropdown {
      .btn-secondary {
        background: #fff;
        color: #fff;
        border: 0;
        box-shadow: unset;
        outline: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        display: flex;
        align-items: center;

        .flag {
          height: 18px;
          margin-right: 10px;
        }
      }
      .dropdown-toggle {
        color: #fff;
        border: 0;
        box-shadow: unset;
        outline: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        display: flex;
        align-items: center;
        &::after {
          position: absolute;
          right: 10px;
          top: 20px;
          background: url("https://leverageedunew.s3.us-east-1.amazonaws.com/assets/new-images/dropdown.png");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          height: 10px;
          width: 10px;
          border: 0;
        }
        > div {
          display: flex;
          align-items: center;
        }
        .flag {
          height: 18px;
          margin-right: 10px;
        }
      }
      .dropdown-menu {
        width: auto;
        .header {
          display: flex;
          align-items: center;
          outline: 0;
          border-bottom: 1.5px solid #e1e5e8;
          .search-icon {
            margin-right: 10px;
            height: 17px;
          }
          .search-input {
            flex: 1 1;
            border: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            color: #667a8b;
            outline: 0;
            box-shadow: unset;
            height: unset !important;
          }
        }
        .dropdown-item {
          display: flex;
          align-items: center;
          .flag {
            margin-right: 10px;
            height: 18px;
          }
          .currency-code {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #284157;
            margin-right: 10px;
            min-width: 40px;
          }
          .currency {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            color: #667a8b;
          }
          &.active {
            .currency-code {
              color: #fff;
            }
            .currency {
              color: #eaeaea;
            }
          }
        }
      }
    }
  }
}
.conversions {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.ant-form-item {
  margin-bottom: 0 !important;
}
.ant-select-arrow {
  color: #fff !important;
  right: 2px !important;
}
.ant-select-clear {
  right: 20px !important;
}
main {
  // background: green !important;
  // background-image: url("https://leverageedunew.s3.amazonaws.com/flybank/background.png");
  // height: calc(100vh - 300px);
  // background-image: url("https://leverageedunew.s3.amazonaws.com/assets/new-images/Ellipse-top.png");
  &.Wrapper {
    background-color: #0a0a0a;
    // margin-left: 60px;
    // padding: 100px 30px 50px;
    padding: 80px 0;
    // min-height: calc(100vh - 73px);
    min-height: 100vh;
    // position: fixed;
    // position: fixed;
    // width: 100%;
    // min-height: 100vh;
  }
  #navContainer {
    &.open {
      left: 0 !important;
    }
    #sidebar {
      height: 100vh;
      background: #000000;
      position: fixed;
      top: 0;
      left: 0;
      .Logo {
        img {
          height: 40px;
          margin: 15px;
        }
      }
      ul {
        padding: 0;
        a {
          &.active {
            li {
              // border-left: 4px solid #9296eb;
              background: #3d3d3d !important;
            }
          }
          li {
            color: #ffffff;
            font-size: 12px;
            list-style-type: none;
            transition: 0.3s;
            border-left: 4px solid transparent;
            padding: 15px 5px 15px 20px;
            &:active {
              background: #3d3d3d !important;
            }
            &:hover {
              border-left: 4px solid #9296eb;
              background: #3c415e;
            }
            img {
              margin-top: -2px;
              margin-right: 8px;
              width: 26px;
              filter: grayscale(100%);
            }
          }
        }
      }
    }
  }
  .continueBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    margin: 16px 0;
    width: 195px;
    height: 48px;
    color: #fff;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid #344380;
    background: #344380;
    border-radius: 12px;
    cursor: pointer;
    &:hover {
      border: 1px solid #6181ff;
      background: #6181ff;
    }

    @media (max-width: 767px) {
      margin: 12px 0 !important;
      width: 100% !important;
    }
  }
  .continuebtn-container {
    width: 100%;
    position: fixed;
    bottom: 0;
    padding-right: 30px;
    right: 0px;
    // right: 30px;
    background-color: #000;
    cursor: pointer;
    // margin-top: 2%;
    border-top: 1px solid #7a7a7a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 9999 !important;

    @media (max-width: 767px) {
      padding-right: 24px;
      padding-left: 24px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .learn {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
    width: 100%;
  }
  .continueCheckbox {
    position: relative;
    left: 112px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;

    @media (max-width: 576px) {
      left: 0px;
      top: 8px;
    }
  }
  .moneyHop {
    display: block;
    // left: 116px;
    // top: 29px;
    position: absolute;
    position: sticky;
    left: 63px;
  }
  .mobile-hide {
    display: block;

    @media (max-width: 767px) {
      display: none;
    }
  }
  .mobile-show {
    display: none;
    @media (max-width: 767px) {
      display: block;
    }
  }
}
.Wrapper-shadow {
  background-image: url("https://leverageedunew.s3.amazonaws.com/assets/new-images/Ellipse-top.png");
}
.card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #3f3f4426;
  border-radius: 3px;
  opacity: 1;
  border: 0;
  margin-bottom: 30px;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: white;
}

// WIDTHS AND HEIGHTS
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
.w-10 {
  width: 10% !important;
}
.w-90 {
  width: 90% !important;
}
.w-70 {
  width: 70% !important;
}
.transform-180 {
  transform: rotateX(180deg);
}

.font-12 {
  font-size: 12px;
}

.font-10 {
  font-size: 10px;
}

.font-6 {
  font-size: 6px !important;
}

.font-8 {
  font-size: 8px !important;
}

// Start of material Scheduler Design Changer

body {
  .e-schedule .e-vertical-view .e-clone-time-indicator,
  .e-schedule .e-vertical-view .e-current-time {
    color: #ffffff;
    background: #5c6ac4;
    padding: 2px;
  }
  .e-schedule .e-vertical-view .e-header-cells.e-current-day {
    color: #5563c0;
  }
  .e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
  .e-schedule .e-schedule-toolbar .e-active-view .e-icons {
    color: #5563c0;
  }
  // .e-appointment
  .e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
    background: #c2c9f6;
    border: 1px solid #e8eaf6;
    color: #000;
  }
  .e-float-input:not(.e-input-group) .e-float-line::before,
  .e-float-input:not(.e-input-group) .e-float-line::after,
  .e-float-input:not(.e-input-group) .e-float-line::before,
  .e-float-input:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
  .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after {
    background: #5563c0;
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
      .e-float-input
    )::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
      .e-float-input
    )::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
    .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
    .e-input-in-wrap::after {
    background: #a3a0fb;
  }
  .e-btn.e-flat.e-primary:hover,
  .e-css.e-btn.e-flat.e-primary:hover {
    background-color: rgba(65, 157, 216, 0.04) !important;
    border-color: transparent;
    color: #a3a0fb !important;
  }
  .e-btn.e-flat.e-primary,
  .e-css.e-btn.e-flat.e-primary {
    background-color: transparent !important;
    border-color: transparent;
    color: #5c6ac4 !important;
  }
  .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
  .e-float-input:not(.e-error)
    input[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error)
    input[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-bigger
    .e-float-input:not(.e-error)
    input[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error)
    input[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error)
    input[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-bigger
    .e-float-input.e-control-wrapper:not(.e-error)
    input[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error)
    input[readonly]:focus
    ~ label.e-float-text,
  .e-small
    .e-float-input:not(.e-error)
    input[readonly]:focus
    ~ label.e-float-text.e-label-top,
  .e-float-input.e-small.e-bigger:not(.e-error)
    input[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-bigger
    .e-float-input.e-small:not(.e-error)
    input[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error)
    input[readonly]:focus
    ~ label.e-float-text,
  .e-small
    .e-float-input.e-control-wrapper:not(.e-error)
    input[readonly]:focus
    ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error)
    input[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-bigger
    .e-float-input.e-control-wrapper.e-small:not(.e-error)
    input[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-float-input:not(.e-error).e-input-focus
    input[readonly]
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error).e-input-focus
    input[readonly]
    ~ label.e-label-top.e-float-text,
  .e-bigger
    .e-float-input:not(.e-error).e-input-focus
    input[readonly]
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error).e-input-focus
    input[readonly]
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus
    input[readonly]
    ~ label.e-label-top.e-float-text,
  .e-bigger
    .e-float-input.e-control-wrapper:not(.e-error).e-input-focus
    input[readonly]
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error).e-input-focus
    input[readonly]
    ~ label.e-float-text,
  .e-small
    .e-float-input:not(.e-error).e-input-focus
    input[readonly]
    ~ label.e-float-text.e-label-top,
  .e-float-input.e-small.e-bigger:not(.e-error).e-input-focus
    input[readonly]
    ~ label.e-label-top.e-float-text,
  .e-bigger
    .e-float-input.e-small:not(.e-error).e-input-focus
    input[readonly]
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus
    input[readonly]
    ~ label.e-float-text,
  .e-small
    .e-float-input.e-control-wrapper:not(.e-error).e-input-focus
    input[readonly]
    ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus
    input[readonly]
    ~ label.e-label-top.e-float-text,
  .e-bigger
    .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus
    input[readonly]
    ~ label.e-label-top.e-float-text,
  .e-float-input:not(.e-error)
    textarea[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error)
    textarea[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-bigger
    .e-float-input:not(.e-error)
    textarea[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error)
    textarea[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error)
    textarea[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-bigger
    .e-float-input.e-control-wrapper:not(.e-error)
    textarea[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error)
    textarea[readonly]:focus
    ~ label.e-float-text,
  .e-small
    .e-float-input:not(.e-error)
    textarea[readonly]:focus
    ~ label.e-float-text.e-label-top,
  .e-float-input.e-small.e-bigger:not(.e-error)
    textarea[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-bigger
    .e-float-input.e-small:not(.e-error)
    textarea[readonly]:focus
    ~ label.e-label-top.e-float-text
    .e-float-input.e-control-wrapper.e-small:not(.e-error)
    textarea[readonly]:focus
    ~ label.e-float-text,
  .e-small
    .e-float-input.e-control-wrapper:not(.e-error)
    textarea[readonly]:focus
    ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error)
    textarea[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-bigger
    .e-float-input.e-control-wrapper.e-small:not(.e-error)
    textarea[readonly]:focus
    ~ label.e-label-top.e-float-text,
  .e-float-input:not(.e-error).e-input-focus
    textarea[readonly]
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-bigger:not(.e-error).e-input-focus
    textarea[readonly]
    ~ label.e-label-top.e-float-text,
  .e-bigger
    .e-float-input:not(.e-error).e-input-focus
    textarea[readonly]
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error).e-input-focus
    textarea[readonly]
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus
    textarea[readonly]
    ~ label.e-label-top.e-float-text,
  .e-bigger
    .e-float-input.e-control-wrapper:not(.e-error).e-input-focus
    textarea[readonly]
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-small:not(.e-error).e-input-focus
    textarea[readonly]
    ~ label.e-float-text,
  .e-small
    .e-float-input:not(.e-error).e-input-focus
    textarea[readonly]
    ~ label.e-float-text.e-label-top,
  .e-float-input.e-small.e-bigger:not(.e-error).e-input-focus
    textarea[readonly]
    ~ label.e-label-top.e-float-text,
  .e-bigger
    .e-float-input.e-small:not(.e-error).e-input-focus
    textarea[readonly]
    ~ label.e-label-top.e-float-text
    .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus
    textarea[readonly]
    ~ label.e-float-text,
  .e-small
    .e-float-input.e-control-wrapper:not(.e-error).e-input-focus
    textarea[readonly]
    ~ label.e-float-text.e-label-top,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus
    textarea[readonly]
    ~ label.e-label-top.e-float-text,
  .e-bigger
    .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus
    textarea[readonly]
    ~ label.e-label-top.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error)
    input:focus
    ~ label.e-float-text,
  .e-bigger
    .e-float-input.e-small:not(.e-error)
    input:focus
    ~ label.e-float-text,
  .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-bigger .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-small .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error)
    textarea:focus
    ~ label.e-float-text,
  .e-bigger
    .e-float-input.e-small:not(.e-error)
    textarea:focus
    ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error)
    input:focus
    ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error)
    input:focus
    ~ label.e-float-text,
  .e-bigger
    .e-float-input.e-control-wrapper:not(.e-error)
    input:focus
    ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error)
    input:focus
    ~ label.e-float-text,
  .e-small
    .e-float-input.e-control-wrapper:not(.e-error)
    input:focus
    ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error)
    input:focus
    ~ label.e-float-text,
  .e-bigger
    .e-float-input.e-control-wrapper.e-small:not(.e-error)
    input:focus
    ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error)
    textarea:focus
    ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error)
    textarea:focus
    ~ label.e-float-text,
  .e-bigger
    .e-float-input.e-control-wrapper:not(.e-error)
    textarea:focus
    ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error)
    textarea:focus
    ~ label.e-float-text,
  .e-small
    .e-float-input.e-control-wrapper:not(.e-error)
    textarea:focus
    ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error)
    textarea:focus
    ~ label.e-float-text,
  .e-bigger
    .e-float-input.e-control-wrapper.e-small:not(.e-error)
    textarea:focus
    ~ label.e-float-text,
  .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-float-input.e-bigger:not(.e-error).e-input-focus
    input
    ~ label.e-float-text,
  .e-bigger
    .e-float-input:not(.e-error).e-input-focus
    input
    ~ label.e-float-text,
  .e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
  .e-small
    .e-float-input:not(.e-error).e-input-focus
    input
    ~ label.e-float-text,
  .e-float-input.e-small.e-bigger:not(.e-error).e-input-focus
    input
    ~ label.e-float-text,
  .e-bigger
    .e-float-input.e-small:not(.e-error).e-input-focus
    input
    ~ label.e-float-text,
  .e-float-input.e-control-wrapper:not(.e-error).e-input-focus
    input
    ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus
    input
    ~ label.e-float-text,
  .e-bigger
    .e-float-input.e-control-wrapper:not(.e-error).e-input-focus
    input
    ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus
    input
    ~ label.e-float-text,
  .e-small
    .e-float-input.e-control-wrapper:not(.e-error).e-input-focus
    input
    ~ label.e-float-text,
  .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus
    input
    ~ label.e-float-text,
  .e-bigger
    .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus
    input
    ~ label.e-float-text {
    color: #5c6ac4;
  }
  .e-checkbox-wrapper:hover .e-frame.e-check,
  .e-css.e-checkbox-wrapper:hover .e-frame.e-check {
    background-color: #5c6ac4;
    border-color: transparent;
    color: #fff;
  }
  .e-checkbox-wrapper .e-frame.e-check,
  .e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: #5c6ac4;
    border-color: transparent;
    color: #fff;
  }
  .e-checkbox-wrapper .e-frame.e-check,
  .e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: #5c6ac4;
    border-color: transparent;
    color: #fff;
  }
  .e-schedule .e-schedule-toolbar .e-tbar-btn:hover,
  .e-schedule .e-schedule-toolbar .e-tbar-btn:focus {
    background-color: #c2c9f6;
    box-shadow: unset;
  }
  .e-quick-popup-wrapper .e-event-popup .e-popup-header {
    background-color: #8d9cff;
  }
  // End of material Scheduler Design Changer

  .e-calendar .e-content td.e-selected span.e-day,
  .e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
    background-color: #a3a0fb !important;
  }
  .e-calendar .e-content td.e-today span.e-day,
  .e-calendar .e-content td.e-focused-date.e-today span.e-day,
  .e-bigger.e-small .e-calendar .e-content td.e-today span.e-day,
  .e-bigger.e-small
    .e-calendar
    .e-content
    td.e-focused-date.e-today
    span.e-day {
    background: none;
    border: 1px solid #a3a0fb !important;
    border-radius: 50%;
    color: #a3a0fb !important;
  }
  .e-calendar .e-content td.e-today:hover span.e-day,
  .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
  .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
  .e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day,
  .e-bigger.e-small
    .e-calendar
    .e-content
    td.e-focused-date.e-today:hover
    span.e-day,
  .e-bigger.e-small
    .e-calendar
    .e-content
    td.e-focused-date.e-today:focus
    span.e-day {
    background-color: #eee;
    border: 1px solid #a3a0fb !important;
    color: #a3a0fb !important;
  }
  .e-calendar .e-content td.e-today.e-selected span.e-day,
  .e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day {
    background-color: #5c6ac4;
    border: 1px solid #5c6ac4;
    box-shadow: inset 0 0 0 2px #fff;
    color: #fff !important;
  }
  .e-schedule .e-vertical-view .e-current-timeline {
    border-top: 1px solid #5c6ac4;
  }
  .e-popup.e-popup-open.e-dialog {
    height: auto !important;
  }

  .documentAppIcons {
    font-size: 18px;
    margin-right: 0.5rem;
    color: black;
  }

  @media (min-width: 576px) {
    .modal-dialog.confirmModal {
      min-width: 600px;
    }
    .totalPaid {
      p {
        margin-left: 30%;
      }
    }
  }

  .ant-tabs-nav {
    .ant-tabs-tab {
      margin-right: 0 !important;
      padding: 12px 30px !important;
      font-family: montserrat-semibold !important;
      font-size: 13px;
      &:hover {
        color: #5c6ac4;
      }
    }
    .ant-tabs-tab-active {
      color: #5c6ac4;
    }
    .ant-tabs-ink-bar {
      background-color: #5c6ac4 !important;
    }
  }
  .ant-tabs-bar {
    border-bottom: 1px solid #dcdcdc;
    margin: 0 0 35px 0;
  }

  .iti {
    width: 100%;
    &.iti--allow-dropdown .iti__flag-container .iti__selected-flag {
      background-color: rgb(245, 245, 245);
      &:focus {
        outline: 0;
        border: 0;
      }
    }
    input {
      height: 40px;
      width: 100%;
      background: #f5f5f5;
      border: 1px solid #e1e1e1;
      border-radius: 5px;
      &:focus {
        outline: 0;
      }
    }
  }

  // .navLogo{
  //   position: absolute;
  //   left: 46%;
  //   cursor: pointer;
  // }

  // Custom notification style
  .notification-container-top-center {
    // width: 370px;
    width: 440px;
    max-width: 50vw;
    top: 85px;
    z-index: 99999999999999;
  }
  .notification-parent {
    .notification-item {
      border-left: 0;
      &.notification-success,
      &.notification-success .timer {
        background-color: #5c6ac4;
      }
      &.notification-warning,
      &.notification-warning .timer {
        background-color: #ffa177;
      }
      &.notification-danger,
      &.notification-danger .timer {
        background-color: #fa6980;
      }

      .notification-content {
        padding: 15px 25px;
        .notification-close {
          background-color: transparent;
          top: 15px;
          &:after {
            font-size: 22px;
          }
        }
        .notification-title {
          display: none;
        }
        .notification-message {
          font-size: 12px;
          // padding: 10px;
        }
      }
    }
  }
}
.ant-notification-notice {
  width: 100% !important;
}
.anticon.ant-notification-notice-icon-warning {
  color: #fff !important;
  top: 27% !important;
}
.ant-notification-notice-with-icon .ant-notification-notice-message{
  color: #fff !important;
  font-weight: 600 !important;
  margin-bottom: 0px !important;
}

.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
  font-size: 10px;
  color: #ff4c4e;
}
.cursor_pointer {
  cursor: pointer;
}

.autocomplete-root {
  position: relative;
}

.autocomplete-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;
  position: absolute;
  z-index: 99;
}

.suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.suggestion-item--active {
  background-color: #fafafa;
}

.leadModal {
  padding: 60px 50px;
  .close {
    z-index: 9;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
  .image {
    height: 100px;
  }
  h2 {
    padding: 20px 5px;
    font-size: 17px;
    text-align: center;
    font-family: "Montserrat-semibold";
  }
  input {
    font-size: 14px;
    height: 45px;
  }
  button {
    height: 50px;
    border-radius: 5px;
    font-size: 15px;
  }
}

button.leadbtn {
  width: 100%;
}

// Loading

.loading-overlay {
  z-index: 1050;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(#002541, 0.2);
  border-radius: 50%;
  border-top-color: #002541;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1;
}
.univ_name {
  min-height: 1.9rem;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .leadModal {
    padding: 40px 20px;
  }
}

.max-width-14 {
  max-width: 14px;
}

// CUSTOM LIBRARIES
#Personal {
  .form-group {
    .ant-picker {
      height: 40px;
      width: 100%;
      border-radius: 0.25rem;
    }
    .ant-picker-input {
      input {
        border: none;
      }
    }
  }
}
.actions_edit_delete {
  min-width: 10rem;
}
body {
  --table-width: 100%; /* Or any value, this will change dinamically */
}
.table-scrollable {
  tbody {
    display: block;
    max-height: 20rem;
    overflow-y: auto;
  }
  thead,
  tbody tr {
    display: table;
    width: var(--table-width);
    table-layout: fixed;
  }
  .actions_edit_delete {
    width: 10rem;
  }
}

.documentUpload {
  .ant-upload.ant-upload-select-picture-card {
    width: 100% !important;
    margin-bottom: 0px;
    height: 36px !important;
    overflow: hidden;
    font-size: 12px;
  }
  .ant-upload-list-picture-card-container {
    width: 100% !important;
    height: 60px !important;
  }
  .ant-upload-list-item
    .ant-upload-list-item-uploading
    .ant-upload-list-item-list-type-picture-card {
    width: 100% !important;
    height: 60px !important;
  }
  span.ant-upload {
    padding: 0px !important;
  }
}

.hideUploadSelect {
  .ant-upload.ant-upload-select-picture-card {
    height: 60px !important;
    width: 100% !important;
    margin-bottom: 0px;
    display: none;
  }
  .ant-upload-list-picture-card-container {
    width: 100% !important;
    height: 60px !important;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 100% !important;
    height: 60px !important;
  }
}

.productsBookSessionModal {
  .modal-header {
    border: none !important;
  }
}
.previewModal {
  width: 800px !important;
  max-width: 800px !important;
  height: 100% !important;
  .modal-content {
    height: auto;
    position: absolute;
    top: 0;
  }
}

.confirmModal {
  width: 600px !important;
  .modal-header {
    border: none !important;
  }
  h5 {
    font-size: 16px;
  }
  .modal-footer {
    button.successButton {
      background-color: #000;
      border: 0px;
    }
    button.successButton:hover {
      background-color: #000000ad;
      border: 0px;
    }
  }
}

// DOCUMENT
.upload_doc {
  span.button {
    border: 1px solid #e1e1e1;
    color: #d0d1d2;
    font-size: 11px;
    font-weight: 600;
    padding: 13px;
    display: inline-block;
    border-radius: 3px;
    width: 160px;
    text-align: center;
    background-color: none;
    transition: all 0.3s;
    margin-right: 10px;
    cursor: pointer;
  }
  span.remove:hover {
    background-color: #e31a1a;
    color: #ffffff;
    text-decoration: none;
  }
  span.preview:hover {
    background-color: #7ab8fc;
    color: #ffffff;
    text-decoration: none;
  }
}

.documentToolTip {
  width: 180px !important;
  text-align: left !important;
  p {
    font-size: 10px;
  }
}

.documentsTab {
  .row {
    padding: 12px;
    // .col-lg-8 {
    //   flex: 0 0 66.666667%;
    //   max-width: 66.666667%;
    // }
    // .col-lg-4 {
    //   flex: 0 0 33.333333%;
    //   max-width: 33.333333%;
    // }
  }
}

.upload_doc {
  .ant-input {
    height: 36px;
  }
}

@media (max-width: 768px) {
  #UserDocumentUploadSection .uploadDetails .upload-doc {
    width: 100% !important;
  }
  .confirmModal,
  .previewModal {
    width: 96% !important;
    margin: 0px auto;
  }
  .previewModal {
    .modal-content {
      position: absolute;
      top: 0;
    }
  }
  .documentsTab {
    .row {
      padding: 12px;
      .ml-4 {
        margin-left: 0.5rem !important;
      }
      .mr-4 {
        margin-right: 0.5rem !important;
      }
      .ant-upload-list-item-thumbnail {
        font-size: 8px;
      }
    }
  }
  .application_progress {
    display: none;
  }
  .application_progress_mobile {
    display: block;
  }
  .applicationButtons {
    margin-bottom: 1rem;
    padding-right: 0px;
    span.w-30 {
      padding: 12px 4px !important;
      width: 100% !important;
    }
    a {
      width: 100% !important;
    }
  }
  .saveBtns {
    button {
      width: 100% !important;
      margin-right: 0px !important;
      margin-left: 0px;
    }
  }
  .upload_doc {
    .ant-input {
      font-size: 12px;
      height: 36px;
    }
    .pl-4 {
      padding-left: 0.5rem !important;
    }
    .ant-select {
      font-size: 8px !important;
    }
  }
  .app_progress {
    width: 100% !important;
    margin-top: 8px;
    padding-right: 15px;
    // padding-left: 15px;
    .progress {
      width: 100% !important;
    }
    .progress-status {
      width: 100% !important;
    }
  }
  .upload_doc {
    .doc_choice {
      .btn {
        font-size: 6px !important;
        padding: 4px 6px !important;
      }
      .btn.view_details {
        width: 80px !important;
      }
    }
    .doc_choice.upDown {
      display: inline-grid !important;
      .btn {
        border-radius: 0px !important;
      }
    }
    div span.icon_delete {
      img {
        width: 16px;
      }
    }
  }
  .mobile_block {
    display: none;
  }
  .mobile_show {
    display: block;
  }
  .appState {
    .col-12 {
      .d-flex.flex-wrap {
        span.documentAppIcons {
          width: 15% !important;
        }
        p {
          width: 80% !important;
        }
      }
    }
  }
  .ant-upload {
    font-size: 6px !important;
  }
  .info_text {
    small {
      font-size: 9px !important;
    }
  }
  .ant-upload {
    p {
      font-size: 7px;
    }
  }
}

@media (min-width: 768px) {
  .application_progress_mobile {
    display: none;
  }
  .mobile_block {
    display: block;
  }
  .mobile_show {
    display: none;
  }
}

.addMarksheetButton {
  font-size: 8px;
  margin-left: 0.5rem;
  cursor: pointer;
}
.app_progress {
  width: 75%;
  .progress {
    width: 100%;
  }
  .progress-status {
    width: 50%;
  }
}
.upload_doc {
  .doc_choice {
    .btn {
      font-size: 12px;
      padding: 8px 12px;
      background-color: white;
      color: #1890ff;
      border-color: #1890ff;
      transition: all 0.2s ease;
    }
    .btn.view_details {
      width: 130px;
    }
    .btn.text-danger {
      border-color: #d43109;
    }
    .btn:active {
      box-shadow: none !important;
      border-color: #1890ff;
    }
    .btn:focus {
      box-shadow: none !important;
      border-color: #1890ff;
    }
    .btn:hover {
      background-color: #1890ff;
      color: white !important;
    }
    .btn.text-danger:hover {
      background-color: #d43109;
    }
    .selected {
      background-color: #1890ff;
      color: white;
    }
  }
  div span.icon_delete {
    img {
      width: 16px;
    }
  }
}
.active-acc {
  span {
    i {
      transform: rotateX(180deg);
    }
  }
}

.program-bookmark {
  display: "inlineBlock" !important;
  padding: "0 10px" !important;
  font-style: "normal";
  font-weight: "300" !important;
  font-size: "11px" !important;
  line-height: "25px" !important;
  align-items: "center" !important;
  color: "#666666" !important;
  border: "0.5px solid #a9a8a8" !important;
  box-sizing: "border-box" !important;
  border-radius: "50px" !important;
  margin-right: "10px" !important;
  text-decoration: "none" !important;
  cursor: "pointer";
}

#paymentSuccessFull {
  .note-text {
    width: 439px;
    margin: auto;
    padding: 12px 16px;
    gap: 8px;
    // height: 48px;
    background: rgba(122, 122, 122, 0.1);
    border-radius: 12px;
    color: #fff;
    margin-top: 32px;
  }
  .thankYou {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
  }
  .transactionNo {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #999999;
  }

  .paymentDone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(122, 122, 122, 0.1);
    backdrop-filter: blur(60px);
    height: 700px;
    margin: 32px 32px;
    padding: 0px 108px;
    img {
      width: 104px !important;
      height: 104px !important;
    }
    h5 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      text-align: center;
      color: #ffffff;
    }
    div {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      width: 100%;
      color: #999999;
    }
    .paidbillDetail {
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(102, 122, 139, 0.12);
      border-radius: 4px;
      padding: 50px;
      width: 600px;
      margin: 20px auto;
      p,
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        text-align: right;

        color: #333333;
        &:last-child {
          flex: 1;
        }
        b {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */
          color: #333333;
        }
      }
    }
  }
  .btn {
    background: #000000;
    border-radius: 74px;
    color: #ffffff;
    // font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    height: 50px;
    padding: 0 30px;
    // width: 100%;
    border: 0;
  }

  @media (max-width: 576px) {
    .note-text {
      width: 100%;
      height: 68px;
    }
  }
}

@media (max-width: 425px) {
  #paymentSuccessFull {
    .paymentDone {
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;
      // background: rgba(122, 122, 122, 0.1);
      // backdrop-filter: blur(60px);
      height: 700px;
      margin: 32px 20px;
      padding: 0px 24px;
      // img {
      //   width: 104px !important;
      //   height: 104px !important;
      // }
      h5 {
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
      }
      div {
        padding: 0px;
        font-size: 12px;
        line-height: 150%;
      }
    }
    .btnSendMoney {
      width: 100%;
    }
  }
}
// SIDEBAR WRAPPER
// #sidebar-wrapper {
//   min-height: calc(100vh - 56px);
//   margin-left: -15rem;
//   -webkit-transition: margin .25s ease-out;
//   -moz-transition: margin .25s ease-out;
//   -o-transition: margin .25s ease-out;
//   transition: margin .25s ease-out;
//   background-color: #2F3B52;
//   .list-group {
//     width: 60px;
//     .menu_icons {
//       margin: 13px 18px;
//     }
//   }
//   a {
//     .active
//     {
//       img {
//         filter: grayscale(0);
//       }
//     }
//     img {
//       filter: grayscale(1);
//     }
//   }
// }

// #page-content-wrapper {
//   min-width: 100vw;
//   background-color: #F0F0F7;
//   padding: 30px 0;
//   margin-top: 56px;
// }

// for essays
#my-essays {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 36px;
    color: #2f3b52;
  }

  .deskResp {
    .dd-options {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-transform: capitalize;
      color: #2f3b52;
    }

    .select-box {
      background: #ffffff;
      box-sizing: border-box;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      float: right;
      border-radius: 4px;
    }

    .layout-box {
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      margin-top: 20px;
      padding: 25px 30px;

      .header {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        text-transform: capitalize;
        color: #2f3b52;
      }
      .univ-logo {
        // width: 100%;
        border-radius: 50%;
        width: 70px;
        height: 70px;
        object-fit: contain;
      }
      .flex-box-logo-name {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-left: 18px;
      }
      .logo-right {
        margin-right: 15px;
      }
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #2f3b52;
      }
      .common-font {
        font-style: normal;
        font-weight: 500;
        font-size: 11.5px;
        line-height: 11px;
        color: #5b6065;
        margin-top: 12px;
      }
      .action-box {
        background: #203539;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 10px;
        width: 100%;
      }
      // statuses
      .sop-Pending {
        background: rgba(255, 146, 18, 0.12);
      }
      .sop-Questionnaire_Filled {
        background: rgba(232, 83, 226, 0.12);
      }
      .sop-Student_Draft_Created {
        background: rgba(25, 210, 222, 0.12);
      }
      .sop-Draft_In_Progress {
        background: rgba(255, 202, 16, 0.12);
      }
      .sop-Under_Student_Review {
        background: rgba(23, 155, 229, 0.12);
      }
      .sop-Finalised {
        background: rgba(42, 223, 12, 0.12);
      }
      //
      .essay-status {
        border-radius: 4px;
        padding: 8px;
        margin-top: 19.5px;
        width: fit-content;
      }
      .sop {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 13px;
        margin-top: 12px;
        color: #0487f3;
        background: rgba(4, 135, 243, 0.16);
        width: fit-content;
        padding: 0 5px;
      }
      .status-icon {
        width: 14px;
        margin-right: 5px;
      }
      .sop-box {
        background: rgba(4, 135, 243, 0.16);
      }
      .hr-line {
        border: 1px solid rgba(91, 96, 101, 0.32);
      }
      .action-font {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
        margin-top: 15px;
        outline: none !important;
      }
      .ant-select-selector {
        height: 38px;
        border-radius: 4px;
        align-items: center;
      }
      .category {
        font-style: normal;
        font-weight: 600;
        font-size: 11.5px;
        line-height: 17px;
        color: #5b6065;
        margin-top: 12px;
      }
    }
    @media (min-width: 800px) and (max-width: 1000px) {
      .header {
        font-size: 13px !important;
      }
      .common-font {
        font-size: 8.5px !important;
        line-height: 12px !important;
        margin-top: 7px !important;
      }
      .title {
        line-height: 16px !important;
        height: 30px !important;
      }
      .action-font {
        font-size: 10px !important;
        line-height: 16px !important;
      }
      .action-box {
        padding: 10px 0 !important;
      }
    }
  }

  .mobResp {
    .layout-card {
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      margin-top: 20px;
      // padding: 9px 5px 9px 0;
      padding: 9px 21px 21px 21px !important;
      // margin-left: 10px !important;
      // margin-right: 10px !important;
      .flex-child-margin {
        margin-right: 15px;
      }
      .flex-child-margin-status {
        margin-right: 20px;
        align-self: center;
      }
      .univ-logo {
        // width: 100%;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
      .flex-box-logo-name {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-left: 18px;
        margin-top: 15px;
      }
      .logo-right {
        margin-right: 15px;
      }
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: #2f3b52;
      }
      .status-title {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        // line-height: 45px;
        text-transform: uppercase;
        color: #2f3b52;
      }
      .common-font {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        // line-height: 20px;
        color: #5b6065;
        margin-top: 7px;
      }
      .action-box {
        background: #203539;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 13px;
        width: 100%;
      }
      // statuses
      .sop-Pending {
        background: rgba(255, 146, 18, 0.12);
      }
      .sop-Questionnaire_Filled {
        background: rgba(232, 83, 226, 0.12);
      }
      .sop-Student_Draft_Created {
        background: rgba(25, 210, 222, 0.12);
      }
      .sop-Draft_In_Progress {
        background: rgba(255, 202, 16, 0.12);
      }
      .sop-Under_Student_Review {
        background: rgba(23, 155, 229, 0.12);
      }
      .sop-Finalised {
        background: rgba(42, 223, 12, 0.12);
      }
      //
      .essay-status {
        border-radius: 4px;
        padding: 8px;
        width: fit-content;
      }
      .sop {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 13px;
        margin-top: 8px;
        color: #0487f3;
        background: rgba(4, 135, 243, 0.16);
        width: fit-content;
        padding: 0 5px;
      }
      .status-icon {
        width: 14px;
        margin-right: 5px;
      }
      .sop-box {
        background: rgba(4, 135, 243, 0.16);
      }
      .action-font {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
        margin-top: 15px;
        outline: none !important;
      }
      .ant-select-selector {
        height: 38px;
        border-radius: 4px;
        align-items: center;
      }
      .details-title {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: #2f3b52;
      }
      .category {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        // line-height: 20px;
        color: #5b6065;
        margin-top: 7px;
      }
    }
  }
}
.MuiInputBase-input {
  color: #fff !important;
}
// @media (min-width:840px) and (max-width:900px){
//   .layout-card {
//     padding: 9px 21px 21px 21px !important;
//     margin-left: 10px !important;
//     margin-right: 10px !important;
//   }
//   .layout-card  {.card-flex{
//     display: flex;
//     justify-content: center;
//     flex-wrap: wrap;
//   }}
// }
@media (min-width: 1000px) {
  .deskResp {
    display: block;
  }
  .mobResp {
    display: none;
  }
}
@media (min-width: 500px) and (max-width: 1000px) {
  .title,
  .category {
    height: 30px !important;
  }
}
@media (max-width: 1000px) {
  .deskResp {
    display: none !important;
  }
  .mobResp {
    display: block !important;
  }
}

// questionnaire css

#my-questionnaire {
  .heading-font {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #2f3b52;
  }
  .w3-light-grey {
    color: #000 !important;
    background-color: #e4f2f5 !important;
    width: 50%;
    height: 8px;
  }
  .bar-flex {
    width: 100%;
    display: inline-flex;
  }
  // .w3-container{
  //   margin-top:16px;
  //   margin-bottom:16px
  // }

  .w3-container:after,
  .w3-container:before {
    content: "";
    display: table;
    clear: both;
  }

  .w3-green {
    color: #fff !important;
    background-color: #05c985 !important;
  }

  .percentage {
    text-align-last: center;
    line-height: 10px;
    width: 50%;
    @media (max-width: 500px) {
      font-size: 12px;
    }
    @media (min-width: 500px) {
      font-size: 14px;
    }
  }
  button.cancel {
    border: 1px solid #203539;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 20px;
    background: transparent;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #203539;
    margin: 0 5px;
  }
  button.submit {
    background: #203539;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    padding: 11px 20px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    outline: none;
    border: none;
    margin: 0 5px;
  }
  .buttons {
    float: right;
    display: inline-flex;
  }

  /* */

  div#accordion1 {
    width: 100%;
  }
  div#accordion2 {
    width: 100%;
  }
  .panel-default > .panel-heading {
    color: #333;
    background-color: #fff;
    border-color: #e4e5e7;
    padding: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  h4 {
    margin-bottom: 0px !important;
  }
  .panel-body {
    padding: 30px;
    background-color: white;
  }
  a.collapsed {
    background: white;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #2f3b52;
    text-decoration: none;
  }

  .panel-default > .panel-heading a {
    display: block;
    padding: 10px 15px;
  }

  .panel-default > .panel-heading a:after {
    content: "";
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: "Glyphicons Halflings";
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    float: right;
    transition: transform 0.25s linear;
    -webkit-transition: -webkit-transform 0.25s linear;
  }
  .white {
    padding: 16px 10px !important;
    background: #7ab8fc !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    text-underline-offset: auto;
    color: white;
    text-decoration: none;
    /* identical to box height */

    color: #ffffff !important;
  }
  .panel-default > .panel-heading a[aria-expanded="true"] {
    background: #7ab8fc;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    color: #ffffff;
  }
  textarea {
    background: #ffffff;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    border-radius: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    margin-top: 25px;
    /* or 171% */

    color: #5b6065;
  }

  .ques {
    font-style: normal;
    /* font-weight: 600; */
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #5b6065;
  }
  .panel-default > .panel-heading1 a[aria-expanded="true"] {
    background-color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    color: #2f3b52;
  }
  .panel-default > .panel-heading a[aria-expanded="true"]:after {
    content: "\2212";
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .panel-default > .panel-heading a[aria-expanded="false"]:after {
    content: "\002b";
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

#userFeedback {
  .rating_button {
    width: 660px;
    @media (max-width: 768px) {
      width: 286px;
    }
    .ant-btn {
      width: 60px;
      @media (max-width: 768px) {
        font-size: 9px;
        width: 26px;
        padding: 6px;
      }
    }
    .ant-btn.ant-btn-primary.ant-primary-leverage {
      background-color: #002541;
      border-color: #002541;
    }
  }
}

.error {
  font-size: 11px;
  color: red;
}

.ql-container.ql-snow.ql-disabled {
  height: 200px !important;
  color: #b8b8b8 !important;
}
.ql-editor.ql-blank {
  height: 200px !important;
}
.ql-container.ql-snow {
  height: 200px;
}

img.img-thumbnail.border-0.rounded-circle.list-thumbnail.align-self-center.xsmall {
  height: 50px;
}
input.ant-input.rounded-chat-card {
  height: 50px;
  /* width: 00px; */
}
.showMobileLogo {
  display: block !important;
  margin: 0px auto;
}
// .modal-open .modal {
//   overflow-x: hidden;
//   overflow-y: auto;
//   transform: translate(1%, 30%);
// }
.modal-dialog.amount-modal {
  transform: translateY(-50%) !important;
  top: 50%;
}
.amount-modal .modal-content {
  border-radius: 12px;
  background: #1d1c1c !important;
  border: 1px solid #1d1c1c;
  box-shadow: none;
  color: #fff;
  .modal-header {
    border-bottom: 1px solid #242729;
    .modal-title {
      width: 100%;
      color: #fff;
      display: flex;
      justify-content: space-between;
    }
  }
  .modal-body {
    .upload-field {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px;
      gap: 16px;
      // width: 384px;
      background: rgba(122, 122, 122, 0.1);
      backdrop-filter: blur(60px);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #999999;
      border-radius: 8px;
      min-height: 64px;
      .upload-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 24px;
        grid-gap: 8px;
        gap: 8px;
        width: 89px;
        background: #6181ff;
        border-radius: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #ffffff;
        cursor: pointer;
        margin-top: 8px;
      }
    }
    .body-title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #c2c2c2;
    }
    .body-content {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #c2c2c2;
      margin-top: 20px;
      margin-bottom: 40px;
    }
    .btn-wrapper {
      display: flex;
      justify-content: flex-end;
      .no-btn {
        font-size: 16px;
        color: #ffffff;
        font-weight: 600;
        line-height: 24px;
        font-style: normal;
        padding: 12px 32px;
        border-radius: 8px;
        margin-right: 20px;
        background: transparent;
        font-family: "Open Sans";
        border: 1px solid #ffffff;
        cursor: pointer;
      }
      .yes-btn {
        font-size: 16px;
        color: #ffffff;
        font-weight: 600;
        line-height: 24px;
        font-style: normal;
        padding: 12px 32px;
        border-radius: 8px;
        background: #6181ff;
        font-family: "Open Sans";
        border: 1px solid #6181ff;
        cursor: pointer;
      }
    }
  }
}

.ant-tooltip-content > .ant-tooltip-inner {
  padding: 6px 12px !important;
  border: none !important;
  //rgba(0, 0, 0, 0.75)
  background: #080808;
  margin-left: 6px !important;
  ::before {
    top: 35%;
    left: 1%;
    content: "";
    position: absolute;
    transform: rotate(45deg);
    background-color: #070707;
    padding: 3px;
    z-index: 1;
  }
}
.ant-tooltip-arrow {
  width: 12px !important;
  height: 12px !important;
  display: none !important;
}
.ant-modal-content {
  border-radius: 16px !important;
  background: #1d1c1c !important;
  border: 1px solid #1d1c1c;
  box-shadow: none;
  color: #fff;
  .ant-modal-close {
    color: #fff !important;
  }
  .ant-modal-header {
    border-bottom: 1px solid #242729;
    background: #1d1c1c !important;
    padding: 0 !important;
    border-radius: 12px;

    .ant-modal-title {
      width: 100%;
      padding: 1rem;
      display: flex;
      color: #fff !important;
      justify-content: space-between;
    }
  }
  .ant-modal-body {
    .upload-field {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px;
      gap: 16px;
      // width: 384px;
      background: rgba(122, 122, 122, 0.1);
      backdrop-filter: blur(60px);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #999999;
      border-radius: 8px;
      min-height: 64px;

      .upload-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 24px;
        grid-gap: 8px;
        gap: 8px;
        width: 89px;
        background: #6181ff;
        border-radius: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #ffffff;
        cursor: pointer;
        margin-top: 8px;
      }
    }
    .body-title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #c2c2c2;
    }
    .body-content {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #c2c2c2;
      margin-top: 20px;
      margin-bottom: 40px;
    }
    .btn-wrapper {
      display: flex;
      justify-content: flex-end;
      .no-btn {
        font-size: 16px;
        color: #ffffff;
        font-weight: 600;
        line-height: 24px;
        font-style: normal;
        padding: 12px 32px;
        border-radius: 8px;
        margin-right: 20px;
        background: transparent;
        font-family: "Open Sans";
        border: 1px solid #ffffff;
        cursor: pointer;
      }
      .yes-btn {
        font-size: 16px;
        color: #ffffff;
        font-weight: 600;
        line-height: 24px;
        font-style: normal;
        padding: 12px 32px;
        border-radius: 8px;
        background: #6181ff;
        font-family: "Open Sans";
        border: 1px solid #6181ff;
        cursor: pointer;
      }
    }
  }
}

#authSection {
  display: flex;
  flex: 1;
  height: 100vh;
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(0px, -10px) scale(0.75);
  }
  .input-disabled {
    background-color: hsl(0deg 31% 6% / 41%) !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  .flex-flex-end {
    display: flex;
    justify-content: flex-end;
  }
  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(1px, 1px) scale(1);
    pointer-events: none;
  }
  .MuiOutlinedInput-root.Mui-focused fieldset {
    border: none !important;
  }
  input#outlined-basic {
    color: #fff;
  }
  input#outlined-basic_otp {
    color: #fff;
    letter-spacing: 20px;
    background: transparent;
    border: none;
  }
  .MuiOutlinedInput-input {
    padding: 10px 10px 10px 1px;
  }
  .form-group {
    margin: 0px 0px 0px 0px;
    border-bottom: 1px solid #999999;
  }
  .error-field {
    min-height: 30px;
  }
  .form-group.no-border {
    border-bottom: none;
  }
  .form-group.no-border.mt-20 {
    margin-top: 30px !important;
  }
  .icon-password .icon.hide-icon {
    height: 22px;
    top: 0px;
  }
  .icon-password .icon {
    top: 3px;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  @media (max-width: 1240px) {
    .left {
      .left-container {
        width: 80% !important;
        .toggle-check-wrapper {
          .color-white {
            font-size: 12px !important;
          }
          .color-blue {
            font-size: 12px !important;
          }
        }
        .signup-continue {
          // position: fixed;
          // bottom: 50px;
          // z-index: 111;
          // opacity: 1;
          // width: 100%;
          // max-width: 40% !important;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 32px;
          gap: 8px;
          background: #344380;
          border-radius: 12px;
          color: #536ed8;
          width: 100%;
          border: none;
        }
        .login-screen-wrapper {
          .login-heading {
            // margin-left: 25px;
            color: #fff;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 24px !important;
            line-height: 150%;
            .tnc {
              color: #999999 !important;
            }
          }

          .toggle-check-wrapper {
            .color-white {
              font-size: 12px !important;
            }
            .color-blue {
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    height: 100% !important;
    .icon-password .icon {
      top: 4px !important;
    }
    .form-group {
      margin: 0 0px 0 0px !important;
    }
    .email_font_sm {
      input {
        font-size: 14px;
      }
    }
    .right {
      display: none !important;
    }
    .left {
      // background: #f7fcfe;
      background: #000;
      height: 109vh;
      width: 50%;
      flex: 1 1;
      overflow-y: auto !important;
      background: #000;
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      .left-container {
        width: 100% !important;
        margin: 0 auto;

        .login-heading {
          margin-left: 0 !important;
          color: #fff;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 150%;
          padding: 0 25px;
          margin-top: 30px;
          margin-bottom: 10px;
          .tnc {
            color: #999999 !important;
          }
        }

        .logo {
          margin-top: 50px;
          display: none;
        }
        .signup-redirect-wrapper {
          margin-top: 10px !important;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #999999;
          // position: fixed;
          padding-bottom: 40px;
          left: -4px;
          width: 100%;
          justify-content: center;
          display: flex;
          align-items: center;
          margin-left: 0;
          .signup-redirect {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: #6181ff;
            margin-left: 3px;
          }
        }
        .login-min-height {
          min-height: 40vh !important;
        }
        .signup-continue {
          // position: fixed;
          // bottom: 50px;
          // z-index: 111;
          // opacity: 1;
          // width: 100%;
          // max-width: 90% !important;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 32px;
          gap: 8px;
          background: #344380;
          border-radius: 12px;
          color: #536ed8;
          width: 100%;
          border: none;
        }
        .login-screen-wrapper {
          // height: 100% !important;
          padding: 25px;
          display: block !important;
          // flex-direction: column;
          // justify-content: center;
          min-height: 40vh !important;
          position: relative;
          .mail-success-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 400px;
            margin-top: 0 !important;
            .mail-success-image-wrapper {
            }
            .check-mail-heading {
              color: #fff;
              font-family: "Open Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 150%;
              text-align: center;
              margin-top: 20px;
              margin-bottom: 20px;
            }
            .check-mail-title {
              color: #999999;
              font-family: "Open Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              text-align: center;
            }
          }
          .back-arrow {
            margin-right: 20px;
            cursor: pointer;
          }
          .otp-message {
            display: block !important;
          }
          .login-btn-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px 32px;
            gap: 8px;
            background: #344380;
            border-radius: 12px;
            color: #536ed8;
            width: 100%;
            border: none;
          }
          .active-login {
            background: #6181ff;
            color: #ffffff;
            border: none;
          }
          .forgot-password {
            margin-top: 0px;
            margin-bottom: 110px !important;
          }
          .toggle-check-wrapper {
            margin-bottom: 30px;
            .color-blue {
              font-family: "Open Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              color: #6181ff;
              margin-left: 4px;
              margin-right: 4px;
            }
            .color-white {
              font-family: "Open Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              color: #fff;
            }
            .agree-checkbox {
              margin-right: 10px;
            }
          }

          p.tnc.mt-1 {
            color: #999999;
          }

          .login-via-mobile {
            background: #6181ff;
            border-radius: 12px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px 32px;
            gap: 8px;
            color: #fff;
          }
          .login-via-email {
            color: #6181ff;
            border-radius: 12px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px 32px;
            gap: 8px;
            margin-top: 30px;
            // border: 1px solid #6181ff;
          }

          .verified {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
          }
          // button.button-primary.cursor_pointer.mb-0 {
          //   margin-top: 22px;
          // }
          .confirm-btn {
            margin-top: 22px;
          }
        }
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      & {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
      .cust-container {
        width: 500px;
        max-width: 90%;
        margin: 0 auto;
        height: 100%;
        margin-top: 50px;
        // background-color: grey;
        .container {
          height: 100%;
          /* align-items: center; */
          display: block;
        }

        form {
          margin-top: 40px;
          .heading {
            // font-weight: bold;
            // font-size: 32px;
            // line-height: 40px;
            font-weight: 800;
            font-size: 48px;
            line-height: 40px;
            font-family: "Darker Grotesque" !important;
            /* identical to box height, or 125% */
            // color: #284157;
            color: #000000;
            span {
              // color: #35a8e0;
              color: #0487f3;
              font-family: "Darker Grotesque";
            }
          }
          .form-group {
            position: relative;
            .static {
              position: static;
            }
            .input {
              width: 100%;
              &.verify {
                .MuiOutlinedInput-root {
                  padding-right: 94px !important;
                }
              }
            }
            .verified {
              position: absolute;
              right: 25px;
              top: 18px;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              /* identical to box height, or 167% */

              text-align: right;

              color: #1cb447;
              align-items: center;
              display: inline-flex;
              img {
                margin-right: 5px;
              }
            }
          }
          .note {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            /* or 160% */

            color: #667a8b;
            font-family: "Darker Grotesque" !important;
          }
          .tnc {
            font-style: normal;
            // font-weight: normal;
            font-family: "Open Sans";
            font-weight: 400;
            // font-size: 14px;
            font-size: 16px;
            // line-height: 16px;
            line-height: 24px;
            /* identical to box height, or 133% */

            // color: #667a8b;
            // color: #989898;
            color: #fff;
            font-family: "Darker Grotesque" !important;
            span.showLink2 {
              color: #0487f3;
              // font-family: 'Open Sans';
              font-family: "Darker Grotesque" !important;
            }
          }
          .tncSignup {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            color: #989898;
          }
          .button-primary {
            // background: #35a8e0;
            background: #000000;
            // border-radius: 8px;
            border-radius: 98px;
            width: 100%;
            // height: 58px;
            height: 64px;
            color: #ffffff;
            // font-size: 20px;
            font-size: 24px;
            font-family: "Darker Grotesque";
            margin-bottom: 20px;
            line-height: 24px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              background-color: white;
              color: black;
              border: 1px solid black;
            }
          }
        }
      }
      .otpMailSuccess-container {
        height: 93vh;
        .otpMailSuccess-block {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  &.thankyou {
    .logo {
      // position: fixed;
      // left: 30px;
      // top: 30px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      text-align: center;

      color: #667a8b;
    }
  }
  @media (max-width: 425px) {
    .left {
      // overflow-y: hidden !important;
      .cust-container {
        form {
          .heading {
            font-weight: 800;
            font-size: 32px !important;
          }
        }
      }
    }
  }
  .left {
    width: 60%;
    // flex: 1;
    // overflow-y: auto;
    overflow-y: auto;
    // background: #f7fcfe;
    background: #000;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    .left-container {
      width: 52%;
      margin: 0 auto;
      // height: 100%;
      .logo {
        margin-top: 50px;
        min-height: 200px;
      }
      .active-login {
        background: #6181ff !important;
        color: #ffffff !important;
        border: none !important;
      }
      .signup-redirect-wrapper {
        // margin-left: 25px;
        margin-top: 10px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #999999;
        .signup-redirect {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          color: #6181ff;
          margin-left: 3px;
          cursor: pointer;
        }
      }
      .login-min-height {
        min-height: 130px;
      }
      .signup-continue {
        // position: fixed;
        // bottom: 50px;
        // z-index: 111;
        // opacity: 1;
        // width: 100%;
        // max-width: 26%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 32px;
        gap: 8px;
        background: #344380;
        border-radius: 12px;
        color: #536ed8;
        width: 100%;
        border: none;
        &:hover {
          cursor: pointer !important;
          background: transparent !important;
          color: #6181ff !important;
          border: 1px solid #6181ff !important;
        }
      }
      .a > div {
        justify-content: space-between !important;
      }
      .a > div > .abc {
        margin-right: 8px;
        padding: 0px 14px;
        border-bottom: 1px solid #ccc;
      }
      .a > div > .abc > input {
        border: none;
        color: #ccc;
        outline: none;
        background: transparent;
        padding: 0;
      }
      .w-300 {
        width: 300px;
      }
      .login-screen-wrapper {
        // height: 70vh;
        display: flex;
        flex-direction: column;
        // min-height: 370px;
        // justify-content: center;
        .mail-success-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // height: 400px;
          margin-top: 150px;
          .mail-success-image-wrapper {
          }
          .check-mail-heading {
            color: #fff;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 150%;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 20px;
          }
          .check-mail-title {
            color: #999999;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
          }
        }
        .a > div {
          justify-content: space-between !important;
        }
        .a > div > .abc {
          margin-right: 8px;
          padding: 0px 14px;
          border-bottom: 1px solid #ccc;
        }
        .a > div > .abc > input {
          border: none;
          color: #ccc;
          outline: none;
          background: transparent;
          padding: 0;
        }

        .back-arrow {
          margin-right: 20px;
          cursor: pointer;
        }
        .login-btn-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 32px;
          gap: 8px;
          background: #344380;
          border-radius: 12px;
          color: #536ed8;
          width: 100%;
          border: none;

          &:hover {
            cursor: not-allowed !important;
            background-color: #858585 !important;
            color: white !important;
            border: none !important;
          }
        }
        .otp-message {
          display: flex;
          margin-bottom: 20px;
        }
        .active-login {
          background: #6181ff;
          color: #ffffff;
          border: none;
          cursor: pointer;
          &:hover {
            cursor: pointer !important;
            // background: #6181ff !important;
            background: transparent !important;
            color: #6181ff !important;
            border: 1px solid #6181ff !important;
          }
        }
        .forgot-password {
          margin-top: 0px;
          margin-bottom: 10px;
        }
        .toggle-check-wrapper {
          margin-bottom: 20px;
          margin-top: 40px;
          display: flex;
          align-items: center;

          .color-blue {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #6181ff;
            margin-left: 4px;
            margin-right: 4px;
          }
          .color-white {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #fff;
          }
          .agree-checkbox {
            margin-right: 10px;
          }
        }

        p.tnc.mt-1 {
          color: #999999;
        }

        .login-heading {
          color: #fff;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 150%;
          margin-bottom: 10px;
          // margin-left: 25px;
          .tnc {
            color: #999999 !important;
          }
        }
        .login-via-mobile {
          background: #6181ff;
          border-radius: 12px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 32px;
          gap: 8px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #fff;
          // margin-top: 80px;
          cursor: pointer;

          &:hover {
            background: transparent;
            color: #6181ff;
            border: 1px solid #6181ff;
          }
        }
        .login-via-email {
          color: #6181ff;
          border-radius: 12px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 32px;
          gap: 8px;
          margin-top: 30px;
          // border: 1px solid #6181ff;
          cursor: pointer;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;

          &:hover {
            background: #6181ff;
            color: #fff;
            border: 1px solid #6181ff;
          }
        }

        .verified {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
        }
        // button.button-primary.cursor_pointer.mb-0 {
        //   margin-top: 22px;
        // }
        .confirm-btn {
          margin-top: 22px;
        }
      }
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    & {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    .cust-container {
      width: 500px;
      max-width: 90%;
      margin: 0 auto;
      height: 100%;
      margin-top: 50px;
      // background-color: grey;
      .container {
        height: 100%;
        /* align-items: center; */
        display: block;
      }

      form {
        margin-top: 40px;
        .heading {
          // font-weight: bold;
          // font-size: 32px;
          // line-height: 40px;
          font-weight: 800;
          font-size: 48px;
          line-height: 40px;
          font-family: "Darker Grotesque" !important;
          /* identical to box height, or 125% */
          // color: #284157;
          color: #000000;
          span {
            // color: #35a8e0;
            color: #0487f3;
            font-family: "Darker Grotesque";
          }
        }
        .form-group {
          position: relative;
          .static {
            position: static;
          }
          .input {
            width: 100%;
            &.verify {
              .MuiOutlinedInput-root {
                padding-right: 94px !important;
              }
            }
          }
          .verified {
            position: absolute;
            right: 25px;
            top: 18px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 167% */

            text-align: right;

            color: #1cb447;
            align-items: center;
            display: inline-flex;
            img {
              margin-right: 5px;
            }
          }
        }
        .note {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          /* or 160% */

          color: #667a8b;
          font-family: "Darker Grotesque" !important;
        }
        .tnc {
          font-style: normal;
          // font-weight: normal;
          font-family: "Open Sans";
          font-weight: 400;
          // font-size: 14px;
          font-size: 16px;
          // line-height: 16px;
          line-height: 24px;
          /* identical to box height, or 133% */

          // color: #667a8b;
          // color: #989898;
          color: #999999;
          font-family: "Darker Grotesque" !important;
          span.showLink2 {
            color: #0487f3;
            // font-family: 'Open Sans';
            font-family: "Darker Grotesque" !important;
          }
        }
        .tncSignup {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          color: #989898;
        }
        .button-primary {
          // background: #35a8e0;
          background: #000000;
          // border-radius: 8px;
          border-radius: 98px;
          width: 100%;
          // height: 58px;
          height: 64px;
          color: #ffffff;
          // font-size: 20px;
          font-size: 24px;
          font-family: "Darker Grotesque";
          margin-bottom: 20px;
          line-height: 24px;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            background-color: white;
            color: black;
            border: 1px solid black;
          }
        }
      }
    }
    .otpMailSuccess-container {
      height: 93vh;
      .otpMailSuccess-block {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  a {
    color: #0487f3;
  }
  .right {
    // flex: 1;
    width: 40%;
    background: #344380;
    align-items: center;
    justify-content: center;
    .background-image {
      width: 85%;
    }
  }
  button:disabled {
    // background-color: #858585 !important;
  }
  .input-disabled {
    background-color: #d3d3d369;
  }
  span.showLink {
    color: #6181ff;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
  }
}

.MuiCheckbox-root {
  padding: 0 !important;
  margin-right: 10px !important;
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: black !important;
}

.go-back {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #ffffff;
  span {
    display: inline-flex;
    align-items: center;
    img {
      cursor: pointer;
      margin: 0px 15px 0px 0px;
      width: 32px;
    }
  }
}
.recipient-text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #ffffff;
}
p.sub-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-left: 27px;
  color: rgba(255, 255, 255, 0.8);
}
p.sub-text-add {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-left: 41px;
  color: rgba(255, 255, 255, 0.8);
}
p.sub-text2 {
  color: #e40000;
}

#userTypeSelectionSection {
  // padding: 30px 0;
  .sub-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
    /* identical to box height, or 150% */

    color: #667a8b;
  }
  .box {
    background: #ffffff;
    border: 2px solid #f3f3f3;
    box-sizing: border-box;
    box-shadow: 0px 4px 24px rgba(102, 122, 139, 0.16);
    border-radius: 16px;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
    padding: 45px 30px 45px;
    justify-content: center;
    position: relative;
    cursor: pointer;
    &:hover {
      border: 2px solid #35a8e0;
    }
    &.active {
      background: #ffffff;
      border: 2px solid #35a8e0;
      box-sizing: border-box;
      box-shadow: 0px 4px 24px rgba(102, 122, 139, 0.16);
      border-radius: 16px;
      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        &::after {
          content: "";
          position: absolute;
          height: 10px;
          width: 10px;
          background: #35a8e0;
          border-radius: 50%;
        }
      }
    }
    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
    .circle {
      border: 2px solid #e1e5e8;
      height: 20px;
      width: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      border-radius: 50%;
    }
    img {
      margin-bottom: 25px;
    }
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      text-align: center;

      color: #284157;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      /* identical to box height, or 167% */

      text-align: center;

      color: #667a8b;
      margin: 0;
    }
  }
}

.table-action {
  padding-top: 20px;
  display: flex;
  align-items: center;
  .float-right {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
  .actions {
    display: flex;
    align-items: center;
    .text1 {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */

      color: #667a8b;
      margin-right: 10px;
    }
    .ant-select-selector {
      background: #ffffff;
      border: 1px solid #e1e5e8 !important;
      border-radius: 4px !important;
    }
    .prev {
      background: #6181ff;
      border: 1px solid #e1e5e8;
      border-radius: 4px;
      transform: rotate(-180deg);
      margin-right: 10px;
      margin-left: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 33px;
    }
    .next {
      background: #6181ff;
      border: 1px solid #e1e5e8;
      border-radius: 4px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 33px;
    }
  }
}
.no-recipient {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  /* identical to box height, or 36px */

  text-align: center;

  /* Secondary/White */

  color: #ffffff;
}
#UserDocumentUploadSection {
  padding: 30px 0;

  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    width: 100%;
    height: 150px;
    background: #ffffff;
    border: 2px dashed #e1e5e8;
    box-sizing: border-box;
    border-radius: 16px;
  }

  .ant-upload-list-picture-card-container {
    display: none;
  }
  .ant-upload-list-item.ant-upload-list-item-uploading.ant-upload-list-item-list-type-picture-card {
    display: none;
  }
  .uploadCard {
    .head-text {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      color: #000000;
    }
    .error {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */
      color: #ed3131;
      display: inline-flex;
      align-items: center;
    }
    .verify {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      color: #284157;
      display: inline-flex;
      align-items: center;
    }
    .card_preview {
      background: #ffffff;
      border: 1px solid #f3f3f3;
      box-sizing: border-box;
      box-shadow: 0px 0px 24px rgba(102, 122, 139, 0.16);
      border-radius: 8px;
      padding: 20px;
      display: flex;
      position: relative;
      .imgBox {
        width: 52%;
        padding-right: 20px;
        /* width: 200px; */
        height: 90px;
        overflow: hidden;
        display: flex;
        // img {
        //   // width: 100%;
        //   // height: auto;
        // }
      }
      .details {
        flex: 1;
        padding-left: 10px;
        h6 {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height, or 133% */

          color: #284157;
        }
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height, or 133% */
          color: #667a8b;
        }
        .remove {
          position: absolute;
          bottom: 20px;
          right: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .uploadDetails {
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      color: #000000;
      margin-bottom: 30px;
    }
    .upload-doc {
      // margin: 20px -23px;
      width: 400px;
    }
    .note {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #0487f3;
      margin-top: 30px;
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
      }
    }
    .radioGroup {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      color: #667a8b;
      .ant-radio-wrapper {
        display: block !important;
      }
      .primary-button {
        // line-height: 20px;
        // padding: 5px;
        // text-align: center;
        // width: 100%;
        background: #000000;
        border-radius: 74px;
        color: #ffffff;
        // font-family: 'Darker Grotesque';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        padding: 5px;
        width: 100%;
        border: 0;
      }
    }
  }

  .guidelines {
    margin-top: 40px;
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 167% */

      color: #3d3d3d;
      margin-bottom: 10px;
    }
    ul {
      padding: 0 28px;
      li {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 27px;
        /* identical to box height, or 167% */

        color: #121212;
        position: relative;
        list-style-type: none;
        img {
          position: absolute;
          left: -27px;
          top: 7px;
        }
      }
    }
  }
}
.ant-upload.ant-upload-drag {
  background: #ffffff !important;
  border: 2px dashed #e1e5e8 !important;
  border-radius: 16px !important;
  margin-top: 20px;
  &.ant-upload-drag-hover {
    border: 2px dashed #35a8e0 !important;
  }
}
.icon-password {
  position: relative;
  input {
    padding-right: 55px;
  }
  .icon {
    position: absolute;
    right: 2px;
    top: 20px;
    z-index: 99;
    height: 15px;
    cursor: pointer;
    &.hide-icon {
      height: 22px;
      top: 17px;
    }
    // &.show-icon {
    // }
  }
}
.pointer {
  cursor: pointer;
}
.upload-doc {
  // background: #ffffff;
  // border: 2px dashed #e1e5e8;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 25px ​40px !important;
  flex-direction: column;
  // margin: 20px;
  cursor: pointer;
  padding: 0 20px;
  img {
    margin-bottom: 20px;
    margin-top: 25px;
  }
  input {
    visibility: hidden;
    position: absolute;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;

    color: #667a8b;
    margin-bottom: 10px;
  }
  button {
    background: #858585;
    border-radius: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    color: #fff;
    border: 0;
    padding: 6px 15px;
    font-weight: 500;
    margin: 10px;
    margin-bottom: 25px;
    &:hover {
      background: #000000;
      border-radius: 8px;
      color: #fff;
    }
  }
}

@keyframes bar {
  0% {
    top: 0%;
  }
  50% {
    top: calc(100% - 8px);
  }
  100% {
    top: 0%;
  }
}

.upload-file-caption {
  float: right;
  color: #999999;
  text-align: right;
}

.submit-btn {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 8px;
  cursor: pointer;
  button {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 12px 32px;
    gap: 8px;
    border: none;
    width: 100%;
    height: 48px;
    background: #6181ff;
    border-radius: 12px;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
}
// #UserPersonalDetailsSection .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header
#UserPersonalDetailsSection {
  // padding: 30px 0;
  .page-alignment {
    // padding: 32px 32px;
  }
  .excluding-text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #999999;
  }
  .go-back {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #ffffff;
    span {
      display: inline-flex;
      align-items: center;
      img {
        cursor: pointer;
        margin: 0 15px 0 0;
        width: 32px;
      }
    }
  }
  p.sub-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-left: 32px;
    color: rgba(255, 255, 255, 0.8);
  }
  .details-container {
    background: rgba(122, 122, 122, 0.2);
    border-radius: 16px;
    padding: 32px;
    margin-bottom: 82px;
  }
  .note1 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #e40000;
  }
  .ant-collapse {
    border: 0;
    background: transparent;
    > .ant-collapse-item {
      border: 1px solid #000000 !important;
      border-radius: 4px;
    }
  }
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    .note {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      // text-align: right;
      color: #e40000;
    }
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: 0;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(102, 122, 139, 0.12);
    border-radius: 4px;
    margin-bottom: 15px;
  }
  .text-head {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 16px;
  }
  .upload-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 24px;
    gap: 8px;
    width: 89px;
    background: #6181ff;
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    cursor: pointer;
    margin-top: 8px;
    @media (max-width: 425px) {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }
  .upload-input-file {
    display: none;
  }
  .upload-file-caption {
    float: right;
    color: #999999;
    text-align: right;
  }
  .send-otp-btn {
    border: 1px solid #6181ff;
    padding: 6px 24px;
    background: #6181ff;
    border-radius: 8px;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;

    @media (max-width: 380px) {
      padding: 8px 20px !important;
      font-size: 10px !important;
      font-weight: 500;
      line-height: 14px !important;
    }
    @media (min-width: 380px) and (max-width: 414px) {
      padding: 6px 20px !important;
      font-size: 10px !important;
      font-weight: 500;
      line-height: 14px !important;
    }
  }
  .verifyBtn {
    padding-left: 50px;
    @media (max-width: 1360px) {
      padding-left: 35px;
    }
  }

  .toggleBtnStyle {
    color: white;
    width: 100%;
    border-bottom: 1px solid rgb(255, 255, 255) !important;
    border: transparent;
    background: transparent;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .upload-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    gap: 16px;
    // width: 384px;
    background: rgba(122, 122, 122, 0.1);
    backdrop-filter: blur(60px);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #999999;
    border-radius: 8px;
    min-height: 64px;
    @media (max-width: 425px) {
      min-height: 48px;
    }
  }
  .guidelines {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #0487f3;
  }
  .pnote {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #3d3d3d;
  }
  .saveBtn2 {
    background: #000000;
    border-radius: 74px;
    color: #ffffff;
    // font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    height: 50px;
    // padding: 0 30px;
    width: 100%;
    border: 0;
    cursor: pointer;
    &:hover {
      background-color: white !important;
      color: black !important;
      border: 1px solid black !important;
    }
  }
  .saveBtn3 {
    background: #6181ff;
    border-radius: 74px;
    color: #ffffff;
    // font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    height: 50px;
    // padding: 0 30px;
    // width: 100%;
    border: 0;
    cursor: pointer;
    &:hover {
      background-color: white;
      color: black;
      border: 1px solid black;
    }
  }
  .saveBtn {
    background: #000000;
    border-radius: 74px;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* display: flex; */
    /* align-items: center; */
    text-align: center;
    color: #ffffff;
    border: 0;
    height: 50px;
    padding: 0 20px;
    cursor: pointer;
    &:hover {
      background-color: white !important;
      color: black !important;
      border: 1px solid black !important;
    }
  }

  .MuiInput-underline:before {
    border-bottom: 2px solid #ffffff !important;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid #ffffff !important;
  }
  .MuiInput-root .MuiInput-input {
    padding: 4px 4px 6px 0px !important ;
  }
}

#ResendOTPBtn {
  .resendBtn {
    bottom: -22px;
    position: absolute;
    right: 0;
    font-size: 14px;
    background: transparent;
    border: none;
    // @media (max-width: 411px) {
    //   font-size: 8px;
    // }
  }
  button:disabled:hover {
    background-color: transparent !important;
    border: none !important;
  }
}
.MuiFormControl-root {
  margin: 0 !important;
  color: #999999;
}

.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 160px 24px;
  background: rgba(122, 122, 122, 0.3);
  border-radius: 16px;
  margin-top: 24px;
  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    /* identical to box height, or 36px */

    text-align: center;

    /* Secondary/White */

    color: #ffffff;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;

    color: #c2c2c2;
    margin-bottom: 30px;
  }
}

#UserRecipientSection {
  // padding: 30px 0;
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
  .addNewRecipient {
    background: #2f3b52;
  }
  .table-container {
    padding: 20px 30px;
    .table-action {
      .page-counter {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */
        color: #667a8b;
      }
    }
  }
  // .table-responsive {
  //   background: #ffffff;
  //   border: 1px solid #000000;
  //   box-sizing: border-box;
  //   box-shadow: 0px 0px 16px rgba(102, 122, 139, 0.12);
  //   border-radius: 8px;
  //   padding: 0 15px;
  // }
  .table-responsive {
    // border: 1px solid #3D3D3D;
    box-sizing: border-box;
    background: rgba(122, 122, 122, 0.3);
    border-radius: 8px;
  }
  table {
    margin-bottom: 0;
    thead {
      height: 56px;
      tr {
        th {
          padding: 20px 20px;
          // font-style: normal;
          // font-weight: 300;
          // font-size: 14px;
          // line-height: 30px;
          // /* identical to box height, or 133% */

          // color: #667a8b;
          // border: 0;
          // border-bottom: 1px solid #e1e5e8;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          border: 0;
          border-bottom: 1px solid #3d3d3d;
          color: #999999;
        }
      }
    }

    tbody {
      height: 96px;

      tr {
        border-collapse: collapse;
        transition: 0.5s;
        border-radius: 10px;
        overflow: hidden;
        padding: 32px;
        @media (max-width: 500px) {
          border-bottom: 10px solid black;
        }
        td {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #ffffff;
          border-bottom: 1px solid #3d3d3d !important;
          padding: 20px 20px;
          @media (max-width: 500px) {
            padding: 11px 11px;
          }
          &.recipient-column {
            font-weight: 700 !important;
          }

          &.actions {
            min-width: 133px;
            color: #6181ff;
            &:hover {
              color: #fff;
            }
            span {
              padding: 2px;
              border-radius: 8px;
              height: 30px;
              display: inline-flex;
              width: 30px;
              align-items: center;
              justify-content: center;
              margin-right: 5px;

              &:hover {
                &.view {
                  background: rgba(28, 180, 71, 0.12);
                }
                &.edit {
                  background: rgba(53, 168, 224, 0.12);
                }
                &.delete {
                  background: rgba(237, 49, 49, 0.12);
                }
              }
            }
          }
        }
        &:hover {
          background: #344380;
          border-radius: 8px;
        }
      }
    }
    th,
    td {
      vertical-align: middle;
      padding: 0.5rem;
      .secondary-button {
        width: 115px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 167% */
        border-radius: 74px;
        display: flex;
        align-items: center;
        text-align: center;
        border: 1px solid #000000;
        color: #000000;
        padding: 0 20px;
        background-color: #ffffff;
      }
      &.w-190 {
        width: 130px;
      }
    }
    .action {
      min-width: 90px !important;
      color: #6181ff !important;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      &:hover {
        color: #fff !important;
      }
    }
  }
  .context {
    .box {
      display: flex;
      // background: #ffffff;
      // border: 1px solid black;
      // box-sizing: border-box;
      // box-shadow: 0px 4px 16px rgba(102, 122, 139, 0.12);
      // border-radius: 8px;
      // padding: 10px 20px;
      padding: 24px 16px;
      gap: 16px;
      // width: 366px;
      // height: 98px;
      background: rgba(122, 122, 122, 0.3);
      backdrop-filter: blur(60px);
      border-radius: 12px;
      align-items: center;
      margin-bottom: 10px;
      &.active {
        border: 1px solid #35a8e0;
      }
      .labeler {
        display: flex;
        align-items: center;
        flex: 1;
        .checkbox {
          width: 40px;
          input {
            position: relative;
            margin: 0;
            padding: 0;
          }
        }
        .details {
          .name-label {
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 12px;
            /* identical to box height, or 120% */

            color: #667a8b;
          }
          .name {
            // font-style: normal;
            // font-weight: 500;
            // font-size: 14px;
            // line-height: 12px;
            // /* identical to box height, or 100% */

            // display: flex;
            // align-items: center;

            // color: #284157;
            // margin: 0;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 150%;
            color: #ffffff;
          }
        }
      }
      .action {
        .view,
        .delete {
          padding-left: 10px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #6181ff;
        }
      }
      .acNo {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #999999;
      }
    }
  }
  img {
    // margin: 0 15px 0 0;
    cursor: pointer;
    transition: 0.5s;
    // &:hover {
    //   transform: scale(1.1);
    // }
  }
}
.viewDetailsModalSection {
  width: 900px;
  max-width: 95%;
  .modal-content {
    // background: #ffffff;
    // border: 3px solid black;
    box-sizing: border-box;
    // box-shadow: 0px 8px 48px rgba(102, 122, 139, 0.48);
    // box-shadow: 0px 0px 0px 0.2px white;
    box-shadow: 0px 8px 48px rgb(102 122 139 / 48%);
    border-radius: 16px;
    background: rgba(122, 122, 122, 0.1);
    backdrop-filter: blur(60px);
  }
}
#viewDetailModalSection {
  padding: 30px;
  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    // color: #000000;
    color: #ffffff;
    margin-bottom: 25px;
  }
  .cross {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
  }
  .dataBox {
    label {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */
      color: #ffffff;
      // color: #3d3d3d;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      /* identical to box height, or 133% */
      color: #ffffff;
      // color: #000000;
      overflow-wrap: anywhere;
      a {
        // color: #000000 !important;
        color: #ffffff !important;
      }
    }
    a {
      color: #284157 !important ;
    }
  }

  .line {
    border-bottom: 1px solid #e1e5e8;
    margin: 10px 0 30px;
  }
}
.transactionData {
  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
}
.accordion
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed):after {
  -webkit-transform: rotate(226deg);
  transform: rotate(226deg);
}
.accordion-button:not(.collapsed):after {
  transform: rotate(180deg);
}
.accordion-button:after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.accordion .accordion-item .accordion-header {
  position: relative;
  .accordion-button:after {
    background-image: url(https://leverageedunew.s3.us-east-1.amazonaws.com/assets/img/leverage-finance/plus.svg);
    height: 13px;
    width: 13px;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: 0px;
    top: 25px;
    // top: 50%;
    // transform: translate(-50%,-50%);
  }
}

#revDetailSection {
  // padding: 30px 0;
  .previewContainer {
    padding: 48px 30px;
  }
  .go-back {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #ffffff;
    span {
      display: inline-flex;
      align-items: center;
      img {
        cursor: pointer;
        margin: 0px 15px 0 0;
        width: 32px;
      }
    }
  }
  p.sub-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-left: 47px;
    color: rgba(255, 255, 255, 0.8);
  }

  .cards-container {
    flex-direction: row;
    width: 100%;
  }
  .cards-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
  }
  .head-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    color: #000000;
  }
  .saveBtn {
    background: #000000;
    border-radius: 74px;
    color: #ffffff;
    // font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    height: 50px;
    // padding: 0 30px;
    width: 50%;
    border: 0;
    &:hover {
      background-color: white;
      color: black;
      border: 1px solid black;
    }
  }

  .details-container {
    background: rgba(122, 122, 122, 0.2);
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 82px;
    margin: 8px 16px;
    height: 100%;
  }
  .details-container-2 {
    background: rgba(122, 122, 122, 0.2);
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 82px;
    margin: 8px 16px;
    // height: 100%;
  }
  .details {
    .note {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      /* or 167% */

      color: #35a8e0;
    }
  }
  .ant-collapse {
    background-color: transparent;
    > .ant-collapse-item > .ant-collapse-header {
      padding: 12px 0;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      color: #fff;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 16px 0;
      padding-bottom: 0;
    }
  }
  .edit {
    margin-right: 40px;
    height: 35px;
    padding: 0 20px;

    background-color: #ffffff;
    border: 1px solid #000000;
    color: #000000;
    border-radius: 74px;
    &:hover {
      background-color: black;
      color: white;
    }
  }
  table {
    tr {
      &:first-child {
        td {
          padding-top: 12px;
        }
      }
      &:last-child {
        td {
          padding-bottom: 0;
        }
      }
      td:first-child {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        color: #667a8b;
      }
      th,
      td {
        padding: 0.4rem 0;
        border-top: 0;
      }
    }
    tfoot,
    tbody {
      // border-top: 1px solid #e1e5e8;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #ffffff;
    }
  }

  @media (max-width: 767px) {
    .previewContainer {
      padding: 24px 24px;
    }
    .cards-container {
      flex-direction: column;
    }
    p.sub-text {
      margin-left: 0px !important;
    }
  }
}

.ant-tooltip-inner {
  font-family: "Open Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  color: #c2c2c2 !important;
  padding: 12px !important;
}
@media screen and (max-width: 767px) {
  .ant-tooltip-inner {
    width: 44vw !important;
  }
}
#paymentSection {
  // padding: 30px 0;
  .info-icon {
    width: 21px !important;
    margin-bottom: 3px;
  }
  .tcs-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #c2c2c2;
    padding: 8px;
  }
  padding: 48px 17px;
  .sub-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    color: #3d3d3d;
    font-family: "Open Sans", sans-serif;
  }

  .tag {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: right;

    color: #5eb568;
    background: #d4ffd9;
    border-radius: 8px;
    display: inline-flex;
    padding: 10px 20px;
    img {
      margin-right: 8px;
    }
  }
  .note {
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    color: #0487f3;
  }
  .box {
    // background: #ffffff;
    // border: 2px solid #000000;
    // box-sizing: border-box;
    // box-shadow: 0px 4px 24px rgba(102, 122, 139, 0.16);
    // border-radius: 16px;
    // padding: 20px;
    // height: 100%;
    // text-align: center;
    // display: flex;
    // flex-direction: column;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px;
    gap: 32px;
    // width: 100%;
    // height: 248px;
    background: rgba(122, 122, 122, 0.1961);
    border-radius: 16px;
    &:hover {
      // background: #ffffff;
      // border: 2px solid #6181ff;
      // box-sizing: border-box;
      // box-shadow: 0px 4px 24px rgba(102, 122, 139, 0.16);
      // border-radius: 16px;
      // cursor: pointer;
      // transition: 0.1s;
      // &:focus {
      //   border: 2px solid #6181ff;
      // }
      // &:active {
      //   border: 2px solid #6181ff;
      // }
    }
    .inside-box {
      // display: flex;
      // justify-content: space-between;
      // border-left: 1px solid #3d3d3d;
      height: 100%;
    }
    .imgBox {
      height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      text-align: center;

      color: #284157;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      /* identical to box height, or 167% */

      text-align: center;

      color: #667a8b;
      flex: 1;
    }
    .b-table {
      // border-top: 1px solid #e1e5e8;
      // padding-top: 10px;
      min-height: 50px;
    }
    table {
      td {
        // font-style: normal;
        // font-weight: normal;
        // font-size: 10px;
        // line-height: 16px;
        // /* identical to box height, or 160% */

        // color: #667a8b;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #c2c2c2;
        padding: 8px;
      }
    }
    .more-details {
      display: flex;
      justify-content: flex-end;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      text-align: right;
      color: #6181ff;
    }
    .cardImage {
      width: 90%;
      height: 100%;
      border-radius: 16px;
      margin: 0 10%;
    }
  }
  .note-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 8px;
    background: rgba(231, 76, 60, 0.4);
    border-radius: 12px;
    width: 50%;

    p {
      padding: 2px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: #ffffff;
      margin-bottom: 0px !important;
    }
  }

  @media (max-width: 767px) {
    padding: 24px 7px;
    overflow: hidden;
    .box {
      flex-direction: column;
      padding: 16px;
      width: 100%;
      table {
        td {
          // font-style: normal;
          // font-weight: normal;
          // font-size: 10px;
          // line-height: 16px;
          // /* identical to box height, or 160% */

          // color: #667a8b;
          font-style: normal;
          font-weight: 400;
          font-size: 13px !important;
          line-height: 150%;
          color: #c2c2c2;
          padding: 8px 0px;
        }
      }
      .inside-box {
        border: none;
        padding-bottom: 16px;
        // border-top: 1px solid #3d3d3d;
      }
    }
    .note-container {
      width: 100%;
    }
    .cardImage {
      height: 100% !important;
      width: 100% !important;
      border-radius: 16px;
      margin: 0 2% !important;
    }
    .info-icon {
      width: 17px !important;
      margin-bottom: 3px;
    }
  }

  // .continueBtn {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   align-items: center;
  //   padding: 12px 32px;
  //   gap: 8px;
  //   margin: 16px 0;
  //   width: 135px;
  //   height: 48px;
  //   color: #fff;
  //   font-style: normal;
  //   font-weight: 600;
  //   font-size: 16px;
  //   line-height: 24px;
  //   border: 1px solid #6181ff;
  //   /* Primary/Purple 48% */

  //   background: #6181ff;
  //   border-radius: 12px;
  // }
  // .continuebtn-container {
  //   max-width: 100%;
  //   margin-top: 2%;
  //   border-top: 1px solid #7a7a7a;
  //   display: flex;
  //   align-items: flex-end;
  //   justify-content: flex-end;
  // }
}
#amoundHeaderHeadSection {
  background: #000000;
  // box-shadow: 0px 2px 4px rgba(102, 122, 139, 0.16);
  padding: 20px 0;
  .head-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo-head {
      .logo {
      }
    }

    // .process-steps {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   width: 50%;
    //   padding: 1px;
    //   .step {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     flex-direction: column;
    //     flex: 1;
    //     position: relative;
    //     &:last-child {
    //       &::before {
    //         display: none;
    //       }
    //       .number {
    //       }
    //     }
    //     &.complete {
    //       &::before {
    //         background: #6181ff;
    //       }
    //       .number {
    //         background: #6181ff;
    //         border: 1.5px solid #6181ff;
    //         color: #000000;
    //       }
    //     }
    //     &.active {
    //       .number {
    //         background: #fec807;
    //         border: 2px solid #fec807;
    //         // color: #000000;
    //         // padding: 8px;
    //         // position: relative;
    //         // z-index: 1;
    //         background-repeat: no-repeat;
    //         background: #fec807;
    //         // border-color: #9AE7DC
    //         animation: 0.3s ease-out;
    //         background-image: url("/assets/img/Tick.png");
    //         // &:after
    //         // background: #9AE7DC;
    //       }
    //     }
    //     &.number {
    //       &::after {
    //         background: #9ae7dc;
    //       }
    //     }

    //     &::before {
    //       content: "";
    //       height: 2px;
    //       width: 100%;
    //       background: #c4c4c4;
    //       position: absolute;
    //       left: 50%;
    //       top: 12px;
    //     }
    //     .number {
    //       border: 2px solid #e1e5e8;
    //       box-sizing: border-box;
    //       width: 25px;
    //       height: 25px;
    //       border-radius: 50%;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       color: #fff;
    //       background: #e1e5e8;
    //       z-index: 9;
    //       position: relative;
    //     }
    //     .label {
    //       font-style: normal;
    //       font-weight: 500;
    //       font-size: 16px;
    //       line-height: 24px;
    //       /* identical to box height, or 150% */

    //       text-align: center;

    //       color: #667a8b;
    //       margin-top: 5px;
    //     }
    //   }
    // }
    .cross {
    }
  }
}

#paymentMode {
  // padding: 30px 0;
  .paymentContainer {
    padding: 48px 30px;
  }
  // .paymentLink {
  //   color: red !important;
  // }
  .go-back {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #ffffff;
    span {
      display: inline-flex;
      align-items: center;
      img {
        cursor: pointer;
        margin: 0px 15px 0 0;
        width: 32px;
      }
    }
  }
  .sub-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.8);
    margin-left: 47px;
  }

  .table-responsive {
    // border: 1px solid #3D3D3D;
    box-sizing: border-box;
    background: rgba(122, 122, 122, 0.1);
    border-radius: 8px;
  }

  .MuiAccordion-root {
    background: rgba(122, 122, 122, 0.1);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
    margin: 0px;
    border-bottom: 1px solid #3d3d3d;
    :focus {
      background: rgba(122, 122, 122, 0.2);
    }
    // &:hover{
    //   background: rgba(122, 122, 122, 0.2);
    // }.
  }
  .MuiAccordionSummary-content {
    justify-content: space-between;
    height: 56px;
    margin: 12px 18px !important;
  }
  .MuiCollapse-entered {
    background: rgba(122, 122, 122, 0.2);
  }
  .head-container {
    height: 56px;
    background: rgba(122, 122, 122, 0.1);
    border-bottom: 1px solid #3d3d3d !important;
    width: 100%;
    margin-left: 0px;
  }
  .more-details {
    display: flex;
    justify-content: flex-end;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    text-align: right;
    color: #6181ff;
  }
  .table-head {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #999999;
    display: flex;
    align-items: center;
    height: 56px;
  }
  .table-image {
    width: 32px;
    margin: 0 16px 0 -8px;
  }
  table {
    margin-bottom: 0;
    thead {
      height: 56px;
      background: rgba(122, 122, 122, 0.1);
      tr {
        th {
          // font-style: normal;
          // font-weight: 300;
          // font-size: 14px;
          // line-height: 30px;
          // /* identical to box height, or 133% */

          // color: #667a8b;
          // border: 0;
          // border-bottom: 1px solid #e1e5e8;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          border: 0;
          border-bottom: 1px solid #3d3d3d;
          color: #999999;
        }
      }
    }

    tbody {
      height: 96px;

      tr {
        // border-collapse: collapse;
        transition: 0.5s;
        border-radius: 10px;
        overflow: hidden;
        // border-bottom: 1px solid #3D3D3D !important;
        padding: 32px;
        height: 96px;
        background: rgba(122, 122, 122, 0.1);
        // backdrop-filter: blur(60px);

        td {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #ffffff;
          border-bottom: 1px solid #3d3d3d !important;
          &.recipient-column {
            font-weight: 700 !important;
          }
          label {
            margin-bottom: 0rem;
            padding-left: 34px;
          }

          &.actions {
            min-width: 133px;
            color: #6181ff;
            &:hover {
              color: #fff;
            }
            span {
              padding: 2px;
              border-radius: 8px;
              height: 30px;
              display: inline-flex;
              width: 30px;
              align-items: center;
              justify-content: center;
              margin-right: 5px;
              &:hover {
                &.view {
                  background: rgba(28, 180, 71, 0.12);
                }
                &.edit {
                  background: rgba(53, 168, 224, 0.12);
                }
                &.delete {
                  background: rgba(237, 49, 49, 0.12);
                }
              }
            }
          }
        }
        &:hover {
          // background: #344380;
          border-radius: 8px;
          background: rgba(122, 122, 122, 0.2);
        }
      }
    }
    th,
    td {
      vertical-align: middle;
      padding: 0.5rem;
      .secondary-button {
        width: 115px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 167% */
        border-radius: 74px;
        display: flex;
        align-items: center;
        text-align: center;
        border: 1px solid #000000;
        color: #000000;
        padding: 0 20px;
        background-color: #ffffff;
      }
      &.w-190 {
        width: 130px;
      }
    }
  }

  .tag {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: right;

    color: #5eb568;
    background: #d4ffd9;
    border-radius: 8px;
    display: inline-flex;
    padding: 10px 20px;
    img {
      margin-right: 8px;
    }
  }
  .note {
    background: rgba(231, 76, 60, 0.4);
    border-radius: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
    padding: 12px 16px;
  }
  .box {
    // background: #ffffff;
    // border: 2px solid #000000;
    // box-sizing: border-box;
    // box-shadow: 0px 4px 24px rgba(102, 122, 139, 0.16);
    // border-radius: 16px;
    // padding: 20px;
    // height: 100%;
    // text-align: center;
    // display: flex;
    // flex-direction: column;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 32px;
    gap: 32px;
    width: 100%;
    // height: 248px;
    background: rgba(122, 122, 122, 0.1961);
    border-radius: 16px;
    // &:hover {
    //   // background: #ffffff;
    //   border: 2px solid #6181ff;
    //   box-sizing: border-box;
    //   box-shadow: 0px 4px 24px rgba(102, 122, 139, 0.16);
    //   border-radius: 16px;
    //   cursor: pointer;
    // }
    .inside-box {
      display: flex;
      justify-content: space-between;
      // border-left: 1px solid #3d3d3d;
      height: 100%;
    }
    .imgBox {
      height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      text-align: center;

      color: #284157;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      /* identical to box height, or 167% */

      text-align: center;

      color: #667a8b;
      flex: 1;
    }
    .b-table {
      // border-top: 1px solid #e1e5e8;
      padding-top: 10px;
      min-height: 50px;
    }

    @media (max-width: 767px) {
      .paymentContainer {
        padding: 24px 24px;
      }
    }
  }
  // .continueBtn {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   align-items: center;
  //   padding: 12px 32px;
  //   gap: 8px;
  //   margin: 16px 0;
  //   width: 135px;
  //   height: 48px;
  //   color: #fff;
  //   font-style: normal;
  //   font-weight: 600;
  //   font-size: 16px;
  //   line-height: 24px;
  //   border: 1px solid #6181ff;
  //   /* Primary/Purple 48% */

  //   background: #6181ff;
  //   border-radius: 12px;
  // }
  // .continuebtn-container {
  //   max-width: 100%;
  //   margin-top: 2%;
  //   border-top: 1px solid #7a7a7a;
  //   display: flex;
  //   align-items: flex-end;
  //   justify-content: flex-end;
  // }

  @media (max-width: 767px) {
    .mode-head {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      color: #ffffff;
    }
    .viewDetails {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #6181ff;
      &:hover {
        color: #fff;
      }
    }
    .table-head {
      flex-direction: column !important;
      align-items: flex-start !important;
    }
  }
  @media (max-width: 427px) {
    .go-back {
      font-weight: 600;
      font-size: 16px !important;
      line-height: 24px;
      span {
        display: inline-flex;
        align-items: center;
        img {
          margin: 0px 15px 0 0;
          width: 22px;
        }
      }
    }
    .sub-text {
      font-size: 14px;
      margin-left: 35px;
    }
    .MuiAccordionSummary-content {
      justify-content: unset;
      height: 56px;
      margin: unset !important;
    }
    .table-head {
      margin: 4% 0%;
    }
  }
}

#confirmSection {
  text-align: center;
  padding: 30px;
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    text-align: center;

    color: #284157;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;

    color: #667a8b;
  }
  .action {
    button {
      margin: 10px;
      width: 100px;
      background-color: #000000;
      border-radius: 74px;
    }
    .btnYes {
      background-color: #ffffff;
      color: #000000;
      border: 1px solid #000000;
    }
    .btnYes:hover {
      color: #fff;
      background-color: #000000;
    }
  }
}
.confirmSection {
  width: 400px;
  max-width: 96%;
  .modal-content {
    background: #ffffff;
    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    box-shadow: 0px 8px 48px rgba(102, 122, 139, 0.48);
    border-radius: 16px;
  }
}
#UploadDocModalSection {
  padding: 20px;
  padding-bottom: 0;
  .text-head {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    color: #000000;
  }
  .text-title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    color: #999999;
  }
  .ant-select-selector {
    height: 48px;
    align-items: center;
    border: 1.5px solid #b1b1b1;
    border-radius: 8px;
  }
  .inline-upload {
    display: flex;
    align-items: center;
    .anticon-upload {
      display: none;
    }
    .ant-upload-select {
      .ant-upload {
        .ant-btn {
          background: rgba(24, 144, 255, 0.12);
          border-radius: 8px;
          border: 0;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          color: #1890ff;
          height: 44px;
        }
      }
    }
    .ant-upload-list-item {
      margin: 0;
    }
  }
}
.modal-content {
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 8px 48px rgba(102, 122, 139, 0.48);
  background: rgba(122, 122, 122, 0.1) !important;
  backdrop-filter: blur(60px) !important;
  border-radius: 16px;
}
.partnershipTag {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  opacity: 0.6;
}

.compareSection {
  max-width: 90%;
  width: 800px;
  .modal-content {
    background: #ffffff;
    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    box-shadow: 0px 8px 48px rgba(102, 122, 139, 0.48);
    border-radius: 16px;
  }
  #compareSection {
    padding: 20px;
    .heading {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      /* identical to box height, or 140% */

      color: #284157;
      margin-bottom: 30px;
    }
    .cross {
      position: absolute;
      top: 44px;
      right: 35px;
      cursor: pointer;
    }
    .table {
      thead {
        tr {
          background: #e1e5e8;
          border-radius: 8px;
          overflow: hidden;
        }
      }
      td,
      th {
        border: 0;
      }
      img {
        height: 30px;
      }
    }
  }
}

.debitCardSection,
.netbankingSection,
.UPISection,
.neftSection {
  .modal-content {
    background: #ffffff;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 8px 48px rgba(102, 122, 139, 0.48);
    border-radius: 16px;
    overflow: hidden;
    min-height: 414px;
  }
}

#debitCardModalSection {
  padding: 18px;
  .contentBox {
    padding: 0 5%;
  }
  .cross {
    position: absolute;
    right: 40px;
    top: 40px;
    cursor: pointer;
    padding: 5px;
  }
  .primary-button {
    background-color: #6181ff !important;
  }
  h5.head-text {
    margin-bottom: unset;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #ffffff;
    // color: #284157;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      color: #667a8b;
    }
  }
  p.sub-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    color: #667a8b;
    margin-bottom: 30px;
  }
}
@media (max-width: 427px) {
  #debitCardModalSection {
    padding: 18px 0px;
    .contentBox {
      padding: unset;
    }
    h5.head-text {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
#netbankingModalSection {
  padding: 18px;
  .contentBox {
    padding: 0 5%;
  }
  .cross {
    position: absolute;
    right: 40px;
    top: 40px;
    cursor: pointer;
    padding: 5px;
  }
  h5.head-text {
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    color: #000000;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      color: #667a8b;
    }
  }
  p.sub-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    color: #667a8b;
    margin-bottom: 30px;
  }
  .btnContinue {
    background: #000000;
    border-radius: 74px;
    color: #ffffff;
    // font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    height: 50px;
    // padding: 0 30px;
    // width: 100%;
    border: 0;
    &:hover {
      background-color: white;
      border: 1px solid black;
      color: black;
    }
  }
  .MuiFormLabel-root {
    font-size: 14px;
  }
}
@media (max-width: 427px) {
  #netbankingModalSection {
    padding: 18px 0px;
    .contentBox {
      padding: unset;
    }
  }
}
#upiModalSection {
  padding: 18px;
  width: 100%;
  .contentBox {
    padding: 0 5%;
  }
  .cross {
    position: absolute;
    right: 40px;
    top: 40px;
    cursor: pointer;
    padding: 5px;
  }
  h5.head-text {
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    color: #284157;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      color: #667a8b;
    }
  }
  p.sub-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    color: #667a8b;
    margin-bottom: 30px;
  }
  .btnVerify {
    background: #6181ff;
    border-radius: 74px;
    color: #fff;
    // font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    height: 50px;
    // padding: 0 30px;
    // width: 100%;
    border: 0;
    &:hover {
      background-color: #000;
      color: #fff;
      border: 1px solid black;
    }
  }
  .MuiFormLabel-root {
    font-size: 14px;
  }
}
// .neftSection {
//   width: 700px;
//   max-width: 96%;
@media (max-width: 427px) {
  #upiModalSection {
    padding: 18px 0px !important;
    .contentBox {
      padding: unset;
    }
  }
}

#neftModalSection {
  // padding: 18px;
  padding: 0 5%;
  .cross {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    padding: 5px;
  }
  h5.head-text {
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #fff;
  }
  h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    color: #ffffff;
    margin: unset;
  }
  .steps {
    .steps-desc {
      margin-bottom: 20px;
      p {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 133% */
        color: #fff;
      }
      .details {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        color: #fff;
      }
      ul {
        li {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height, or 133% */

          color: #fff;
        }
      }
    }
    .note {
      color: #35a8e0;
      h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */

        color: #35a8e0;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height, or 133% */

        color: #35a8e0;
      }
    }
  }
  .neftbtn {
    background: #000000;
    border-radius: 74px;
    color: #ffffff;
    // font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    height: 50px;
    // padding: 0 30px;
    width: 25%;
    border: 0;
    &:hover {
      background-color: white;
      color: black;
      border: 1px solid black;
    }
  }
  .neftoutline {
    background: #ffffff;
    border-radius: 74px;
    color: #000000;
    // font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    height: 50px;
    // padding: 0 30px;
    width: 25%;
    border: 1px solid #000000;
    &:hover {
      background-color: #000000;
      color: #ffffff;
    }
  }

  @media (max-width: 576px) {
    padding: 5px 0%;
  }
}
// }
.line {
  border-bottom: 1px solid #e1e5e8;
}

#paymentSuccessFull {
  .actions {
    button.primary-button {
      background-color: #6181ff;
    }
  }
}
.paymentStepsViewSection {
  width: 75%;
  max-width: 95%;
}
#paymentStepsViewSection {
  // padding: 50px 50px 30px 50px;
  // for-paymentStepsViewSection-two-css-modified
  padding: 50px 20px 30px 0px;
  .cross {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      transform: scale(1.1);
    }
  }
  .steps {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: -10%;
    margin-right: -10%;
    .step {
      flex: 1;
      text-align: center;
      position: relative;
      &::after {
        content: "";
        height: 15px;
        width: 15px;
        left: 50%;
        top: -6px;
        transform: translate(-50%);
        position: absolute;
        border-radius: 50%;
        background: #c4c4c4;
      }
      &::before {
        content: "";
        height: 2px;
        width: 100%;
        left: 50%;
        top: 1px;
        position: absolute;
        background: #c4c4c4;
      }
      &:last-child {
        &::before {
          display: none;
        }
      }

      &.completed {
        &::before {
          background: #6181ff;
          height: 4px;
          top: 0px;
        }
        &::after {
          background: #6181ff;
        }
        span {
          color: #ffffff;
        }
      }
      &.active {
        &::after {
          background: #fff;
          border: 2px solid #6181ff;
        }
        span {
          color: #ffffff;
        }
      }
      span {
        margin-top: 20px;
        display: block;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        text-align: center;

        color: #999999;
      }
    }
  }
  .line {
    border-bottom: 1px solid #c4c4c4;
    margin: 20px 0;
  }
  .detailsBox {
    h6 {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */

      display: flex;
      align-items: center;

      color: #ffffff;
      margin-bottom: 20px;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */

      display: flex;
      align-items: center;

      color: #999999;
      &:last-child {
        margin-bottom: 0;
      }
      strong {
        color: #fff;
        font-weight: 500;
      }
    }
  }
  .foot-action {
    .time {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      text-align: right;

      color: #ed3131;
    }
    .primary-button {
      border-radius: 4px;

      &.black {
        background: #2f3b52;
      }
      &.outlined {
        background: transparent;
        border: 1px solid #6181ff;
        color: #6181ff;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.black {
          color: #2f3b52;
          border: 1px solid #2f3b52;
        }
        img {
          margin-right: 10px;
          margin-top: -2px;
        }

        &:hover {
          background: #007cb9;
          color: #fff;
          &.black {
            color: #fff;
            background: #062541;
            border: 1px solid #062541;
          }
        }
      }

      &:hover {
        background: #007cb9;
        color: #fff;
        &.black {
          color: #fff;
          background: #062541;
          border: 1px solid #062541;
        }
      }
    }
  }
}

.documentUploadSection {
  width: 60%;
  max-width: 95%;
}

.documentUploadSection .modal-body {
  padding: 32px;
}

#errorPage {
  .dashboard-wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: rgb(0, 0, 0);
  }
  .error-image {
    width: 265px;
    display: flex;
    justify-content: center;
  }
  h1 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 150%;
    /* or 60px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }
  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
  }
  .text-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 156px;
  }
}

@media (max-width: 768px) {
  /* width */
  .documentUploadSection {
    width: 100%;
    max-width: 95%;
  }
  .paymentStepsViewSection {
    width: 100%;
    max-width: 96%;
    #paymentStepsViewSection {
      padding: 15px;
      padding-top: 60px;
      .steps .step span {
        font-size: 13px;
      }
      .detailsBox {
        border-bottom: 1px solid #ccc;
        padding-bottom: 20px;
      }
      .foot-action {
        .time {
          flex: 1 1;
          display: flex;
          margin-bottom: 20px;
          width: 100%;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          /* identical to box height, or 125% */
          color: #ed3131;
          border-top: 1px solid #ccc;
          padding-top: 20px;
        }
        .my-action {
          width: 100%;

          button {
            flex: 1;
            width: 50% !important;
            margin: 0 5px 0 0 !important;
          }
        }
        .primary-button {
          &.outlined {
            margin-bottom: 20px;
            font-size: 11px;
            padding: 14px;
            line-height: 15px;
          }
        }
      }
    }
  }
  .saveBtn3 {
    width: 100% !important;
  }
  #errorPage {
    .error-image {
      width: 184px;
      display: flex;
      justify-content: center;
    }
    h1 {
      font-size: 32px;
    }
    span {
      font-size: 16px;
      word-wrap: break-word;
    }
    .text-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 0 15px;
    }
  }

  #UserRecipientSection {
    // padding-bottom: 90px;
    .head-nav {
      width: 100%;
      .primary-button {
        font-size: 13px;
        line-height: 18px;
        padding: 0 10px;
        margin-bottom: 20px;
        height: 40px;
      }
    }
    .table-container {
      padding: 24px 7px !important;
    }
    // .select-section {
    //   // width: 50px!important;
    // }
    img {
      margin: 0 5px !important;
      max-width: 90%;
    }
  }
  .viewDetailsModalSection {
    width: 900px;
    max-width: 95%;
    #viewDetailModalSection {
      padding: 15px;
    }
  }
  .saveBtn {
    margin-top: 50px;
    width: 100%;
  }
  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    height: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  #userFeedback {
    .dashboard-wrapper.w-70.mx-auto {
      width: 90% !important;
    }
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.MuiFormControl-root {
  // background: #fff;
  background: transparent;
}

@media screen and (max-width: 767px) {
  .ant-tooltip-inner {
    width: 60vw;
  }
}
@import "./responsive"; // responsive file import

// amar mui test

.ant-radio-checked .ant-radio-inner {
  // border-color: #fec807 !important ;
  border-color: #6181ff !important;
}

.ant-radio-checked .ant-radio-inner:after {
  // background-color: #fec807;
  background-color: #6181ff !important;
}

.ant-radio:hover .ant-radio-inner {
  // border-color: #fec807;
  border-color: #6181ff !important;
}
.ant-radio-input:focus + .ant-radio-inner {
  // border-color: #989898 !important;
  border-color: #6181ff !important;
}

//himanshu css
.footer-btn {
  border-top: 1px solid #7a7a7a;
  background-color: #000;
  z-index: 0;
}

#Pagination {
  .pagination {
    align-items: center;
  }
  .page-link {
    color: #ffffff;
    background-color: unset;
    border: unset;
    cursor: pointer;
  }
}

.continueBtn2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  gap: 8px;
  margin: 32px 0 24px;
  width: 195px;
  height: 48px;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #6181ff;
  background: #6181ff;
  border-radius: 12px;
  cursor: pointer;
  &:hover {
    border: 1px solid #6181ff;
    background: transparent;
  }

  @media (max-width: 767px) {
    margin: 12px 0;
    width: 100% !important;
  }
}

#RatingSection {
  img {
    float: right;
  }
  .heading {
    padding-top: 40px;
    .mainH {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      color: #ffffff;
      padding: unset;
      margin: unset;
    }
    .subH {
      padding-top: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: rgba(255, 255, 255, 0.8);
      margin: unset;
    }
  }
  .ratingBlock {
    padding-top: 36px;
    padding-bottom: 36px;
    .continueBtn2 {
      margin-top: 32px;
      width: 100%;
    }
  }
  .feedback {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: #999999;
      margin: unset;
    }
    .MuiFilledInput-root {
      background: unset;
    }
    .MuiFilledInput-multiline {
      padding: 8px 0px 10px;
    }
    .MuiFilledInput-underline:before {
      border-bottom: 1px solid #ffffff;
    }
  }
  .ThanyouSection {
    img {
      width: 124px;
      height: 124px;
      margin-top: 80px;
    }
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      text-align: center;
      color: #ffffff;
      margin-top: 28px;
      margin-bottom: 28px;
    }
  }
}

.RatingSectionModal {
  width: 348px;
  max-width: 95%;
  .modal-content {
    background-color: rgba(122, 122, 122, 0.1) !important;
    backdrop-filter: blur(60px) !important;
    border: 1px solid rgba(122, 122, 122, 0.1) !important;
    box-shadow: unset !important;
  }
  .modal-body {
    background: rgba(122, 122, 122, 0.1);
    backdrop-filter: blur(60px);
    border-radius: 16px;
  }
}

.reactStars {
  span:not(:first-of-type) {
    margin-left: 12px;
  }
}

@media (max-width: 427px) {
  .RatingSectionModal {
    width: unset !important;
    max-width: unset !important;
  }
  #RatingSection {
    .heading {
      padding-top: 20px;
      .mainH {
        font-size: 16px;
        line-height: 24px;
      }
      .subH {
        font-size: 14px;
        line-height: 21px;
      }
    }
    .ratingBlock {
      padding-top: 30px;
      padding-bottom: 30px;
      .continueBtn2 {
        margin-top: 30px;
        width: 100%;
      }
    }
    .feedback {
      p {
        font-size: 14px;
        line-height: 21px;
      }
    }
    .ThanyouSection {
      img {
        margin-top: 60px !important;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
        margin-bottom: 24px;
      }
    }
  }
}

.show-animation-wrapper {
  gap: 32px;
  width: 100%;
  display: flex;
  padding: 40px 0px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #61521c;
  min-height: 384px;
  position: sticky;
  top: 80px;
}
.show-animation-wrapper-small {
  top: 80px;
  width: 100%;
  display: flex;
  position: sticky;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #61521c;
  z-index: 9;
}
.bg-dark-red {
  background: #57231f !important;
}
.exchange-rate-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}
.loader-wrapper {
  position: relative;
  width: 150px;
}
.time-tracker {
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.time-tracker-time {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
}
.loader {
  /* width: 110px; */
  padding: 14px;
  background: #fff;
  aspect-ratio: 1;
  border-radius: 50%;
  --_m: conic-gradient(#0000, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  box-sizing: border-box;
  animation: load 2s linear infinite;
}

@keyframes load {
  to {
    transform: rotate(1turn);
  }
}

.loading-heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
}
.loading-content {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  /* line-height: 150%; */
  color: rgba(255, 255, 255, 0.8);
}
.ok-btn-wrapper {
}
.ok-btn {
  padding: 12px 32px;
  border: 1px solid #ffffff;
  border-radius: 12px;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}

.loader-small {
  width: 24px;
  padding: 2px;
  background: #fff;
  aspect-ratio: 1;
  border-radius: 50%;
  --_m: conic-gradient(#0000, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  box-sizing: border-box;
  animation: load-small 2s linear infinite;
}

@keyframes load-small {
  to {
    transform: rotate(1turn);
  }
}
.hidden {
  z-index: 1;
  opacity: 0;
  transition: all 0.19s ease 0s;
  visibility: hidden;
  min-height: 0px;
  height: 0px;
  padding: 0;
}

@media (max-width: 768px) {
  .show-animation-wrapper {
    /* min-width: 300px; */
    top: 56px;
  }
  .loading-heading {
    font-size: 14px;
    text-align: center;
    padding: 0 9px;
    margin: 0 !important;
  }
  .loading-content {
    font-size: 14px;
    text-align: center;
    padding: 0 40px;
  }
  .exchange-rate-title {
    font-size: 14px;
    text-align: center;
    padding: 0 60px;
  }
  .loader-small {
    position: relative;
    left: 15px;
    top: 0px;
    width: 21px;
  }
  .show-animation-wrapper-small {
    top: 55px;
  }
}

@media (max-width: 568px) {
  .loader-small {
    left: 0px;
  }
  #paymentSection.uniform-padding {
    padding: 24px 7px !important;
  }
  #amountHeaderSection.uniform-padding {
    padding: 24px 7px !important;
  }
  #amountHeaderSections.uniform-padding {
    padding: 24px 7px !important;
  }
  #UserPersonalDetailsSection.uniform-padding {
    padding: 24px 7px !important;
  }
  #reviewDetails.uniform-padding {
    padding: 24px 7px !important;
  }
}

.uniform-padding {
  padding: 32px 82px !important;
}
