.otpVerificationModal {
  width: 600px;
  max-width: 90vw;
  .close {
    right: 30px;
    position: absolute;
    top: 25px;
    cursor: pointer;
  }
}
#otpVerificationSection {
  padding: 35px 110px;
  h1 {
    font-size: 15px;
    font-family: montserrat-bold;
    margin-bottom: 15px;
  }
  p.title {
    font-size: 12px;
    color: #363636;
    margin-bottom: 40px;
    strong {
      font-size: 12px;
      color: #000;
    }
  }
  p.p1-1 {
    margin-bottom: 4px;
  }
  a {
    &.resend {
      font-size: 13px;
      font-family: montserrat-semibold;
      color: #0487f3;
    }
  }
  button {
    margin: 20px 0 20px 0;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    letter-spacing: 1px;
  }
  .container-fluid {
    padding: 0;
    input {
      text-align: center;
      background: whitesmoke;
      border: 1px solid #e9e9e9;
      font-size: 13px;
      font-family: montserrat-semibold;
      color: #000;
      /* padding: 13px; */
      height: 35px;
    }
  }
}

@media (max-width: 500px) {
  .otpVerificationModal .close {
    right: 30px;
    position: absolute;
    top: 25px;
    cursor: pointer;
    height: 10px;
  }
  #otpVerificationSection {
    padding: 35px 10px;
    .row {
      padding: 0 10px;
      .col-3 {
        padding: 5px;
        input {
          height: 45px;
        }
      }
    }
    button {
      padding: 13px 0 13px 0;
      margin-top: 5px;
    }
    .rec_title {
      margin-bottom: 5px;
      font-size: 12px;
    }
  }
  .otpVerificationModal {
    margin: 0 auto;
  }
}
