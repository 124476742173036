.productsBookSessionModal {
  width: 800px;
  max-width: 90vw;
  .close {
    right: 30px;
    position: absolute;
    top: 25px;
    cursor: pointer;
    z-index: 9;
  }
  .modal-body {
    padding: 0;
  }
}
.connectModal {
  .callerBox {
    text-align: center;
    .imgBox {
      height: 100px;
      width: 100px;
      overflow: hidden;
      border-radius: 50%;
      margin: 15px auto;
      img {
        width: 100%;
      }
    }
    h4 {
      font-size: 20px;
    }
    p {
      font-size: 17px;
      img.call{
          height:14px;
      }
    }
  }
}
#productsBookSessionModal {
  .title {
    font-size: 11px;
    margin-bottom: 3px;
  }
  .name {
    font-size: 13px;
    color: #000;
    margin: 0;
    strong {
      color: #000;
    }
  }
  .tabler {
    padding: 10px 45px;
  }
  table {
    width: 100%;
    tr {
      td {
        padding: 15px 0px;
        .imgBox {
          width: 40px;
          height: 40px;
          overflow: hidden;
          border-radius: 50%;
          img {
            width: 100%;
          }
        }
        .full_name {
          color: #000;
          display: inline-block;
          margin-left: 20px;
          margin-top: 9px;
          font-family: montserrat-semibold;
          position: relative;
          .caller {
            position: absolute;
            right: -30px;
            top: 2px;
          }
        }
        .designation {
          font-size: 12px;
        }

        .caller {
          margin-left: 10px;
          cursor: pointer;
        }
        button {
          width: 145px;
        }
      }
    }
  }
  .head {
    padding: 30px 30px 10px;
    border-bottom: 1px solid #f3f3f3;
  }

  .mobile-show {
    display: none;
  }
}
@media (max-width: 500px) {
  .productsBookSessionModal {
    margin: 0 auto !important;
  }
  #productsBookSessionModal {
    .mobile-show {
      display: block;
    }
    .name {
      strong {
        margin-bottom: 10px;
        display: block;
      }
    }
    .head {
      padding: 30px 0px 20px;
    }
    .tabler {
      padding: 10px 15px 10px;
    }
    table tr td {
      .full_name {
        margin-top: 0;
        margin-left: 10px;
      }
      .designation {
        margin-left: 10px;
      }
      button {
        width: 110px;
        font-size: 10px;
        padding: 7px;
        border-radius: 50px;
      }
    }
  }
  .productsAndServices {
    .scroller {
      li {
        .card {
          .insider {
            table {
              tr {
                td {
                  .button-primary {
                    margin-top: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
