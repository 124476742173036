.reminderModal {
  width: 900px;
  max-width: 90vw;
  .close {
    right: 30px;
    position: absolute;
    top: 30px;
    cursor: pointer;
    z-index: 9;
  }
  .modal-body {
    padding: 0;
  }
}
.reminderSession {
  .schedular {
    height: 450px !important;
    overflow: auto;
  }
  .remindersList {
    margin-top: 50px;
    h1 {
      font-size: 16px;
      font-family: montserrat-semibold;
      margin-bottom: 30px;
    }
    ul {
      padding: 0;
      li {
        list-style-type: none;
        margin: 20px 0;
        font-size: 12px;
        color: #000;
        img {
          margin-right: 20px;
          height: 12px;
        }
        .site-input-group-wrapper {
          margin-left: 38px;
          position: relative;
        }
        .ant-select {
          margin-right: 5px;
          border-right-width: 1px;
          // border: 1px solid #000;
          // background: grey;
          .ant-select-selector {
            background: #eeeeee;
            font-size: 12px;
          }
        }
        .clear {
          position: absolute;
          right: 0;
          top: 13px;
          cursor: pointer;
          height: unset;
        }
      }
    }
    button {
      width: 226px;
      font-size: 14px;
      height: 40px;
      margin-left: 38px;
      margin-top: 20px;
    }
  }
}
@media (max-width: 500px) {
  .mobile-hide {
    display: none;
  }
  .reminderModal {
    margin: 0 auto;
    .schedular {
      display: none;
    }
    .bookSession {
      padding: 20px;
      .actions {
        margin-top: 0px;
      }
    }
    .ant-tabs-nav .ant-tabs-tab {
      padding: 10px 25px !important;
    }
  }
  .reminderSession {
    .remindersList {
      padding: 30px;
      margin-top: 0px;
      button {
        margin-top: 0px;
      }
    }
  }
}
