#mySessionSection {
  .titleName {
    font-size: 17px;
    font-family: montserrat-bold;
  }
  #past__section {
    .my__card {
      border-radius: 5px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #dadadb;
      overflow: hidden;
      height: 100%;
      .imgBox {
        height: 180px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .details {
        padding: 20px;
        .name {
          font-size: 18px;
          font-family: montserrat-bold;
          margin-bottom: 15px;
        }
        table {
          tbody {
            tr {
              td {
                font-size: 13px;
                &:first-child {
                  width: 100px;
                }
                vertical-align: top;
                // width: 1%;
                position: relative;
                .status {
                  margin-right: 5px;
                  margin-top: -2px;
                }
                .calendar {
                  position: absolute;
                  left: 0;
                  height: 20px;
                }
                .timeSchedule {
                  display: inline-block;
                  padding-left: 28px;
                  font-size: 12px;
                }
              }
            }
          }
        }
        button {
          margin-top: 30px;
          height: 40px;
          font-size: 13px;
          font-family: montserrat-semibold;
          color: #000;
          padding: 0;
          margin-bottom: 10px;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }

  #bookSection {
    ul {
      margin: 0;
      padding: 0;
      li {
        margin: 40px 0;
        display: block;
        position: relative;
        .imgBox {
          width: 40px;
          height: 40px;
          overflow: hidden;
          border-radius: 50%;
          float: left;
          img {
            width: 100%;
          }
        }

        .details {
          margin-left: 70px;
          display: inline-block;
          position: relative;
          .designation {
            margin: 0;
            font-size: 12px;
            margin-bottom: 5px;
          }
          .name {
            margin: 0;
            font-size: 15px;
            font-family: montserrat-semibold;
          }
          .caller {
            position: absolute;
            right: -60px;
            top: 10px;
          }
        }
        .sessions {
          margin-left: 70px;
          .tHead {
            margin: 0;
            font-size: 12px;
            margin-bottom: 5px;
          }
          .timing {
            margin: 0;
            font-size: 15px;
            font-family: montserrat-medium;
            font-weight: 400;
          }
        }
        button {
          height: 40px;
          font-size: 13px;
          width: 150px;
        }
      }
    }
  }
  figure {
    position: relative;
    .filter {
      position: absolute;
      right: 0;
      top: 5px;
      z-index: 9;
      box-shadow: 0px 2px 3px #0000000d;
      .ant-select-selector {
        width: 170px;
        font-size: 12px;
      }
    }
  }
}
.reviewModal {
  position: relative;
  max-width: 55%;
  width: 800px;
  .modal-body {
    padding: 10px 100px;
  }
  .close {
    height: 10px;
    width: 10px;
    position: absolute;
    top: 30px;
    right: 40px;
    cursor: pointer;
  }
  .rTitle {
    font-size: 16px;
    margin-top: 35px;
    font-family: "montserrat-bold";
    color: #000;
  }
  .sTitle {
    font-size: 12px;
    color: #000;
    margin-bottom: 25px;
  }
  .imgBox {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    img {
      width: 100%;
      border-radius: 50%;
    }
  }
  .name {
    font-size: 12px;
    font-family: "montserrat-semibold";
    color: #000;
    margin-top: 5px;
  }
  .customForms {
    .anticon svg {
      display: inline-block;
      height: 2rem;
      width: 2rem;
      margin-bottom: 30px;
    }
    label {
      color: #666666;
      font-size: 14px;
    }
    .ant-form-item-label {
      padding-bottom: 0;
    }
    textarea {
      min-height: 100px;
      background: #f9f9f9;
    }
    button {
      width: 60%;
      height: 40px;
      border-radius: 5px;
      font-size: 14px;
      margin-top: 15px;
    }
  }
}

.not-found {
  padding: 60px 30px;
  text-align: center;
  width: 100%;
  img {
    width: 200px;
    height: 180px;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 5px;
    font-size: 14px;
    strong {
      color: #000;
    }
  }
  button {
    margin-top: 20px;
  }
}
.bookSessionModal {
  position: relative;
  max-width: 90%;
  width: 900px;
  overflow: hidden;
  border-radius: 10px;
  .modal-body {
    padding: 0;
  }
  .close {
    height: 10px;
    width: 10px;
    position: absolute;
    top: 30px;
    right: 40px;
    cursor: pointer;
    z-index: 999;
  }
}

@media (max-width: 500px) {
  #mySessionSection {
    .titleName {
      margin-top: 20px;
    }
    figure {
      .filter {
        top: -36px;
        box-shadow: unset;
        .ant-select-selector {
          width: auto;
          font-size: 10px;
          border: 0;
          background: transparent;
          .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
          .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
            padding-right: 20px !important;
          }
        }
        .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
          z-index: 1;
          margin-top: -8px;
        }
      }
      .ant-tabs-nav {
        // width: 100%;
        .ant-tabs-tab {
          margin-right: 0 !important;
          padding: 10px 10px !important;
          font-family: montserrat-semibold !important;
          font-size: 11px;
          width: 33.33%;
          text-align: center;
        }
      }
    }

    #past__section {
      .horizontal-Scroll {
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        width: 100vw;
        margin-left: -34px;
        padding: 0;
        margin-bottom: 0;

        li {
          display: inline-block;
          width: 290px;
          margin: 10px;
          &:first-child {
            margin-left: 54px;
          }
        }
      }
      .my__card {
        .imgBox {
          height: 150px;
        }
        // .details table tbody tr td {
        //   .calendar {
        //     height: 19px;
        //   }
        //   .timeSchedule {
        //     display: inline-block;
        //     padding-left: 27px;
        //     font-size: 12px;
        //   }
        // }
      }

      .details {
        padding: 15px 20px 0;
        .name {
          font-size: 15px;
        }
        table {
          tbody {
            tr {
              td {
                font-size: 10px;
                img {
                  margin-top: -3px;
                }
              }
            }
          }
        }
        button {
          width: 75%;
        }
      }
    }
    #bookSection ul li {
      margin: 15px 2px;
      display: block;
      position: relative;
      padding: 20px 10px;
      background: #fff;
      box-shadow: 0 8px 10px -5px #ccc;
      .text-right {
        height: 0;
      }

      .details {
        margin-left: 14px;
        .designation {
          margin: 0;
          font-size: 10px;
          margin-bottom: 3px;
          margin-top: 6px;
        }
        .name {
          font-size: 12px;
        }
      }
      button {
        height: 25px;
        font-size: 10px;
        width: auto;
        padding: 0 12px;
        border-radius: 50px;
        margin-top: -55px;
      }
    }
  }

  .ant-select-item-option-content {
    font-size: 10px !important;
  }
  .ant-tabs-bar {
    border-bottom: 1px solid #dcdcdc;
    margin: 0 0 20px 0 !important;
  }
}
