#sendMoneySection {
  height: 90vh;
  padding: 48px 32px;
  display: flex;
  justify-content: center;
  
  .ant-spin-nested-loading{
    width: 50% !important;
  }

  .mob-show {
    display: none;
  }
  .custom-dropdown {
    position: absolute;
    top: 250px;
    right: 12%;
  }
  .button-outline {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    border: 1px solid #000000;
    // font-family: 'Darker Grotesque';
    background: white;
    border-radius: 94px;
    height: 50px;
    padding: 0 30px;
    width: 151px;
    &:hover {
      background-color: black;
      color: white;
    }
  }
  .btn-secondary {
    background-color: transparent;
    border: none;
    padding-left: 0px;
  }
  .btn-secondary.focus,
  .btn-secondary:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
  .dropdown-item {
    // border: none;
    // font-weight: 500;
    // font-size: 12px;
    // line-height: 16px;
    // color: #000000;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
    background-color: rgba(122, 122, 122, 0.1);
    backdrop-filter: blur(60px);
    border-bottom: none;
  }
  .dropdown-item::before {
    background: none;
  }
  .dropdown-item:hover {
    background-color: rgba(122, 122, 122, 0.3);
  }
  .dropdown-menu {
    // background-color: #fff;
    // box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.24);
    // background: rgba(122, 122, 122, 0.1);
    // border: none;
    // border-radius: 8px;
    // top: 23px !important;
    // left: 42px !important;
    // borer: 2px solid #000000;
  }
  .primary-button {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 167% */

    box-sizing: border-box;
    border-radius: 4px;
    height: 45px;
    padding: 0 45px;
    &:hover {
      background-color: white !important;
      color: black !important;
      border: 1px solid black !important;
    }
  }
  .custom-action {
    img {
      cursor: pointer;
    }
  }
  h2.heading {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #ffffff;
  }
  .no-data {
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(102, 122, 139, 0.12);
    border-radius: 4px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 24px;
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      text-align: center;

      color: #000000;
      margin-top: 30px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      text-align: center;

      color: #989898;
    }
  }
  .no-data2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 130px;
    h5 {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      color: #ffffff;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      text-align: center;

      color: #667a8b;
      margin-bottom: 30px;
    }
    button {
      width: 160px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 167% */
      display: flex;
      justify-content: center;
      padding: 11px 5px;
      margin: 0;
      &.primary-button {
        background: #6181ff;
        border-radius: 12px;
        color: #fff;
      }
    }
  }
  .details-container{
    padding: 0px !important;
  }
  .purpose-container {
    // margin: 32px 0;
    padding: 12px 0;
  }
  .welcome-container {
    display: flex;
    flex-direction: column;
    min-height: 120px;
    justify-content: space-around;
    align-items: flex-start;
    padding: 24px;
    // background: rgba(122, 122, 122, 0.1);
    // backdrop-filter: blur(60px);
    width: 100%;
    border-radius: 12px;
    margin-bottom: 8px;
  }
  .welcome-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: #ffffff;
  }
  .phone {
    color: #6181ff;
  }
  .welcome-msg {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #c2c2c2;
  }
  .transaction-container {
    max-width: 100%;
    padding: 32px 32px;
    background: rgba(122, 122, 122, 0.2);
    backdrop-filter: blur(60px);
    margin: auto;
    border-radius: 16px;
  }
  .note-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 8px;
    margin-top: 32px;
    // position: absolute;
    // left: 419px;
    // top: 796px;
    background: rgba(231, 76, 60, 0.4);
    border-radius: 12px;

    p{
      font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 150%;
margin-bottom: 0px !important;
color: #FFFFFF;
    }
  }
  // .continuebtn-container {
  //   max-width: 100%;
  //   margin-top: 5%;
  //   border-top: 1px solid #7a7a7a;
  //   display: flex;
  //   align-items: flex-end;
  //   justify-content: flex-end;
  // }
  .amount-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .sendAmount-title {
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
  }
  .radio-receiver {
    display: flex;
    padding: 0px 16px;
    grid-gap: 12px;
    margin: 12px 32px 12px 0;
    gap: 12px;
    width: 100%;
    min-width: 205px;
    height: 48px;
    background: #3d3d3d;
    -webkit-backdrop-filter: blur(60px);
    backdrop-filter: blur(60px);
    border-radius: 8px;
    border: 1px solid #000000;
    // justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:last-child {
      margin: 12px 0;
    }

    &:hover {
      border: 1px solid #999999;
    }
    &:active {
      border: 1px solid #6181ff;
    }
  }
  .radio-purposebtn {
    display: flex;
    padding: 0px 13px;
    grid-gap: 12px;
    gap: 12px;
    width: 322px;
    margin: 12px 0px;
    height: 48px;
    background: #3d3d3d;
    -webkit-backdrop-filter: blur(60px);
    backdrop-filter: blur(60px);
    border-radius: 8px;
    border: 1px solid #000000;
    // justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
      border: 1px solid #999999;
    }
    &:active {
      border: 1px solid #6181ff;
    }
  }

  .receiver-optionsButton {
    display: flex;
    justify-content: space-between;

    .label-text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .purpose-optionsButton {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .label-text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .tag-image {
    width: 24px;
  }

  // .continueBtn {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   align-items: center;
  //   padding: 12px 32px;
  //   gap: 8px;
  //   margin: 16px 0;
  //   width: 135px;
  //   height: 48px;
  //   color: #fff;
  //   font-style: normal;
  //   font-weight: 600;
  //   font-size: 16px;
  //   line-height: 24px;
  //   border: 1px solid #6181ff;
  //   /* Primary/Purple 48% */

  //   background: #6181ff;
  //   border-radius: 12px;
  // }

  // .custom-table-handler {
  //   background: #ffffff;
  //   box-shadow: 0px 4px 8px rgba(102, 122, 139, 0.12);
  //   border-radius: 4px 6px 4px 4px;
  //   // overflow: hidden;
  //   .table {
  //     thead {
  //       background: #000000;
  //       tr {
  //         color: #fff;
  //         th {
  //           font-style: normal;
  //           font-weight: 500;
  //           font-size: 12px;
  //           line-height: 16px;
  //           /* identical to box height, or 160% */

  //           color: #ffffff;
  //         }
  //       }
  //     }
  //     tbody {
  //       tr {
  //         .custom-action {
  //           display: flex;
  //           height: 55.8px;
  //           align-items: center;
  //           justify-content: center;
  //         }
  //         td {
  //           vertical-align: middle;
  //           font-style: normal;
  //           font-weight: 500;
  //           font-size: 12px;
  //           line-height: 16px;
  //           color: #000000;
  //           border-top: 0px solid #dee2e6;
  //           .transact {
  //             margin-left: 5px;
  //             margin-right: 5px;
  //             transform: rotate(180deg);
  //             width: 25px;
  //           }
  //           .eye {
  //             cursor: pointer;
  //           }
  //           .progress {
  //             width: 100% !important;
  //             margin-top: 10px;
  //             height: 5px !important;
  //             border-radius: 20px !important;
  //             overflow: hidden;
  //             padding: 0 !important;
  //             .progress-bar {
  //               border-radius: 20px !important;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  // progress bar custom color
  .await .progress-bar {
    background-color: #0487f3;
  }
  .success .progress-bar {
    background-color: #5eb568;
  }
  .fail .progress-bar {
    background-color: #e40000;
  }

  .await {
    color: #f1c40f;
  }
  .success {
    color: #2ecc71;
  }
  .cancel {
    color: #f57c00;
  }
  .fail {
    color: #e74c3c;
  }

  .box {
    margin-bottom: 10px;
    // background: #ffffff;
    background: rgba(122, 122, 122, 0.1);
    backdrop-filter: blur(60px);
    box-shadow: 0px 2px 8px rgba(102, 122, 139, 0.12);
    border-radius: 4px;
    border: 1px solid black;
    padding: 10px;
    display: flex;
    align-items: center;
    .details {
      flex: 1;
      display: flex;
      flex-direction: column;
      label {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        /* identical to box height, or 120% */

        color: #b1b1b1;
      }
      p {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        /* identical to box height, or 100% */

        display: flex;
        align-items: center;

        color: #fff;
      }
    }
    span {
      color: #ffffff;
    }
    .status {
      flex: 1;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      /* identical to box height, or 120% */

      display: flex;

      color: #b1b1b1;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 20px;
      .progress {
        flex: 1;
        width: 100% !important;
        height: 6px !important;
        padding: 0 !important;
      }
    }
    .action {
      width: 25px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 20px;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    button {
      margin: 0 5px;
      background: #ffffff;
      border: 1px solid #b1b1b1;
      border-radius: 4px;
      .left-icon {
        transform: rotate(180deg);
      }
      &.disabled {
        opacity: 0.5;
      }
    }
  }

  .transferAmount {
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(102, 122, 139, 0.12);
    border-radius: 4px;
    border: 1px solid black;
    padding: 30px;
    height: 100%;
    // overflow: hidden;
    .converstions {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 167% */

        text-align: right;

        color: #2f3b52;
      }
    }
    .actions {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      button {
        width: 48%;
        font-style: normal;
        // font-family: 'Darker Grotesque';
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 167% */

        border-radius: 74px;
        padding: 0 5px;
        margin: 0;
        &.primary-button {
          background: #000000;
          border: 1px solid #000000;
          color: #fff;

          // &:hover {
          //   background: #062541;
          //   border: 1px solid #062541;
          // }
        }
        &.secondary-button {
          background: transparent;
          color: #2f3b52;
        }
      }
    }
    .notice {
      font-family: "Montserrat";
      font-style: italic;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #000000;
      margin-top: 16px;
      .hl {
        color: #0487f3;
      }
    }
    .moneyHop-logo {
      margin-top: 7px;
      img {
        width: 140px;
      }
    }
    .note {
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      /* or 167% */

      color: #2f3b52;
      // margin-top: 20px;
    }
  }
  .cards-heading {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
    margin-bottom: 24px;
  }
  .table-responsive {
    // border: 1px solid #3D3D3D;
    box-sizing: border-box;
    // background: rgba(122, 122, 122, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  // table {
  //   margin-bottom: 0;
  //   width: 100%;
  //   thead {
  //     height: 56px;
  //     tr {
  //       th {
  //         // font-style: normal;
  //         // font-weight: 300;
  //         // font-size: 14px;
  //         // line-height: 30px;
  //         // /* identical to box height, or 133% */

  //         // color: #667a8b;
  //         // border: 0;
  //         // border-bottom: 1px solid #e1e5e8;
  //         font-style: normal;
  //         font-weight: 400;
  //         font-size: 16px;
  //         line-height: 150%;
  //         padding: 16px 32px;
  //         border: 0;
  //         border-bottom: 1px solid #3d3d3d;
  //         color: #999999;
  //       }
  //       // th:last-child {
  //       //   border-bottom: none !important;
  //       // }
  //     }
  //   }

  //   tbody {
  //     height: 96px;
  //     tr {
  //       border-collapse: collapse;
  //       transition: 0.5s;
  //       border-radius: 10px;
  //       overflow: hidden;

  //       padding: 32px;
  //       td {
  //         font-style: normal;
  //         font-weight: 400;
  //         font-size: 16px;
  //         line-height: 150%;
  //         padding: 16px 32px;
  //         color: #ffffff;
  //         border-bottom: 1px solid #3d3d3d !important;

  //         .transact {
  //           margin-left: 5px;
  //           margin-right: 5px;
  //           transform: rotate(180deg);
  //           width: 25px;
  //         }
  //         &.recipient-column {
  //           font-weight: 700 !important;
  //         }

  //         &.actions {
  //           min-width: 133px;
  //           color: #6181ff;
  //           &:hover {
  //             color: #fff;
  //           }
  //           span {
  //             padding: 2px;
  //             border-radius: 8px;
  //             height: 30px;
  //             display: inline-flex;
  //             width: 30px;
  //             align-items: center;
  //             justify-content: center;
  //             margin-right: 5px;
  //             &:hover {
  //               &.view {
  //                 background: rgba(28, 180, 71, 0.12);
  //               }
  //               &.edit {
  //                 background: rgba(53, 168, 224, 0.12);
  //               }
  //               &.delete {
  //                 background: rgba(237, 49, 49, 0.12);
  //               }
  //             }
  //           }
  //         }
  //       }
  //       &:hover {
  //         background: rgba(122, 122, 122, 0.2);
  //         border-radius: 8px;
  //         cursor: pointer;
  //       }
  //     }
  //   }
  //   th,
  //   td {
  //     vertical-align: middle;
  //     padding: 0.5rem;
  //     .secondary-button {
  //       width: 115px;
  //       font-style: normal;
  //       font-weight: 500;
  //       font-size: 14px;
  //       line-height: 20px;
  //       /* identical to box height, or 167% */
  //       border-radius: 74px;
  //       display: flex;
  //       align-items: center;
  //       text-align: center;
  //       border: 1px solid #000000;
  //       color: #000000;
  //       padding: 0 20px;
  //       background-color: #ffffff;
  //     }
  //     &.w-190 {
  //       width: 130px;
  //     }
  //   }
  // }
  table {
    margin-bottom: 0px !important;
    tr {
      &:first-child {
        td {
          // padding-top: 12px;
        }
      }
      &:last-child {
        td {
          padding-bottom: 0;
        }
      }
      td:first-child {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        color: #c2c2c2;
      }
      th,
      td {
        padding: 0.4rem 0;
        border-top: 0;
      }
    }
    tfoot,
    tbody {
      // border-top: 1px solid #e1e5e8;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #c2c2c2;
    }
  }
  // .btnSendMoney {
  //   padding: 12px 31px;
  //   width: 160px;
  //   background: #6181ff;
  //   border-radius: 12px;
  //   font-style: normal;
  //   font-weight: 600;
  //   font-size: 16px;
  //   line-height: 24px;
  //   color: white;
  //   border: 1px solid #6181ff;
  //   align-items: center;
  //   &:hover {
  //     background-color: white;
  //     color: black;
  //     border: 1px solid white;
  //   }
  // }
  .noTransactions {
    background: rgba(122, 122, 122, 0.1);
    backdrop-filter: blur(60px);
    border-radius: 16px;
  }
  .mobileTransaction {
    border-bottom: 1px solid #3d3d3d !important;
    .left {
      padding: 16px 0px 16px 16px;
      .university-heading {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #ffffff;
        margin-bottom: 9px;
      }
      .txnId {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #999999;
      }
    }
    .right {
      padding: 16px 16px 16px 0px;
      img {
        rotate: 270deg;
        margin-bottom: 9px;
        float: right;
      }
      .payment-status {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        margin: 0px;
      }
    }
  }
  .mobileTransaction:last-child {
    border-bottom: unset !important;
  }
}

@media (max-width: 500px) {
  #sendMoneySection {
    padding-bottom: 20px;
    .custom-dropdown {
      top: 130px;
    }
    .heading {
      margin-bottom: 16px;
    }
    .primary-button {
      padding: 0 20px;
    }
    .status {
      flex-direction: row !important;
      justify-content: center !important;
    }
    .actions {
      // flex-direction: column;
      button {
        margin: 0 5px;
        background: #ffffff;
        border: 1px solid #b1b1b1;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
      }
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */

        color: #000000;
        margin-top: 10px;
      }
    }
    .welcome-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 32px;
      color: #ffffff;
    }
    .transferAmount {
      padding: 30px 20px;
      overflow: visible;
      .converstions span {
        font-size: 12px;
      }
      .input-encaps .type-select {
        width: 110px;
      }
      .actions button.primary-button {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 167% */

        color: #ffffff;
      }
    }
    .no-data {
      p {
        font-size: 14px;
      }
    }
    .bg-info {
      background-color: #0487f3 !important;
    }
    .bg-warning {
      background-color: #e40000 !important;
    }
    .bg-success {
      background-color: #5eb568 !important;
    }
  }
  .no-data2 {
    padding: 130px 10px !important;
    button {
      width: 300px !important;
    }
  }
}

@media screen and (min-width: 1200px) {
  #sendMoneySection {
    .container {
      max-width: 1300px;
    }
  }
}

@media screen and (min-width: 801px) and (max-width: 1024px) {
  #sendMoneySection .transferAmount {
    height: 380px;
  }
  .welcome-container {
    margin: 100px 0 24px 0;
  }
}
@media screen and (max-width: 768px) {
  #sendMoneySection {
    .mob-show {
      display: block;
      width: 100% !important;
      .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
        margin-bottom: 20px;
        background: rgba(122, 122, 122, 0.1);
        // border-width: 1px 0px;
        // border-style: solid;
        /* border-color: #3D3D3D; */
        border: none;
        backdrop-filter: blur(60px);
        .accordion-header-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          z-index: 6;
        }
      }
      .university-heading {
        color: #fff;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
      }
      .payment-status {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
      }
    }
    .padding-right{
      padding-right: 22% !important;
    }
    table.table {
      // display: none;
    }
    table {
      thead {
        tr {
          th .transact {
            transform: rotate(180deg);
            :last-child {
              border-bottom: none !important;
            }
          }
        }
      }
    }

    .actions {
      // display: none;
      justify-content: center;
      .text-white {
        display: none;
      }
    }
    .MuiSelect-icon {
      background-image: url("https://leverageedunew.s3.amazonaws.com/assets/new-images/dropdown.png") !important;
      background-repeat: no-repeat;
    }
    .MuiSvgIcon-root {
      fill: none;
    }
  }
}

@media screen and (max-width: 801px) {
  #sendMoneySection {
    padding: 24px 24px;
    // max-height: 732px;
    .welcome-container {
      margin: 0px ;
    }
    .amount-container {
      flex-direction: column !important;
    }
    .welcome-title {
      margin-bottom: 8px;
    }
    .transaction-container {
      max-width: 100% !important;
      padding: 16px 16px;
    }
    .ant-spin-nested-loading{
      width: 100% !important;
    }
    // .purpose-container {
    //   width: 100%;
    // }
  }
}
// .MuiAccordionSummary-root {
//   display: flex;
//   padding: 0px 16px;
//   min-height: 48px;
//   transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
//     background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//   z-index: -1;
//   position: relative;
// }

#sendMoneySection .dropdown-menu {
  background: rgb(0, 0, 0);
  border: none;
  border-radius: 8px;
  left: 20px !important;
  z-index: 999999;
  padding: 10px 0px;
  transform: translate3d(-235px, 7px, 0px) !important;
}

@media (max-width: 768px) {
  #sendMoneySection.countryDropdown {
    .dropdown-menu.purposeDropdown {
      top: 0px !important;
      left: 234px !important;
      position: relative !important;
      z-index: 0;
    }
    .dropdown-menu {
      top: 41px !important;
      left: 288px !important;
    }
  }
}

.dropdownPosition0 {
  top: -40px !important;
}

.dropdownPosition1 {
  top: -20px !important;
}

.dropdownPosition2 {
  top: 30px !important;
}
.dropdownPosition3 {
  top: 80px !important;
}
.dropdownPosition4 {
  top: 110px !important;
}

.uploadRecieptClass {
  .modal-content {
    background-color: rgba(122, 122, 122, 0.1) !important;
    backdrop-filter: blur(60px) !important;
    border: 1px solid rgba(122, 122, 122, 0.1) !important;
    box-shadow: unset !important;
  }
  .modal-body {
    background: rgba(122, 122, 122, 0.1);
    backdrop-filter: blur(60px);
    border-radius: 16px;
  }
}
