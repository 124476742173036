#UserRecipientSection {
  .page-container {
    padding: 48px 30px;
  }
  .nodata3 {
    color: red;
  }
  .addRecbtn {
    background: transparent !important;
    border: 1px solid white !important;
    width: 50% !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 8px;
    border-radius: 12px;
    &:hover {
      background-color: white !important;
      color: black !important;
    }
  }
  .buttonRecp {
    // width: 100%;
    padding: 12px 32px;
    gap: 8px;

    // width: 168px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6181ff;
    border-radius: 12px;
    &.primary-button {
      background: #6181ff;
      border: 2px solid #6181ff;
      color: #fff;
      &:hover {
        background-color: white;
        color: black !important;
      }
    }
  }
  .pagination-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .saveBtn {
    background: #000000;
    border-radius: 74px;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* display: flex; */
    /* align-items: center; */
    text-align: center;
    color: #ffffff;
    border: 0;
    height: 50px;
    padding: 0 20px;
  }

  .sub-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-left: 47px;
    width: 100%;
    color: rgba(255, 255, 255, 0.8);
  }
  @media only screen and (max-width: 425px) {
    .page-container {
      padding: 24px 15px;
    }
    .buttonRecp {
      width: 100% !important;
    }
    .sub-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      margin-left: 42px !important;
      width: 100%;
      color: rgba(255, 255, 255, 0.64);
    }
    .details {
      width: 133px;
    }
  }
}

#UserPersonalDetailsSection{
  overflow-x: hidden;
  .sub-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-left: 47px;
    width: 100%;
    color: rgba(255, 255, 255, 0.8);
  }
  @media only screen and (max-width: 425px) {
    
    .sub-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      margin-left: 42px !important;
      width: 100%;
      color: rgba(255, 255, 255, 0.64);
    }
  }
}

@media (max-width: 768px){
  .AddUserRecipient img {
    margin: 0 5px !important;
    max-width: 90%;
}
}