#topNav {
  // margin-left: 222px;
  background: #0a0a0a;
  // padding: 10px 16px;
  padding: 16px;
  // box-shadow: 0 0 15px -6px #ccc;
  border-bottom: 1px solid #3d3d3d;
  z-index: 99 !important;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  // position: relative;
  .navtoggler {
    // margin-top: 17px;
    cursor: pointer;
  }
  .noHamburger {
    display: none !important;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      font-size: 12px;
      span {
        margin-left: 10px;
      }
    }
  }
  .steps-container {
    min-width: 20%;
  }
  .process-steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 50%;
    width: 100%;
    padding: 1px;
    .step {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1;
      position: relative;
      min-width: 100px;
      &:last-child {
        &::before {
          display: none;
        }
        .number {
        }
        .stepSlide {
          height: 2px;
          background: #6181ff;
          position: absolute;
          left: -30% !important;
          top: 12px;
          animation: complteSlide 1s 1 forwards;
        }
      }
      &.complete {
        &::before {
          // animation-iteration-count: 1;
          // animation: complete 100s ;
          // animation: complteSlide 3s 1 forwards;
          // animation-fill-mode: forwards;
          // background: #6181ff;
          // transition: 1s ease-in;
        }

        .number {
          background: #6181ff;
          border: 1.5px solid #6181ff;
          color: #000000;
          width: 24px;
          height: 24px;
          background-image: url("https://flybank.s3.amazonaws.com/Fly-Forex/Tick.png");
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      &.active {
        .number {
          animation: outerCircle 1s 1s forwards ease-in-out;
          // background: transparent;
          // border: 3px solid #fec807;
          // width: 24px;
          // height: 24px;
          // padding: 4px;
          // animation: 0.3s ease-out;
          // color: #000000;
          // padding: 8px;
          // position: relative;
          // z-index: 1;
          // border-color: #9AE7DC
          // background-image: url("/assets/img/Tick.png");
          // &:after
          // background: #9AE7DC;
        }
        .number2 {
          // border: 3px solid #fec807;
          // background: #fec807 !important;
          // box-sizing: border-box;
          // width: 11px;
          // height: 11px;
          // border-radius: 50%;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          // background: #e1e5e8;
          // z-index: 9;
          // position: relative;
          animation: innerCircle 1s 1s forwards;
        }
      }
      @keyframes outerCircle {
        0% {
          background: transparent;
          border: 3px solid #fec807;
          width: 24px;
          height: 24px;
          padding: 4px;
          animation: 0.3s ease-out;
        }
        100% {
          background: transparent;
          border: 3px solid #fec807;
          width: 24px;
          height: 24px;
          padding: 4px;
          animation: 0.3s ease-out;
        }
      }

      @keyframes innerCircle {
        0% {
          border: 3px solid #fec807;
          background: #fec807 !important;
          box-sizing: border-box;
          width: 1px;
          height: 1px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fec807;
          z-index: 9;
          position: relative;
        }
        100% {
          border: 3px solid #fec807;
          background: #fec807 !important;
          box-sizing: border-box;
          width: 11px;
          height: 11px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fec807;
          z-index: 9;
          position: relative;
        }
      }
      &.number {
        &::after {
          background: #9ae7dc;
        }
      }

      &::before {
        content: "";
        height: 2px;
        width: 82%;
        background: #c4c4c4;
        position: absolute;
        left: 59%;
        top: 12px;
      }
      .number {
        border: 2px solid #e1e5e8;
        box-sizing: border-box;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: #000000;
        z-index: 9;
        position: relative;
        // position: relative;
      }
      .label {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        text-align: center;

        color: #667a8b;
        margin-top: 5px;
      }
    }
    .stepSlide {
      height: 2px;
      // width: 82%;
      background: #6181ff;
      position: absolute;
      left: 59%;
      top: 12px;
      animation: complteSlide 1s 1 forwards;
      &:last-child {
        height: 2px;
        background: #6181ff;
        position: absolute;
        left: -30% !important;
        top: 12px;
        animation: complteSlide 1s 1 forwards;
      }
    }
    @keyframes complteSlide {
      from {
        width: 0%;
      }
      to {
        width: 82%;
      }
    }
  }
  .logout {
    padding: 2px;
    position: absolute;
    right: 10px;
  }

  .lockBtn {
    background: transparent;
    border: 0px;
  }
  .lockIcon {
    max-width: 40px;
    filter: brightness(1);
  }
  &:hover .lockBtn {
    .lockIcon {
      filter: brightness(2);
    }
  }
  .avatarBox {
    .btn-secondary {
      background: transparent;
      border: 0;
    }
    .btn-secondary.focus,
    .btn-secondary:focus {
      background: transparent;
      border: 0;
      box-shadow: unset;
    }
    .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid black;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      transform: rotate(360deg);
    }
    .dropdown-menu.show {
      display: block;
      left: unset !important;
      right: 0;
      transform: translate3d(0, 52px, 0px) !important;
      background-color: rgba(122, 122, 122, 0.1);
      backdrop-filter: blur(60px);
      color: #fff;
      box-shadow: 0px 2px 10px #00000027;
      border: 0;
      border-radius: 0;
      // right: 0;
      // left: unset !important;
      .dropdown-header {
        // background: url("../../img/user-bg.png");
        width: 335px;
        padding: 1.5rem 2.5rem;
        border-bottom: 1px solid #7a7a7a;
        &:focus {
          box-shadow: unset;
          outline: 0;
        }
        .name {
          font-size: 12px;
          font-family: "Open Sans";
          margin: 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #c2c2c2;
        }
        .desc {
          font-size: 10px;
        }
      }
      .dropdown-item {
        display: block;
        width: 100%;
        padding: 1.5rem 2.5rem;
        clear: both;
        font-weight: 600;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        // font-size: 14px;
        border-bottom: 1px solid #7a7a7a;
        font-family: "Open Sans";
        text-align: left;
        &:hover {
          // font-family: "Open Sans";
          color: #6181ff;
          &::before {
            opacity: 1;
          }
        }
        &::before {
          content: "";
          height: 70px;
          width: 8px;
          background: #a3a0fb4d;
          display: block;
          position: absolute;
          left: 0;
          margin-top: -24px;
          opacity: 0;
          transition: 0.3s;
        }
      }
    }
  }

  .avatar {
    height: 32px;
    border-radius: 50%;
  }
  .seperator-vertical {
    display: inline-block;
    border-right: 2px solid #ddd;
    margin-right: 20px;
    padding-right: 20px;
    .mobile-hide {
      display: inline-block;
    }
  }

  .noLogo {
    width: 106px;
    height: 48px;
    position: absolute;
    left: 27px;
  }
  .logo1 {
    width: 106px;
    height: 48px;
    position: absolute;
    right: 27px;
  }
  .logo2 {
    top: 10px;
    width: 61px;
    right: 27px;
    height: 61px;
    position: absolute;
  }

  .mobile-hide {
    display: block;
  }
}

@media screen and (max-width: 1024px) {
  #topNav {
    margin-left: 0;
    .badges.primary {
      font-size: 10px;
    }
    .navtoggler.mobile-show {
      display: inline-block !important;
    }
  }
}

@media screen and (max-width: 767px) {
  #topNav {
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #3d3d3d;
    background: #000000;
    height: 56px;
    // box-shadow: 0 0 15px -6px #ccc;
    .navtoggler.noHamburger {
      display: inline-block !important;
    }
    .noLogo {
      display: none;
    }
    .logo1 {
      display: none;
    }
    .logo2 {
      display: none;
    }
    .mobile-hide {
      display: none;
    }
    .steps-container {
      min-width: 100% !important;
    }
    .process-steps {
      .step {
        min-width: 15%;

        .label {
          font-size: 10px;
          font-weight: 400px;
          line-height: 150%;
        }
      }
    }
  }
}
