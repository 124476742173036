.verifyMobileModal {
  width: 600px;
  max-width: 90vw;
  .close {
    right: 30px;
    position: absolute;
    top: 25px;
    cursor: pointer;
  }
}
#verifyMobileSection {
  padding: 35px 110px;
  h1 {
    font-size: 15px;
    font-family: montserrat-bold;
    margin-bottom: 15px;
  }
  p {
    font-size: 12px;
    color: #363636;
    margin-bottom: 40px;
    strong {
      font-size: 12px;
      color: #000;
    }
  }
  button {
    margin: 20px 0 20px 0;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    letter-spacing: 1px;
  }
}

@media (max-width: 500px) {
  #verifyMobileSection {
    padding: 40px 10px;
  }
  .verifyMobileModal {
    width: 100%;
    max-width: 90vw;
    margin: 0 auto;
  }
}
