#transactionSummarySection {
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    color: #3D3D3D;
    ;
  }
  .table td,
  .table th {
    border-top: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    color: #121212;
    padding: 8px 0;
    strong {
      color: #3D3D3D;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    .final {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #3D3D3D;
    }
  }
  table {
    tbody {
      tr {
        td {
          strong {
            font-weight: 500;
          }
          .tooltip-nostro {
            margin: 0 10px;
          }
          .toggle-btn {
            display: inline;
            button {
              width: 84px;
              font-size: 12px;
              font-weight: 400;
              border: 1px solid #667a8b;
              color: #667a8b;
              background: none;
            }
            .btn-1 {
              border-radius: 4px 0px 0px 4px;
              border-right: none;
            }
            .btn-2 {
              border-radius: 0px 4px 4px 0px;
              border-left: none;
            }
            .showColor {
              background: #000000;
              color: #fff;
              border: 1px solid #000000;
            }
          }
        }
      }
    }
  }
  tfoot,
  tbody {
    border-top: 1px solid #ccc;
  }
}
@media screen and (max-width: 767px) {
  #transactionSummarySection {
    .toggle-btn {
      display: block !important;
      margin-top: 10px;
    }
  }
}
@media (max-width: 500px) {
  #transactionSummarySection .table td,
  #transactionSummarySection .table th {
    font-size: 13px;
  }
}
