/*!
 * CSS file
 */

body {
  font-family: 'Montserrat', sans-serif;
}

#wrapper {
  overflow-x: hidden;
}
#sidebar-wrapper {
  min-height: calc(100vh - 56px);
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
  background-color: #0A0A0A;
  top:79px;
  z-index: 1;

  border-right: 1px solid #3d3d3d !important;
}
#sidebar-wrapper .list-group {
  width: 60px;
  /* Blur 120 */
}
/* #sidebar-wrapper :hover .list-group{
  background: rgba(122, 122, 122, 0.1);
  backdrop-filter: blur(60px);
} */

#sidebar-wrapper .list-group .menu_icons {
  /* margin: 13px 18px; */
  width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;

}
  

/* #sidebar-wrapper a img {
    filter: grayscale(1);
    width: 26px;
}

#sidebar-wrapper a.active img{
  filter: grayscale(0);
  width: 26px;
} */
#sidebar-wrapper a img {
  filter: brightness(0.5);
  width: 32px;
}

#sidebar-wrapper a.active img{
filter: brightness(1.5);
width: 32px;
}
#sidebar-wrapper a.active  {
    /* filter: grayscale(0); */
    background: #6181FF;
}
/* #sidebar-wrapper .list-group a.active {
  background-color: #3D3D3D !important;
  margin-left: 0px;
  width: 60px;
  text-align: center;
  padding: 14px;
} */
#page-content-wrapper {
  min-width: 100vw;
  background-color: #F0F0F7;
  padding: 30px 0;
  margin-top: 56px;
}
#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

/*--------- tooltip CSS -------------*/
.tooltip {
  position: relative;
  opacity: 1;
  font-family: 'Montserrat', sans-serif;
}
.tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #a5a4bf;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltip-right::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent #a5a4bf transparent transparent;
}
.tooltip-right {
  top: -5px;
  left: 175%;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
/*--------------- // tooltop CSS ---------------*/


.card {
  border: none;
  border-radius: 0;
}
.card .card-header {
  background-color: #F0F0F7;
  border: none;
  padding-left: 0;
}
.card .card-header h5, .card-title h5 {
  font-size: 18px;
  color: #203539;
  font-weight: 500;
}
.card-title {
  margin-bottom: 22px;
}
.card .card-content {
  border-radius: 3px;
  box-shadow: 0 1px 3px #3F3F4426;
}
.card .card-content .padd20 {
  padding: 25px;
}
.card .card-content p:first-child {
  font-size: 13px;
  color: #203539;
  font-weight: 500;
  width: 85%;
  text-transform: capitalize;
}
.card .card-content p:first-child span {
  margin-right: 15px;
}
.card .card-content p:last-child {
  /* text-transform: uppercase; */
  font-size: 11px;
  font-weight: 600;
  width: 15%;
  color: #587074;
}
.card .card-content p a {
  color: #7AB8FC;
}
.card .btn-showMore {
  border-top: 1px solid #e1e1e1;
  padding: 15px;
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  color: #3B86FF;
}
.impDate .card-content .padd20 {
  padding: 15.5px 25px;
}
.impDate .card-content p:first-child {
  width: 75%;
}
.impDate .card-content p:last-child {
  width: 25%;
}
.impDate .card-content p:first-child span:first-child {
  position: relative;
  margin-right: 30px;
  color: #203539;
  font-weight: 600;
}
.impDate .card-content p:first-child span:first-child:after {
  content: '';
  position: absolute;
  border-right: 1px solid #e1e1e1;
  width: 1px;
  height: 100%;
  right: -15px;
  top: 0;
}
.impDate .card-content p:first-child span small {
  font-size: 11px;
  font-weight: 500;
}
.card .card-content p {
  margin-bottom: 0;
}
.quizScore {
  position: relative;
}
.quizScore:before {
  position: absolute;
  content: '';
  width: 8px;
  height: 100%;
  background-color: #7AB8FC;
  left: 0;
  top: 0;
}
.card-content-header {
  padding: 22px 0;
  border-bottom: 1px solid #E4F2F5;
}
.progress {
  background-color: #E4F2F5 !important;
  height: 0.65rem !important;
  padding: 1.5px !important;
  width:calc(100% - 132px) !important;
}
.progress-bar {
  background-color: #05C985 ;
}
.progress-status {
  font-size: 13px;
  color: #2F4D52;
  font-weight: 500;
  padding-left: 15px;
}
.universityName {
  color: #203539 !important;
  font-size: 13px !important;
  font-weight: 700 !important;
}
.mr-30 {
  margin-top: 30px;
}
.appState {
  padding: 25px 0;
  border-bottom: 1px solid #E4F2F5;
}
.appState img {
  margin-right: 10px;
  width: 25px;
  height: 25px;
  object-fit: contain;
}
.appState p {
  width: auto !important;
  color: #203539 !important;
}
.appState p span:first-child {
  margin-bottom: 5px;
  display: inline-block;
  font-weight: 600;
}
.appState p span:last-child {
  color: #2F4D52;
  font-weight: 500;
}

/*------------------ account data ------------------*/

.account_data .card {
  margin-bottom: 15px;
}
.account_data .card .card-header {
  background-color: #fff;
  box-shadow: 0 2px 6px #0000000A;
  position: relative;
}
.account_data .card .card-header img {
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.account_data .card .card-header.check_card img {
  display: block;
}
.account_data .card .card-header.check_card button {
  color: #2F4D52;
}
.account_data .card .card-header.check_card button span {
  background-color: #2F4D52;
}
.account_data .card .card-header button {
  color: #5b6065;
  font-weight: 600;
  font-size: 13px;
  width: 100%;
  text-align: left;
  padding-left: 20px;
}
.account_data .card .card-header button:hover {
  text-decoration: none;
}
.account_data .card .card-header button:focus {
  outline: none;
  box-shadow: none;
  text-decoration: none;
}
.account_data .card .card-header button span {
  background-color: #d0d1d2;
  color: #fff;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  border-radius: 3px;
}
.account_data .card .card-header.active-acc{
  background-color: #7AB8FC;
  box-shadow: none;
}

.active-acc{
  background-color: #7AB8FC;
  box-shadow: none;
}
.active-acc span{
  color: white;
}
.account_data .card .card-header.active-acc button {
  color: #fff;
}
.account_data .card .card-header.active-acc button span {
  color: #7AB8FC;
  background-color: #fff;
}
.app_progress {
  padding: 0;
}
.app_progress .progress {
  width: 61%;
}
.form_pi div label {
  font-size: 12px;
  color: #203539;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.form_pi div input,.form_pi div select {
  /* color: #A6ADB4; */
  padding: 12px;
  height: 40px;
  font-size: 12px;
  border: 1px solid #e1e1e1;
  font-weight: 500;
}
.form_pi div textarea{
  padding: 12px;
  font-size: 12px;
  border: 1px solid #e1e1e1;
  font-weight: 500;
}

.form_pi div input[type='radio'] {
  height: auto !important;
  margin-right:4px;
}
.form_pi div select {
  height: 40px;
  padding: 0 12px;
}
.form_pi div input:focus, .form_pi div select:focus {
  box-shadow: none;
  outline: none;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: block;
}
.upload-btn-wrapper .btn {
  border: 1px solid #e1e1e1;
  color: #7AB8FC;
  background-color: white;
  padding: 8px 22px;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}
.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.form div .form-group {
  margin-bottom: 30px;
}
span.filename {
  font-size: 12px;
  font-weight: 600;
  color: #203539;
}
.form div .saveBtns {
  margin: 30px 0 25px;
}
.form div .saveBtns button {
  width: 160px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.form div .saveBtns button:first-child {
  margin-right: 15px;
  color: #203539;
  border: 1px solid #203539;
  padding: 8px;
  background-color: #fff;
}
.form div .saveBtns button:last-child {
  margin-right: 15px;
  color: #fff;
  border: 1px solid #203539;
  padding: 8px;
  background-color: #203539;
}
.form div .saveBtns button:hover {
  background-color: rgba(32, 53, 57, 0.9);
  color: #fff;
}
.upload_doc {
  padding: 25px 0;
  /* border-bottom: 1px solid #E4F2F5; */
}
.upload_doc p {
  margin-bottom: 0;
  font-size: 12px;
  color: #203539;
  font-weight: 500;
  text-transform: capitalize;
  /* height: 52px; */
}

/*------------------ Application preview ----------------*/
.padd40 {
  padding: 40px;
}
.userImg {
  color: #000;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 40px;
  text-transform: capitalize;
}
.userImg img{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 12px;
}
.carddata {
  margin-bottom: 30px;
}
.carddata .card-heading p {
  background-color: #7AB8FC;
  padding: 20px 15px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
.carddata .card-content{
  box-shadow: none;
}
.showinfo {
  padding: 10px 0;
  margin: 0 15px;
  border-bottom: 1px solid rgba(166, 173, 180, 0.3);
}
.showinfo p{
  width: 100% !important;
  color: #666 !important;
  font-size: 12px !important;
  padding: 20px 0;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}
.saveexit {
  width: fit-content;
  cursor: pointer;
  color: #fff;
  border: 1px solid #203539;
  padding: 10px;
  background-color: #203539 !important;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 40px;
  border-radius: 4px;
}
.saveexit:hover {
  background-color: rgba(32, 53, 57, 0.9);
  color: #fff;
}

/*---------------- application progress bar --------------*/
.one span, .two span, .three span, .four span {
  width: 115px;
  bottom: -25px;
  position: absolute;
  right: 0;
  left: -17px;
  margin: auto;
  height: 15px;
  font-size: 11px;
  color: #2F4D52;
  font-weight: 500;
  line-height: 15px;
}
.three span, .four span {
  left: -12px;
 }
.one, .two, .three, .four {
  position:absolute;
  margin-top:-8px;
  z-index:1;
  height:22px;
  width:22px;
  border-radius:50px;
}
.no-color{
  background-color:inherit;
}
.primary-color{
  background-color:#05C985;
}
.one{
  left:8%;
}
.two{
  left:37%;
}
.three{
  left:64%;
}
.four{
  left:90%;
}
.ap_head {
  padding: 25px 0;
}
.application_progress .progress {
  width: 100% !important;
}
.application_progress {
    margin-bottom: 55px;
}
.application_progress .ap_head span:first-child {
  font-weight: 600;
}
.application_progress .ap_head span:last-child {
  color: #2F4D52;
  font-size: 13px;
}
.applicationButtons {
  flex-direction: column;
}
.applicationButtons a,.applicationButtons span {
  cursor: pointer;
  font-size: 11px;
  padding: 12px;
  width: 160px;
  border: 1px solid #203539;
  display: block;
  margin-left: auto;
  font-weight: 600;
  text-align: center;
  border-radius: 4px;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.applicationButtons a:first-child,.applicationButtons span:first-child {
  color: #203539;
}
.applicationButtons a:last-child,.applicationButtons span:last-child {
  color: #fff;
  background-color: #203539;
  margin-bottom: 0;
}
.applicationButtons a:hover,.applicationButtons span:hover {
  background-color: rgba(32, 53, 57, 0.9) !important;
  color: #fff;
  text-decoration: none;
}

.width75 {
    width: 75%;
    margin-left: auto;
}
.my_app .col-12 {
  margin-bottom: 25px;
}
.my_app .col-12:nth-child(4), .my_app .col-12:nth-child(3) {
  margin-bottom: 0;
}
.followLink {
  color: #47464b !important;
  background-color: #E5DEFF !important;
  border: 1px solid #E5DEFF !important;
}
.followLink:hover {
  color: #fff !important;
}
.my_app .applicationButtons a {
  margin-bottom: 15px !important;
}
.my_app .application_progress .progress .one {
  left: 10%;
}
.my_app .application_progress .progress .two {
  left: 50%;
}
.my_app .application_progress .progress .three {
  left: 90%;
}
.card .card-header.appHead p {
  margin: 0;
  padding: 12px 60px 12px 24px;
  background-color: #65607D;
  color: #fff;
  border-radius: 3px 3px 0 0;
  font-size: 13px;
  font-weight: 600;
  display: inline-block;
}
.icon_dot {
  background-color: #FFC06A;
  width: 5px;
  height: 5px;
  display: inline-table;
  vertical-align: middle;
  margin-right: 7px;
  border-radius: 4px;
}
.sendReminder:hover + .followupText {
  display: block !important;
  position: absolute;
  bottom: 17px;
  margin: auto;
  background-color: #A5A4BF;
  padding: 10px;
  border-radius: 4px;
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  left: -28%;
}
.followupText:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  right: -4px;
  background-color: #a5a4bf;
  top: 0;
  bottom: 0;
  margin: auto;
}
.admitPage .col-12 {
  margin-bottom: 25px;
}
.admitPage .col-12:last-child{
  margin-bottom: 0;
}
.admitPage .applicationButtons a {
  margin-bottom: 15px !important;
  width: 175px;
}
.enquiry_form .modal-header {
  border-bottom: none;
  padding: 5px 15px;
}
.enquiry_form .modal-body h5 {
  padding: 0 15px;
  color: #203539;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 25px;
}
.enquiry_form div label {
  color: #203539;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
}
.enquiry_form div input {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 12px;
  font-size: 12px;
  font-weight: 500;
  color: #587074;
}
.enquiry_form div input:focus, .enquiry_visaform div textarea {
  outline: none;
  box-shadow: none;
}
.form-check {
  margin-bottom: 30px;
}
.enquiry_form div button, .enquiry_visaform div button,.enquiry_visaform div button:disabled {
  color: #fff;
  background-color: #203539 !important;
  border: 1px solid #203539 !important;
  font-size: 11px;
  text-transform: uppercase;
  width: 160px;
  padding: 10px;
  font-weight: 600;
  margin-bottom: 25px;
}
.enquiry_visaform div button:hover, .enquiry_form div button:hover, .enquiry_visaform div button:focus, 
.enquiry_form div button:focus {
  opacity: 0.9;
  background-color: #203539;
  border: 1px solid #203539;
}
button:focus {
  outline: none;
}
.enquiry_visaform {
  border: none;
}
.enquiry_visaform .modal-header {
  background-color: #65607D;
  box-shadow: 0 3px 6px #00000029;
}
.enquiry_visaform .modal-header h5 {
  color: #fff;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
}
.enquiry_visaform .modal-header .close {
  color: #fff;
  opacity: 0.9;
}
.enquiry_visaform div {
  padding: 15px 0;
}
.enquiry_visaform div textarea {
  border: 1px solid #E1E1E1;
  padding: 13px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #A6ADB4;
}
.modal-backdrop {
  background-color: #000 !important;
  opacity:0.9 !important;
}
.select_status select {
  color: #203539;
  font-size: 12px;
  font-weight: 500;
  height: 35px;
}
.select_status select:focus {
  outline: none;
  box-shadow: none;
}
.prev_edit button {
  background-color: #fff !important;
  width: auto !important;
  padding: 8px !important;
  margin-right: 20px;
  border: none !important;
  font-weight: 600 !important;
  color: #587074 !important;
}
.essayform label {
  font-size: 12px;
  color: #203539;
  margin-bottom: 15px;
  font-weight: 500;
}
.essayform textarea {
  font-size: 14px;
  color: #587074;
  padding: 15px;
  font-weight: 500;
}
.form_decla label {
  margin-left: 10px;
}
.decl_dateName {
  margin-left: 30px;
}
.decl_dateName p {
  font-size: 12px;
}
.decl_dateName p span {
  width: 150px;
  display: inline-block;
}
.decl_dateName p span:first-child {
  color: #203539;
  font-weight: 600;
  margin-bottom: 5px;
}
.decl_dateName p span:last-child {
  color: #2F4D52;
}

button:focus {
  outline: none;
  box-shadow: none;
}


body {
  background-color: #F0F0F7;
}
.mobilemenu button#menu-toggle {
    padding: 4px 10px;
    margin-left: 10px;
    background-color: #fff;
    border-color: #e4e4e4;
}
.mobilemenu button#menu-toggle span.navbar-toggler-icon {
    width: 1em;
    height: 1em;
}
.avatar {
  width: 32px;
  height: 32px;
  /* padding: 0 10px 0 0 !important; */
  /* display: flex; */
  align-items: center;
}
.avatar img {
  width: 100%;
  border-radius: 50%;
}
.dropdownProfile .dropdown-menu {
  left: auto;
  right: 0;
}
.dropdownProfile .dropdown-menu .dropdown-item {
  font-size: 12px;
  color: #203539;
}


@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
    position: fixed;
    background: rgba(122, 122, 122, 0.1);
    /* top: 56px; */
  }
  .margTop6 {
    margin-top: 6rem;
  }

  .w-85 {
    width: 85%;
    margin: auto;
  }

  #page-content-wrapper {
    min-width: 0;
    width: calc(100% - 61px);
    margin-left: auto;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}


@media only screen and (max-width: 767px) {
  #page-content-wrapper {
    min-width: auto;
    width: 100%;
    margin-left: auto;
  }
  #wrapper.toggled #page-content-wrapper {
    width: calc(100% - 61px);
  }
  #sidebar-wrapper {
    margin-left: -61px;
    position: fixed;
    top: 56px;
    z-index: 9;
  }
  .applicationButtons {
    flex-direction: row;
  }
  .applicationButtons a,.applicationButtons span {
    margin-bottom: 30px;
    margin-left: initial;
  }
  .applicationButtons a,.applicationButtons span {
    margin-right: 25px;
  }
  .applicationButtons a:last-child,.applicationButtons span:last-child {
    margin-right: 0;
  }
  .appState .justify-content-center, .appState .justify-content-end {
    justify-content: flex-start !important;
    margin-top: 20px;
  }
}


@media only screen and (max-width: 575px) {
  .select_status .justify-content-end {
    justify-content: flex-start !important;
    padding-left: 0 !important;
  }
  .width75 {
    width: 100%;
  }
  .admitPage .col-12:nth-child(3), .my_app .col-12:nth-child(3) {
    margin-bottom: 25px;
  }
  .applicationButtons {
    flex-direction: column;
  }
  .form div .saveBtns button {
    margin-bottom: 15px;
  }
}

button.btn:focus{
  outline: 0 !important;
}

.applicationInfo{
  border-bottom: 1px solid rgb(165 157 157);
}


.ant-notification-notice {
  padding: 4px 24px !important;
}

.ant-notification-notice-success {
  background-color: #6181FF !important;
}

.ant-notification-notice-error {
  background-color: #E74C3C !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  color: #fff !important;
  font-size: 12px !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  color: white;
  margin-left: 0px !important;
  font-size: 12px !important;
  display: none !important;
}

.anticon.ant-notification-notice-icon-success  {
  display: none !important;
}
.anticon.ant-notification-notice-icon-error {
  display: none !important;
}
.ant-notification-notice-close  {
  top: 3px !important;
}


.anticon svg  {
  color:  white !important;
}



.ant-notification-notice-with-icon .ant-notification-notice-message  {
  margin-left: 0px !important;
}
/* .anticon svg {
  display: none !important;
} */